import React from 'react';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PrevistoReal from '../graficos/PrevistoReal';
import { Divider, Grid } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import { useSelector } from 'react-redux'


const useStyles = makeStyles(theme => ({
  textUppercase: {
    textTransform: 'uppercase',
  },
  thumbRoot: {
    position: 'relative',
    borderRadius: 4,
    overflow: 'hidden',
    cursor: 'pointer',
    '& img': {
      width: '100%',
      height: '100%',
      display: 'block',
    },
  },
  thumbContent: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(180deg,rgba(0,0,0,.01) 0,rgba(0,0,0,.65))',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 8,
  },
  dotRoot: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 1,
    width: 14,
    height: 14,
    border: `solid 2px ${theme.palette.common.white}`,
    borderRadius: '50%',
  },
  thumbTitle: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.common.white,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  revenueInfoGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      order: 2,
      borderLeft: `2px solid ${theme.palette.borderColor.main}`,
      marginBottom: -12,
      marginTop: -56,
    },
  },
  mapGrid: {
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
}));

const Friends = ({ friends }) => {
  // const { total, frndProfiles, mutual } = friends;
  const classes = useStyles();
  const rfm = useSelector(({ Rfm }) => Rfm);
  const [salesGaugeData,seSalesGaugeData ] = React.useState( [
    {
      category: 'Realizado',
      value: rfm.previsto,
      full: 100,
    },
    {
      category: 'Previsto',
      value: rfm.realizado,
      full: 100,
    } 
  ]);

  React.useEffect(() => {
    seSalesGaugeData(
      [
        {
          category: 'Realizado',
          value: rfm.previsto,
          full: 100,
        },
        {
          category: 'Previsto',
          value: rfm.realizado,
          full: 100,
        } 
      ]
    )
    
  }, [rfm.previsto , rfm.realizado]);
  
  return (

    <CmtCard>
      <CmtCardHeader title="Desempenho Fisico"/>
    <CmtCardContent>
      <GridContainer>
       
        <Grid item xs={12} md={4} className={classes.revenueInfoGrid}>
         % Previsto
         <h1>{rfm.previsto} %</h1>
         <br></br>
         <Divider/>
         % Realizado
         <h1>{rfm.realizado} %</h1>
        </Grid>
        <Grid item xs={12} md={8} className={classes.mapGrid}>
        <PrevistoReal salesGaugeData={salesGaugeData}/>
        </Grid>
      </GridContainer>
    </CmtCardContent>
  </CmtCard>
          
  );
};

export default Friends;

Friends.prototype = {
  friends: PropTypes.object.isRequired,
};
