import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import useApi from '../../../../services/api';
import useFormat from '../../../../services/Formats';

import makeStyles from '@material-ui/core/styles/makeStyles';

import GenericReportTable from './../Components/reports/Generics';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBack from '@material-ui/icons/ArrowBack';

import Generics from '../Components/table/Generics';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Button } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  navLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    color: theme.palette.text.secondary,
  },
  iconRoot: {
    fontSize: 18,
    marginRight: 10,
  },
  titleSpace: {
    marginBottom: 20,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      marginTop: 15,
    },
  },
  carding: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)',
      '& $iconThumb': {
        width: 95,
        height: '100%',
        borderRadius: 0,
      },
      '& $hoverContent': {
        transform: 'translate(-10px, -50%)',
      },
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const Relatorios = () => {
  const classes = useStyles();

  let btnRef = useRef();
  const api = useApi();
  const { id } = useParams();
  const formats = useFormat();
  const [open, setOpen] = React.useState(false);
  const [nomeObra, setNomeObra] = useState('...');

  const checkdaObra = async () => {
    const retorno = await api.checkObraStatus(id);

    if (retorno.error === '') {
      setNomeObra(retorno.obra);
      setOpen(false);
    } else {
      alert(retorno.error);
    }
  };

  useEffect(() => {
    getListOfRelatorios();
    checkdaObra();
  }, []);

  const handleToggle = () => {
    setOpen(!open);
  };
  const history = useHistory();

  //####################################################################
  //#################  Constantes e funções da exibição de relatorios###
  //####################################################################
  const [filex, setFilexs] = useState('');
  const [resx, setResx] = useState('');
  const [fileto, setfileto] = useState('');

  const radioGroupRef = useRef(null);
  const handleClose = () => {
    setOpen(false);
    setReportComponent(false);
  };

  const handleClickOpen = idrelatorio => () => {
    setOpen(true);
  };

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  const descriptionElementRef = React.useRef(null);

  const reportRegenerate = async (idrelatorio, relatorio) => {
    if (btnRef.current) {
      btnRef.current.setAttribute('disabled', 'disabled');
    }
    if (
      window.confirm(
        'Atenção ao confirmar Esta solicitação , este relatorio será colocado na fila de Geração de Relatorios, deseja Continuar ?',
      )
    ) {
      let data = {
        IdRelatorio: idrelatorio,
        type: 'GERAL',
        relatorio: relatorio,
      };
      const result = await api.gerarPdfs(data);
      if (result.error != '') {
        alert('Relatorios Gerados!');
      } else {
        alert(result.error);
      }
    }
  };
  //
  const getReportDetail = async (sigla, idrelatorio, semana) => {
    handleToggle();

    switch (sigla) {
      case 'RFM':
        history.push(`/dynamic/rfm/${id}/${idrelatorio}`);

        break;
      case 'RFS':
        history.push(`/dynamic/rfs/${id}/${idrelatorio}`);

        break;
      case 'AFS':
        break;
      case 'MFS':
        break;
      case 'DBM':
        break;
      default:
        alert('Relatorio não suportado');
        break;
    }
  };

  const [listReport, setListReport] = useState([]);
  const [listHeaderReport, setListHeaderReport] = useState('');
  const [reportComponent, setReportComponent] = useState();

  const getRfsDetail = async (idreport, semana) => {
    const result = await api.getRfs(idreport, semana);

    if (result.error === '') {
      const rows = result.list.map(dats => {
        return {
          IDTask: dats.IDTask,
          Servico: dats.Servico,
          Local: dats.Local,
          Empreiteiro: dats.Empreiteiro,
          Startz: dats.Startz,
          Finish: dats.Finish,
          ActualStart: dats.ActualStart,
          ActualFinish: dats.ActualFinish,
          PrevistoSemana1: dats.PrevistoSemana1,
          PrevistoSemana2: dats.PrevistoSemana2,
          PrevistoSemana3: dats.PrevistoSemana3,
          PrevistoSemana4: dats.PrevistoSemana4,
          PrevistoSemana5: dats.PrevistoSemana5,
          RealSemana1: dats.RealSemana1,
          RealSemana2: dats.RealSemana2,
          RealSemana3: dats.RealSemana3,
          RealSemana4: dats.RealSemana4,
          RealSemana5: dats.RealSemana5,
        };
      });
      const headerAfs = [
        'IDTask',
        'Servico',
        'Local',
        'Empreiteiro',
        'Startz',
        'Finish',
        'ActualStart',
        'ActualFinish',
        'PrevistoSemana1',
        'PrevistoSemana2',
        'PrevistoSemana3',
        'PrevistoSemana4',
        'PrevistoSemana5',
        'RealSemana1',
        'RealSemana2',
        'RealSemana3',
        'RealSemana4',
        'RealSemana5',
      ];

      setListReport(rows);
      setListHeaderReport(headerAfs);

      const tabelafs = <GenericReportTable header={headerAfs} dados={rows} />;
      setReportComponent(tabelafs);
      setOpen(true);
    } else {
      alert(result.error);
    }
  };

  //####################################################################
  //################# breadcrumps ######################################
  //####################################################################
  const breadcrumbs = [
    { label: 'G+P', link: '/' },
    { label: nomeObra, link: '/obra/' + id },
    { label: 'Relatorios', isActive: true },
  ];
  //

  //####################################################################
  //################# funções de listagem do Relatorios ################
  //####################################################################
  const [listRelatorios, setListRelatorios] = useState('');
  const [listColumnRelatorios, setListColumnRelatorios] = useState('');
  const [validRpt, setValidRpt] = useState('');
  const handleBackdrop = () => {
    setBackdropActivate(!backdropActivate);
  };
  const [backdropActivate, setBackdropActivate] = useState(false);

  const getListOfRelatorios = async () => {
    const result = await api.getRelatorios(id);

    if (result.error === '') {
      const rows = result.list.map(dats => {
        return {
          SiglaRelatorio: dats.SiglaRelatorio,
          DataInfo: formats.dataPTBR(dats.DataInfo),
          Infos: dats.Infos,
          Primaryc: dats.Primaryc,
          action: (
            <div>
              <Button
                onClick={e => {
                  getReportDetail(dats.SiglaRelatorio, dats.id, dats.SemanaInfo);
                }}>
                Acessar
              </Button>
              <Button
                ref={btnRef}
                onClick={e => {
                  reportRegenerate(dats.id, dats.SiglaRelatorio);
                }}>
                REENVIAR
              </Button>
              <Button
                onClick={e => {
                  handleDownload(dats.id);
                }}>
                VIEW
              </Button>
            </div>
          ),
        };
      });

      setListRelatorios(rows);
      setListColumnRelatorios(['SiglaRelatorio', 'DataInfo', 'Infos', 'Primaryc', 'action']);
      setValidRpt(true);
    } else {
      alert(result.error);
    }
  };
  const handleDownload = relatorio => {
    handleBackdrop();

    // window.open('http://localhost:8000/api/files/getmedicao/' + idrelatorio);
    let token = localStorage.getItem('token');
    console.log(token);
    // idrelatorio  = 655 ;
    // Change this to use your HTTP client
    fetch('https://main.gmaisp.tech/api/files/getreport/' + relatorio, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${token}`,
      },
    }) // FETCH BLOB FROM IT
      .then(response => response.blob())
      .then(blob => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        var _url = window.URL.createObjectURL(blob);
        setFilexs(_url);
        setfileto(new File([blob], 'AFS-teste.pdf'));
        console.log(_url);
        setBackdropActivate(false);

        // window.open(_url, "_blank").focus(); // window.open + focus
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <PageContainer
      heading={
        <>
          <Button
            color="primary"
            onClick={e => {
              history.push(`/obra/${id}`);
            }}
            startIcon={<ArrowBack />}
            variant="outlined"></Button>

          {' OBRA : ' + nomeObra}
        </>
      }
      breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Divider />
          <div style={{ marginTop: 24 }}>
            {validRpt && <Generics cronogramaData={listRelatorios} colun={listColumnRelatorios} />}
          </div>
        </Grid>
      </GridContainer>

      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </PageContainer>
  );
};

export default Relatorios;
