import React, { useEffect, useState , useRef,useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import GSTC from 'gantt-schedule-timeline-calendar';
import { Plugin as TimelinePointer } from 'gantt-schedule-timeline-calendar/dist/plugins/timeline-pointer.esm.min.js';
import { Plugin as Selection } from 'gantt-schedule-timeline-calendar/dist/plugins/selection.esm.min.js';
import { Plugin as ItemResizing } from 'gantt-schedule-timeline-calendar/dist/plugins/item-resizing.esm.min.js';
import { Plugin as ItemMovement } from 'gantt-schedule-timeline-calendar/dist/plugins/item-movement.esm.min.js';
 
import useApi from '../../../../../services/api'
 
import { NavLink , useParams } from 'react-router-dom';

 
import makeStyles from '@material-ui/core/styles/makeStyles';
  

 
 
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import 'gantt-schedule-timeline-calendar/dist/style.css';
 
const useStyles = makeStyles(theme => ({
  navLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    color: theme.palette.text.secondary,
  },
  iconRoot: {
    fontSize: 18,
    marginRight: 10,
  },
  titleSpace: {
    marginBottom: 20,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      marginTop: 15,
    },
    
    
  },
  carding: {
      cursor:"pointer",
      '&:hover': {
        boxShadow: '0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)',
        '& $iconThumb': {
          width: 95,
          height: '100%',
          borderRadius: 0,
        },
        '& $hoverContent': {
          transform: 'translate(-10px, -50%)',
        }
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));




let gstc, state;

// helper functions

function generateRows() {
  /**
   * @type { import("gantt-schedule-timeline-calendar").Rows }
   */
  const rows = {};
  for (let i = 0; i < 100; i++) {
    const id = GSTC.api.GSTCID(i.toString());
    rows[id] = {
      id,
      label: `Row ${i}`,
    };
  }
  return rows;
}

function generateItems() {
  /**
   * @type { import("gantt-schedule-timeline-calendar").Items }
   */
  const items = {};
  // @ts-ignore
  let start = GSTC.api.date().startOf('day').subtract(6, 'day');
  for (let i = 0; i < 100; i++) {
    const id = GSTC.api.GSTCID(i.toString());
    const rowId = GSTC.api.GSTCID(Math.floor(Math.random() * 100).toString());
    start = start.add(1, 'day');
    items[id] = {
      id,
      label: `Item ${i}`,
      rowId,
      time: {
        start: start.valueOf(),
        end: start.add(1, 'day').endOf('day').valueOf(),
      },
    };
  }
  return items;
}

function initializeGSTC(element) {
  /**
   * @type { import("gantt-schedule-timeline-calendar").Config }
   */
  const config = {
    licenseKey:
    '====BEGIN LICENSE KEY====\nHqp+hSSS/bDvbp11g6e+7wFVN227KrIeUAZfzEPtQf+BknIEKQigSgnmqyJnWbcw4Y6IMLg46OXeTnCy3UIXpGh9QXffhqxiGOYXOVm96yZnwAUR7/8S5SZl65/1w3C6pM2bBpeRmBC68YDlYd/l0WuttXN41GGCF667I6XfzId0Q5PXwL5TujitaiwPL5g8XpIQcL2+q1j94ZdQj/aJ4DO5kGRMQrdd4Vc9n+9yZy42h3RFr3W1L7qZSjMosJFYJkzSME83T6V0qouRXxCq9S4F2mief5Ogq5PXNEeEKdnh9aJ5LSw/Ck/WLX0k5kLggOMyNCsZp/wy3l4qFZ4vDA==||U2FsdGVkX1/vQX/Tp9bfU9g5VLSbhC52E0ZJC1lfyzb/Xc3uK4/rwyhGa4QtC1bSunyauwc0cQpS/2jOe3/M+fBby9t72legV6YMroJVg0Q=\nZ8oHjzbmU5IJYiLIOkygHs/HarNkbusdOo8VNv0NepQetIbT1XoA6g5573bXFvjL+P8e13pQHbJFjWrN5GOK/9XA5ar9QY7Med3YTZUhxaz6caRHqMQVvhRss24QDAEoqrXok/3syV9gYO1hHNatFn9N4NGSA+/06+zLi5f0YJbUXLzxGTytOEKfdakITTbDFRux34+uyyCTfPQghaYFexNKJrgXE8G7IYjxG5XsjR/Rwk6BY70GJmeh3H9RDtvjzZSg2nur+oSBkp90rPmRGO1C8ZMZCkBReklJEgsYZfbkVYaMMOU2PSKAdakRBdheotxRp9E3Z5gvAFAyMhfMFw==\n====END LICENSE KEY====',
    plugins: [TimelinePointer(), Selection(), ItemResizing(), ItemMovement()],
    list: {
      columns: {
        data: {
          [GSTC.api.GSTCID('id')]: {
            id: GSTC.api.GSTCID('id'),
            width: 60,
            data: ({ row }) => GSTC.api.sourceID(row.id),
            header: {
              content: 'ID',
            },
          },
          [GSTC.api.GSTCID('label')]: {
            id: GSTC.api.GSTCID('label'),
            width: 200,
            data: 'label',
            header: {
              content: 'Label',
            },
          },
        },
      },
      rows: generateRows(),
    },
    chart: {
      items: generateItems(),
    },
  };

  state = GSTC.api.stateFromConfig(config);

  gstc = GSTC({
    element,
    state,
  });
}

const Relatorios = () => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
 
  };
  const api = useApi();
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [nomeObra,setNomeObra] = useState('...');
 

  
 
  const checkdaObra = async () => {
    
    const retorno = await api.checkObraStatus(id);
   

    if (retorno.error === '') {

      setNomeObra(retorno.obra)
      setOpen(false)

 
    } else {
      alert(retorno.error);
    }

  }
  
  useEffect(() =>{
    checkdaObra();
   
  
  }, []);
  

 
  const handleToggle = () => {
    setOpen(!open);
  };
 
//####################################################################
//################# breadcrumps ######################################
//####################################################################
const breadcrumbs = [
  { label: "G+P" , link: '/' },
  { label: nomeObra , link: '/obra/'+id },
  { label: "Cronogramas", isActive: true },

];
// 

const callback = useCallback((element) => {
  if (element) initializeGSTC(element);
}, []);
 

function updateFirstRow() {
  state.update(`config.list.rows.${GSTC.api.GSTCID('0')}`, (row) => {
    row.label = 'Changed dynamically';
    return row;
  });
}

function changeZoomLevel() {
  state.update('config.chart.time.zoom', 21);
}
 
  return (
    <PageContainer heading={"Obra : "+ nomeObra} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Divider />
          <div style={{ marginTop: 24 }}>
          <div className="toolbox">
        <button onClick={updateFirstRow}>Update first row</button>
        <button onClick={changeZoomLevel}>Change zoom level</button>
      </div>
      <div className="gstc-wrapper" ref={callback}></div>
           
          </div>
          </Grid>
          
          
          </GridContainer>
           
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </PageContainer>
  );
};

export default Relatorios;
