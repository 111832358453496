const baseUrl = 'https://main.gmaisp.tech/api'; // Produção
// const baseUrl = 'http://127.0.0.1:8000/api'; // Desenvolvi  mento

const request = async (method,endpoint,params , token = null , taketwo = null) => {
    method = method.toLowerCase();
    let fullUrl = `${baseUrl}${endpoint}`;
    let body = null;
    switch(method){
        case 'get' :
            let queryString = new URLSearchParams(params).toString();
            fullUrl += `?${queryString}`;
        break;
        case 'post':
        case 'put':
        case 'delete':
            body = JSON.stringify(params);
        break;
    }
    let headers;
    if (taketwo){
     headers = {'Content-Type': 'application/pdf'};
    }
    else {
     headers = {'Content-Type': 'application/json'};
    }
    
    if(token){
        headers.Authorization = `Bearer ${token}`;
    }
    let req = await fetch(fullUrl, {method,headers,body});
    let json = await req.json();
    return json;
}

export default () => {
    return {
        // funções basicas de login
        getToken: () => {
            return localStorage.getItem('token');
        },
        validateToken: async () => {
            let token =  localStorage.getItem('token');
            let json  = await request('post', '/auth/validate', {}, token);
            return json;
        },
        login: async (email, password) => {
            let json = await request('post', '/auth/login', {email,password});
            return json;
        },
        logout: async () => {
            let token =  localStorage.getItem('token');
            let json  = await request('post', '/auth/logout', {}, token);
            localStorage.removeItem('token');
            return json;
        },
        checkMe: async () => {
            let token = localStorage.getItem('token');
            let json  = await request('post', '/auth/me', {} , token);
            return json;
        },
        // Funções de Usuarios
        getAllUser: async () => {
            let token = localStorage.getItem('token');
            let json  = await request('get', '/users', {} , token);
            return json;
        },
        updateUser: async (id,data) => {
            let token = localStorage.getItem('token');
            let json  = await request('put', `/users/${id}`, data , token);
            return json;
        },
        removeUser: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('delete', `/users/${id}`, {} , token);
            return json;
        },
        addUser: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/users/cadastro`, data , token);
            return json;
        },
        getObras: async () => {
            let token = localStorage.getItem('token');
            let json  = await request('get', '/obras', {} , token);
            return json;
        },
        getAcessObras: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/users/${id}/obras`, {} , token);
            return json;
        }, 
        // Funções de Obras
        getAcessUsers: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/obras/${id}/users`, {} , token);
            return json;
        }, 

        addObra: async (data) => {
            let token = localStorage.getItem('token');
            let formData = new FormData();
            formData.append('obra', data.obra);
            formData.append('empresa', data.empresa);
            formData.append('estado', data.estado);
            formData.append('cidade', data.cidade);
            formData.append('endereco', data.endereco);
            formData.append('cronogramaId', data.cronogramaId);
            formData.append('TimelineID', data.TimelineID);
            formData.append('users', data.users);
            formData.append('Responsavel', data.Responsavel);

            if (data.fileObra){
            formData.append('fileObra', data.fileObra);
            formData.append('fileConstrutora', data.fileConstrutora);
            }
            let req = await fetch(`${baseUrl}/obras/cadastro`, {
                method: 'POST',
                headers: {
                    'Authorization':`Bearer ${token}`
                },
                body: formData });
                let json = await req.json();
                return json;

             
        },

        addTRelatorio: async (data) => {
            // let token = localStorage.getItem('token');
            let formData = new FormData();
            formData.append('obra', data.obra);
            formData.append('relatorios[]', data.relatorios, "rfs_tesdte.pdf");
            
            let req = await fetch(`https://gmaisp.com.br/app/web/integracao/timeline/relatorios`, {
                method: 'POST',
                headers: {
                    'Content-Type' : "multipart/form-data",
                    'Authorization':`Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MzQ5MDkxMjEsInVpZCI6NjUxLCJub21lIjoiTWF0ZXVzIFNlcnBhIiwiZW1haWwiOiJlbmcubXNlcnBhQGdtYWlsLmNvbSJ9.czIPNhk0eb7XrXRSrHMGpal3jc9x23t8h9Of1RHx3w0=`
                },
                body: formData });
                let json = await req.json();
                return json;

             
        },
        updateObra: async (id,data) => {
            let token = localStorage.getItem('token');
            let formData = new FormData();
            formData.append('obra', data.obra);
            formData.append('empresa', data.empresa);
            formData.append('estado', data.estado);
            formData.append('cidade', data.cidade);
            formData.append('endereco', data.endereco);
            formData.append('cronogramaId', data.cronogramaId);
            formData.append('TimelineID', data.TimelineID);
            formData.append('users', data.users);
            formData.append('Responsavel', data.Responsavel);
            if (data.fileObra){
            formData.append('fileObra', data.fileObra);
            }
            if (data.fileConstrutora){
            formData.append('fileConstrutora', data.fileConstrutora);
            }
            let req = await fetch(`${baseUrl}/obras/update/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization':`Bearer ${token}`
                },
                body: formData });
                let json = await req.json();
                return json;

            // let json  = await request('put', `/obras/${id}`, data , token);
            // return json;
        },
         

        getWall: async () => {
            let token = localStorage.getItem('token');
            let json  = await request('get', '/walls', {} , token);
            return json;
        },
        updateWall: async (id,data) => {
            let token = localStorage.getItem('token');
            let json  = await request('put', `/wall/${id}`, data , token);
            return json;
        },
        addWall: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/walls`, data , token);
            return json;
        },
        addTimelineStatus: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/walls`, data , token);
            return json;
        },
        removeWall: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('delete', `/wall/${id}`, {} , token);
            return json;
        },

        // Manage API
        getMyObras: async () => {
            let token = localStorage.getItem('token');
            let json  = await request('get', '/myobras', {} , token);
            return json;
        },

        checkCro: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/checkcro/${id}`, {} , token);
            return json;
        },
        checkObraStatus: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/status/${id}`, {} , token);
            return json;
        },
        checkCiclo: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', '/checkcro/', {} , token);
            return json;
        },
        addNewCiclo: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/users/cadastro`, data , token);
            return json;
        },
        makeCiclos: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/makeciclos`, data , token);
            return json;
        },
        clearObra: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/obra/clear`, data , token);
            return json;
        },
        getCiclos: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/ciclos/${id}`, {} , token);
            return json;
        },
        getResumeObra: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/resumeObra/${id}`, {} , token);
            return json;
        },
        getSemanasData: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/semanas/${id}`, {} , token);
            return json;
        },
        getManageData: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/managedata/${id}`, {} , token);
            return json;
        },
        setManageData: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/setrfmdata`, data , token);
            return json;
        },
        getDetailCiclo: async (id,detail) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/ciclos/${id}/${detail}`, {} , token);
            return json;
        },
        getCronogramas: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/cronogramas/${id}`, {} , token);
            return json;
        },
        getCronogramaAtual: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/cronogramaatual/${id}`, {} , token);
            return json;
        },
        getRelatorios: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/relatorios/${id}`, {} , token);
            return json;
        },
        getMedicoes: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/medicoes/${id}`, {} , token);
            return json;
        },
        getPavimentos: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/getpavimentos/${id}`, {} , token);
            return json;
        },
        gerarRelatorio: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/newrelatorios`, data , token);
            return json;
        },
        gerarPdfs: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/montarpdf`, data , token);
            return json;
        },
        geraAll: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/gerarmes`, data , token);
            return json;
        },
        getResumeTasks: async (id) => {
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/resumetask/${id}`, {} , token);
            return json;
        },
        getDyncamicRfs: async (id) => {
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/dynamic/rfs/${id}`, {} , token);
            return json;
        },
        getPavimentosTasks: async (id) => {
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/pavimentostask/${id}`, {} , token);
            return json;
        },
        getReportView: async (id,sigla) => {
            let report = sigla.toLowerCase();
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/rpt/${report}/${id}`, {} , token);
            return json;
        },
        getViewPdf: async () => {
            let taketwo = 1;
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/ping`, {} , token , taketwo);
            return json;
        },
        getReportOn: async (id,month,sigla) => {
            let report = sigla.toLowerCase();
            let taketwo = 1;
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/haverptinmonth/${id}/${month}/${report}`, {} , token , taketwo);
            return json;
        },
        getRfm: async (id) => {
            let taketwo = 1;
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/rpt/rfm/${id}`, {} , token , taketwo);
            return json;
            
        },
        getOs: async (id) => {
            let taketwo = 1;
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/rpt/os/${id}`, {} , token , taketwo);
            return json;
        },
        getKbn: async (id) => {
            let taketwo = 1;
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/rpt/kbn/${id}`, {} , token , taketwo);
            return json;
        },
        getAfs: async (id) => {
            let taketwo = 1;
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/rpt/afs/${id}`, {} , token , taketwo);
            return json;
        },
        getRfs: async (id,semana) => {
            let taketwo = 1;
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/rpt/rfs/${id}/${semana}`, {} , token , taketwo);
            return json;
        },
        getMfs: async (id) => {
            let taketwo = 1;
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/rpt/mfs/${id}`, {} , token , taketwo);
            return json;
        },
        checkRpt: async (id,data) => {
            let taketwo = 1;
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/checkrpt/${id}/${data}`, {} , token , taketwo);
            return json;
        },
        getAgrupamento: async (id) => {
            let token = localStorage.getItem('token');
            let json  =  await request('get', `/agrupamento/${id}`, {} , token);
            return json;
        },
        getEmpreiteiros: async () => {
            let token = localStorage.getItem('token');
            let json  = await request('get', '/empreiteiros', {} , token);
            return json;
        },
        setEmpreiteros: async (id,data) => {
            let token = localStorage.getItem('token');
            let json  = await request('put', `/empreiteiros/${id}`, data , token);
            return json;
        },
        putEmpreiteiros: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/empreiteiros/cadastro`, data , token);
            return json;
        },
        getMedicaoFile: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/files/getmedicao/${id}`, {} , token);
            return json;
        },
  
        addRpt: async (id,data,type) => {
            let token = localStorage.getItem('token');
            let formData = new FormData();
            // formData.append('title', data);
            if(data) {
                formData.append('file',data);
            }
            let req = await fetch(`${baseUrl}/grafico/${id}/${type}`,{
                method: 'POST',
                headers: { 
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });
            let json = await req.json();
            return json;

        },


        // FPLA APIS
        getBaseFpla: async (id) => {
            let token = localStorage.getItem('token');
            let json  = await request('get', `/getbasedfm/${id}`, {} , token);
            return json;
        },

        uploadAgrupamento: async (data) => {
            let token = localStorage.getItem('token');
            let formData = new FormData();
            formData.append('IdObra', data.IdObra);
            formData.append('file', data.file);
           
            let req = await fetch(`${baseUrl}/importarOrcamento`, {
                method: 'POST',
                headers: {
                    'Authorization':`Bearer ${token}`
                },
                body: formData });
                let json = await req.json();
                return json;

        },
        uploadVinculos: async (data) => {
            let token = localStorage.getItem('token');
            let formData = new FormData();
            formData.append('IdObra', data.IdObra);
            formData.append('file', data.file);
           
            let req = await fetch(`${baseUrl}/importarVinculo`, {
                method: 'POST',
                headers: {
                    'Authorization':`Bearer ${token}`
                },
                body: formData });
                let json = await req.json();
                return json;

        },
        setDistribuicao: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/makeDistribuicaoFisica`, data , token);
            return json;
        },
        createPavimentos: async (data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/createpavimentos`, data , token);
            return json;
        },
        getMessure: async (id) => {

            let token = localStorage.getItem('token');
            let json  = await request('get', `/medicao/${id}`, {} , token);
            return json;

        },
        getDetailMess: async (id) => {

            let token = localStorage.getItem('token');
            let json  = await request('get', `/getdetailmess/${id}`, {} , token);
            return json;

        },
        // Aplicativo de Medições
        setActualStart: async(data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/messure/update/actualstart`, data , token);
            return json;
        },
        setActualFinish: async(data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/messure/update/actualfinish`, data , token);
            return json;
        },
        setPercentComplete: async(data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/messure/update/percentcomplete`, data , token);
            return json;
        },
        setObservacao: async(data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/messure/update/updateObservacao`, data , token);
            return json;
        },
        setPhoto: async(data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/messure/update/updatePhoto`, data , token);
            return json;
        },
        getKbnBase64: async(id_relatorio) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/kanban/${id_relatorio}` , token);
            return json;
        },
        getOsBase64: async(id_relatorio) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/os/${id_relatorio}` , token);
            return json;
        },
        // Managers
        setSemanaData: async(data) => {
            let token = localStorage.getItem('token');
            let json  = await request('post', `/setsemanasdata`, data , token);
            return json;
        }

    };
;}