import React, { useEffect, useState,useRef } from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import HorizontalLabelPositionBelowStepper from '../../Widgets/Steps';
import { useParams } from 'react-router-dom';
import useApi from '../../../services/api'
import Card from '@material-ui/core/Card';
 
import Button from '@material-ui/core/Button';
import {  makeStyles } from '@material-ui/core/styles';
import Events from './Components/Events';

import Box from '@material-ui/core/Box';

import { useDispatch, useSelector } from 'react-redux';
import { getUserDetail } from '../../../redux/actions/ProfileApp';
import Skeleton from '@material-ui/lab/Skeleton';
import DataSheet from 'react-datasheet';

import GenericReportTable from './Components/reports/Generics'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme ) => ({
  pageFull: {
    width: '100%',
  },
  profileSidebar: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  profileMainContent: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
    
  }, backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));



const SamplePage = () => {

  const [idObra,setIdObra] = useState('');
  const [nomeObra,setNomeObra] = useState('Carregando...');
  const [situacao,setSituacao] = useState('Primeiros Passos');
  const [inicioObra,setInicioObra ] = useState('');
  const [terminoObra,setTerminoOBra ] = useState('');
  const [datastatus,setDataStatus ] = useState('');
  const [isCheckCro, setisCheckCro] = useState('');
  const [isCheckCiclo, setisCheckCiclo] = useState('');
  const [isOperacional, setisOperacional] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState('about');
  const [listColumn,setListColumn] = useState([]);
  const [openx, setOpenx] = React.useState(false);
  const handleToggle = () => {
    setOpenx(!open);
  };

  
//####################################################################
//#################  Constantes e funções da exibição de relatorios###
//####################################################################
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('xl');

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');
  const radioGroupRef = useRef(null);
  const handleClose = () => {
    setOpen(false);
    setReportComponent(false);
  };

  const handleClickOpen = (idrelatorio) => () => {

    setOpen(true);
    
  };

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  const descriptionElementRef = React.useRef(null);


// 
const getReportDetail = async (sigla,idrelatorio,semana) => {
handleToggle()
console.log(sigla,idrelatorio,semana);

switch (sigla) {
  
  case 'RFM':
  
    
    break;
  case 'RFS':
      const rfs = await getRfsDetail(idrelatorio,semana);
    
    break;
  case 'AFS':
 
    
    break;
  case 'MFS':
  
    
    break;
  case 'DBM':
  
    
    break;
  case 'DBM':
  
    
    break;
  default:
    setOpenx(false);
   alert('Relatorio não suportado')
    break;
}
}
// 

  

//####################################################################
//#################  Constantes e funções da exibição de relatorios###
//####################################################################
const [listReport, setListReport] = useState([]);
const [listHeaderReport, setListHeaderReport] = useState('');
const [reportComponent,setReportComponent] = useState();


const getRfsDetail = async (idreport,semana) => {

 const result = await api.getRfs(idreport,semana);
 

  if (result.error === '') {
    const rows = result.list.map((dats) => {
      return {
        IDTask: dats.IDTask,
        Servico: dats.Servico,
        Local: dats.Local,
        Empreiteiro: dats.Empreiteiro,
        Startz: dats.Startz,
        Finish: dats.Finish,
        ActualStart: dats.ActualStart,
        ActualFinish: dats.ActualFinish,
        PrevistoSemana1: dats.PrevistoSemana1,
        PrevistoSemana2: dats.PrevistoSemana2,
        PrevistoSemana3: dats.PrevistoSemana3,
        PrevistoSemana4: dats.PrevistoSemana4,
        PrevistoSemana5: dats.PrevistoSemana5,
        RealSemana1: dats.RealSemana1,
        RealSemana2: dats.RealSemana2,
        RealSemana3: dats.RealSemana3,
        RealSemana4: dats.RealSemana4,
        RealSemana5: dats.RealSemana5,
      }
    })
    const headerAfs = [
      'IDTask',
      'Servico',
      'Local',
      'Empreiteiro',
      'Startz',
      'Finish',
      'ActualStart',
      'ActualFinish',
      'PrevistoSemana1',
      'PrevistoSemana2',
      'PrevistoSemana3',
      'PrevistoSemana4',
      'PrevistoSemana5',
      'RealSemana1',
      'RealSemana2',
      'RealSemana3',
      'RealSemana4',
      'RealSemana5'];

    setListReport(rows);
    setListHeaderReport(headerAfs);
    
  

     const tabelafs =
     ( <GenericReportTable
     header={headerAfs}
     dados={rows}
     /> )
      setReportComponent(tabelafs)
       setOpen(true);
       setOpenx(false);
   
  } else {
    alert(result.error);
  }
}


const getRelatorioMedicao = async (idrelatorio,semana,numerosemana) => {

  handleToggle()
  const rfs = await getRfsDetalhe(idrelatorio,semana,numerosemana);
  }
//####################################################################
//#################  Constantes e funções da exibição de medição #####
//####################################################################
const [listDetalheMedicao, setListDetalheMedicao] = useState([]);
const [listHeaderDetailMedicao, setlistHeaderDetailMedicao] = useState('');
const [medicaoComponent,setmedicaoComponent] = useState();


const getRfsDetalhe = async (idreport,semana,numsemana) => {

 const result = await api.getRfs(idreport,semana);
 
 let previstoSemana = "PrevistoSemana"+numsemana;
 let realsemana = "RealSemana"+numsemana;

  if (result.error === '') {
    const rows = result.list.map((dats) => {
 
      return {
        IDTask: dats.IDTask,
        Servico: dats.Servico,
        Local: dats.Local,
        Empreiteiro: dats.Empreiteiro,
        Startz: dats.Startz,
        Finish: dats.Finish,
        ActualStart: dats.ActualStart,
        ActualFinish: dats.ActualFinish,
        PrevistoSemana: dats[previstoSemana],
        RealSemana: dats[realsemana],
      }
    })
    const headerAfs = [
      'IDTask',
      'Servico',
      'Local',
      'Empreiteiro',
      'Startz',
      'Finish',
      'ActualStart',
      'ActualFinish',
      'PrevistoSemana',
      'RealSemana' ];

      setListDetalheMedicao(rows);
      setlistHeaderDetailMedicao(headerAfs);
      
      console.table(rows);
    
      console.table(headerAfs);

     const tabelafs =
     ( <GenericReportTable
     header={headerAfs}
     dados={rows}
     /> )

      setReportComponent(tabelafs)
       setOpen(true);
       setOpenx(false);
   
  } else {
    alert(result.error);
  }
}

 const user = {
    id: 3432434,
    name: 'Kiley Brown',
    cover_pic: 'https://via.placeholder.com/348x332',
    username: 'kiley',
    location: 'Florida, USA',
    profile_pic: 'https://via.placeholder.com/150x150',
    followers: 2342,
    following: 47,
    email: 'domnicharris@example.com',
    website: 'www.domnic.com',
    phone: '+1-987(454)987',
    friends: {
      total: 327,
      frndProfiles: [
        {
          id: 4523,
          profile_pic: 'https://via.placeholder.com/150x150',
          name: 'Svetlana Harris',
          status: 'online',
        },
        {
          id: 5454,
          profile_pic: 'https://via.placeholder.com/150x150',
          name: 'Mark Taylor',
          status: 'offline',
        },
        {
          id: 3434,
          profile_pic: 'https://via.placeholder.com/150x150',
          name: 'Maxmilian',
          status: 'away',
        },
        {
          id: 3455,
          profile_pic: 'https://via.placeholder.com/150x150',
          name: 'Jonas Milian',
          status: 'away',
        },
        {
          id: 5634,
          profile_pic: 'https://via.placeholder.com/150x150',
          name: 'Josh Brake',
          status: 'offline',
        },
      ],
      mutual: 27,
    },
    pictures: [
      {
        id: 123,
        url: 'https://via.placeholder.com/150x150',
        size: '1.2 mb',
        name: 'Aunt',
      },
      {
        id: 456,
        url: 'https://via.placeholder.com/150x150',
        size: '2.2 mb',
        name: 'Doctor',
      },
      {
        id: 456,
        url: 'https://via.placeholder.com/150x150',
        size: '4.2 mb',
        name: 'Candid',
      },
      {
        id: 76456,
        url: 'https://via.placeholder.com/80x80',
        size: '1.9 mb',
        name: 'Watch',
      },
      {
        id: 456,
        url: 'https://via.placeholder.com/150x150',
        size: '1.6 mb',
        name: 'Me',
      },
      {
        id: 456,
        url: 'https://via.placeholder.com/150x150',
        size: '1.4 mb',
        name: 'Candid',
      },
    ],
    company: 'G-axon Tech Pvt. Ltd.',
    birthday: 'Oct 25, 1994',
    college: 'Oxford University',
    locality: 'Switzerland',
    family: [
      {
        id: 4343,
        name: 'John',
        profile_pic: 'https://via.placeholder.com/150x150',
      },
      {
        id: 5454,
        name: 'Dhruva Sharma',
        profile_pic: 'https://via.placeholder.com/150x150',
      },
      {
        id: 8965,
        name: 'Lily Taylor',
        profile_pic: 'https://via.placeholder.com/150x150',
      },
      {
        id: 3457,
        name: 'Josh',
        profile_pic: 'https://via.placeholder.com/150x150',
      },
    ],
    events: [
      {
        id: 123,
        type: 'Musical Concert',
        title: 'Sundance Film Festival',
        location: 'Downsview Park, Toronto, Canada',
        date: 'Feb 23, 2020',
        thumb: 'https://via.placeholder.com/575x480',
      },
      {
        id: 433,
        type: 'Magic Show',
        title: 'Underwater Musical Festival',
        location: 'Downsview Park, Toronto, Canada',
        date: 'Feb 11, 2020',
        thumb: 'https://via.placeholder.com/575x480',
      },
      {
        id: 654,
        type: 'Musical Concert',
        title: 'Village Feast Fac',
        location: 'Downsview Park, Toronto, Canada',
        date: 'Jan 02, 2020',
        thumb: 'https://via.placeholder.com/575x480',
      },
    ],
  };
  
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { id } = useParams();

  
 


  const api = useApi();
  const [list, setList] = useState([]);
  
  const getList = async () => {
   
    const result = await api.getMyObras();
   
    if(result.error === ''){
        setList(result.list);
    }else {
        alert(result.error);
    }
  }
  
//####################################################################
//################# funções de listagem do Dados Obra ################
//####################################################################

  const checkdaObra = async () => {
    
    const retorno = await api.checkObraStatus(id);

    if (retorno.error === '') {

      localStorage.setItem('obraatual', retorno.obra)
      localStorage.setItem('idobra', id)
      setTerminoOBra(retorno.dados[0].termino);
      setInicioObra(retorno.dados[0].inicio);
      setDataStatus(retorno.dados[0].datastatus);
      setisCheckCro(retorno.cronograma);
      setisCheckCiclo(retorno.ciclo);
      setisOperacional(retorno.operacional);
      setNomeObra(retorno.obra)
 
    } else {
      alert(retorno.error);
    }

  }
//####################################################################
//################# funções de listagem do Cronograma ################
//####################################################################
  const [listCronogramas, setListCronogramas] = useState('');

  function teste(value) {
    window.alert(value)
  }
  const getListOfCronogramas = async () => {
   
    const result = await api.getCronogramas(id);
 
    if (result.error === '') {
      const rows = result.list.map((dats) => {
        return {
          DataHash: dats.DataHash,
          created_at: dats.created_at,
          action: <button onClick={(e)=>{teste(dats.DataHash)}}>Teste</button>
        }
      })
      setListCronogramas(rows);
      setListColumn(['DataHash','created_at','action']);

    } else {
      alert(result.error);
    }
  }

//####################################################################
//################# funções de listagem do Relatorios ################
//####################################################################
const [listRelatorios, setListRelatorios] = useState('');
const [listColumnRelatorios, setListColumnRelatorios] = useState('');

 
const getListOfRelatorios = async () => {
 
  const result = await api.getRelatorios(id);

  if (result.error === '') {
    const rows = result.list.map((dats) => {
      return {
        SiglaRelatorio: dats.SiglaRelatorio,
        Infos: dats.Infos,
        DataInfo: dats.DataInfo,
        Primaryc: dats.Primaryc,
        action: <Button onClick={(e)=>{getReportDetail(dats.SiglaRelatorio,dats.id,dats.SemanaInfo)}}>Teste</Button>
      }
    })
   
    setListRelatorios(rows);
    setListColumnRelatorios(['SiglaRelatorio','Infos','DataInfo','Primaryc','action']);

  } else {
    alert(result.error);
  }
}
//####################################################################
//################# funções de listagem de medições ##################
//####################################################################
const [listMedicoes, setListMedicoes] = useState('');
const [listColumnMedicoes, setListColumnMedicoes] = useState('');

 
const getListMedicoes = async () => {
 
  const result = await api.getMedicoes(id);

  if (result.error === '') {
    const rows = result.list.map((dats) => {
      return {
        Medicao: dats.Medicao,
        Situacao: dats.Situacao,
        DataStatus: dats.DataStatus,
        DataMedicao: dats.DataMedicao,
        InicioMedicao: dats.InicioMedicao,
        Link: dats.Link,
        action: <>
          <button onClick={(e)=>{getRelatorioMedicao(dats.Medicao,dats.DataMedicao,dats.NumSemana)}}>ACESSAR</button>
          <button onClick={handleClickOpen('paper')}>PDF</button>
         </>
      }
    })
   
    setListMedicoes(rows);
    setListColumnMedicoes(['Medicao','Situacao','DataStatus','DataMedicao','InicioMedicao','action']);
 
  } else {
    alert(result.error);
  }
}
//####################################################################
//################# breadcrumps ######################################
//####################################################################
  const breadcrumbs = [
    { label: <IntlMessages id={'Obras'} />, link: '/' },
    { label: nomeObra , isActive: true },
    { label: <IntlMessages id={situacao} />, isActive: true },

  ];

  const [news, setnews ] = useState('');

  const [datarfm, setdatarfm ] = useState('');

  const [grid,setgrid] = useState([]);

const loadgrid = async (list) => {

  const ofSelected = list.map( obj => {
    let rObj = [];
    rObj = [{value : obj.Periodo ,  readOnly: true},
            {value : obj.Previsto} ,
            {value : obj.Executado},
            {value : obj.Replanejado, readOnly: true},
            {value : obj.PrevistoAC, readOnly: true},
            {value : obj.ExecutadoAC, readOnly: true},
            {value : obj.ReplanejadoAC, readOnly: true},
            {valye: '', readOnly: true}
        ]
    
    return rObj
  }

  )

  const grid =   [
    [{readOnly: true, colSpan: 8, value: 'Tabela de Edição do RFM', width: '400px'}],

    [
      { value: 'Periodo', readOnly: true }, // 0
      { value: 'Previsto', readOnly: true }, // 1
      { value: 'Executado', readOnly: true }, // 2
      { value: 'Replanejado', readOnly: true }, // 3
      { value: 'PrevistoAC', readOnly: true }, // 4
      { value: 'ExecutadoAC', readOnly: true }, // 5
      { value: 'ReplanejadoAC', readOnly: true }, // 6
      { value: 'Tendecia', readOnly: true }, // 7


      
    ],
    ];
   
const  newlist = grid.concat(ofSelected)
  console.log(newlist)
  setnews(newlist)
  setdatarfm()
  setgrid(newlist)
}
  const handleSaveRfm =  async (data) => {
  
    let result  = await api.setManageData(data);
   }
 
   const getdatalist  = async () => {
 

    const result = await api.getManageData(id);
    if (result.error === '') {
      loadgrid(result.list);
      console.log(result.list);
  
      
    } else {
      alert(result.error);
    }
  }
  const valueRenderer = cell => cell.value;
  const onCellsChanged = changes => {
    console.log(changes)
    const nome = grid
    changes.forEach(({ cell, row, col, value }) => {
      
      
      if ( col == 1) {
      
        let data = {
          IdObra: id,
          Periodo: nome[row][0].value,
          field:'Previsto',
          value:value
        }
        handleSaveRfm(data);
        getdatalist()
        // console.log('Previsto');
        // console.log(nome[row][0].value)
        // setPeriodo(changes[row][1])
      }
      if ( col == 2) {
        console.log('Executado');
        let data = {
          IdObra: id,
          Periodo: nome[row][0].value,
          field:'Executado',
          value:value
        }
        handleSaveRfm(data);
        getdatalist()


      }

      
      nome[row][col].value = value

    });
    setgrid(nome)
  };

  
  const onContextMenu = (e, cell, i, j) =>
    cell.readOnly ? e.preventDefault() : null;

  useEffect(() =>{
    
    getList();
    checkdaObra();
    getListOfCronogramas();
    getListOfRelatorios();
    getListMedicoes();

    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  

  return (
    <>
    <PageContainer heading={nomeObra} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{ marginBottom: 10 }}>
          </div>
          <Divider />
          <div style={{ marginTop: 24 }}>
            <h3> </h3>
            
            <Card>
              {/* Implantação  */}
              {
              nomeObra != 'Carregando...' &&
              isCheckCiclo == 0 && (
             <HorizontalLabelPositionBelowStepper/>
              )}
            </Card>

            {/*  Manager */}
            {isCheckCiclo && (
              <>
           
            <GridContainer>
            
            <Grid item xs={12} lg={12} className={classes.profileMainContent}>
              <Box mb={6}>
                {listMedicoes && (
                    <Events 
                    cronogram={listCronogramas}
                    relatorios={listRelatorios}
                    relatoriosHeader={listColumnRelatorios}
                    medicoes={listMedicoes}
                    headerMedicoes={listColumnMedicoes}
                    events={user.events} 
                    colunas={listColumn}
                    />
                )}
             
              </Box>
              
            </Grid>
          </GridContainer>
            
            </>
            )}
            
          </div>
        </Grid>
      </GridContainer>
       
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        onEntering={handleEntering}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title"> Detalhe do Relatorio x </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                  {
                    listReport &&
                    reportComponent
                  }
         
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
          
        </DialogActions>
      </Dialog>
    </PageContainer>

    <Backdrop className={classes.backdrop} open={openx} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </>
  );
};

export default SamplePage;
