import React, { useState, useEffect } from 'react';
// import { useParams, useHistory } from 'react-router-dom';
// import DataSheet from 'react-datasheet';

// import 'react-datasheet/lib/react-datasheet.css';

// import useApi from 'services/api';
// import useFormats from 'services/api';
import Alert from '@material-ui/lab/Alert';

export default () => {
  // const history = useHistory();
  // const { id } = useParams();
  // const [visible, setVisible] = useState(!visible);

  // const [statusdata, setstatusdata] = useState('');
  // const [opcao, setopcao] = useState('');

  // const api = useApi();

  // const [loading, setLoading] = useState(true);
  // const [list, setList] = useState([]);
  // const [showModal, setShowModal] = useState(false);
  // const [modalAtrob, setModalAtrob] = useState(false);

  // const [modalLoading, setModalLoading] = useState(false);
  // const [ModalFpla, setModalFpla] = useState(false);

  // const [dast, setDast] = useState([]);
  // const [isagrupamento, setIsAgrupamento] = useState('');
  // const [isvinculo, setisVinculo] = useState('');
  // const [radiovalue, setRadioValue] = useState(1);

  // let main;
  // // serve para quando carregar a pagina
  // useEffect(() => {
  //   setLoading(true);
  //   // checkdaObra();
  //   CallListResumeTasks();
  //   getList();
  //   getListOfCronogramas();
  //   getListOfRelatorios();
  //   getAgrup();
  //   getdatalist();
  // }, []);

  // const handleCloseModal = () => {
  //   setShowModal(false);
  //   setModalFpla(false);
  //   setModalAtrob(false);
  // };

  // const [active, setActive] = useState(1);

  // const fields = ['DataHash', 'Quem', 'Situacao', 'created_at', 'actions'];
  // const getList = async () => {
  //   setLoading(true);
  //   const result = await api.getCiclos(id);
  //   setLoading(false);
  //   if (result.error === '') {
  //     setList(result.list);
  //   } else {
  //     alert(result.error);
  //   }
  // };

  // const getdatalist = async () => {
    
  //   setLoading(true);

  //   const result = await api.getSemanasData(id);
  //   if (result.error === '') {
  //     loadgrid(result.list);
  //     console.log(result.list);
  //     setLoading(false);
  //   } else {
  //     alert(result.error);
  //   }
  // };
  // const finalizarloading = async () => {
  //   setVisible(!visible);
  // };

  // const [listAgrupamento, setListAgrupamento] = useState('');
  // const [listVinculos, setListVinculos] = useState('');

  // const getAgrup = async () => {
  //   setLoading(true);
  //   const result = await api.getAgrupamento(id);
  //   if (result.error === '') {
  //     setListAgrupamento(result.agrupamento);
  //     setListVinculos(result.vinculos);
  //   } else {
  //     alert(result.error);
  //   }
  // };
  // const [listCronogramas, setListCronogramas] = useState('');

  // const getListOfCronogramas = async () => {
  //   setLoading(true);
  //   const result = await api.getCronogramas(id);
  //   setLoading(false);
  //   if (result.error === '') {
  //     setListCronogramas(result.list);
  //   } else {
  //     alert(result.error);
  //   }
  // };

  // const camposRelatorios = ['id', 'SiglaRelatorio', 'QuemCadastrou', 'DataCadastro', 'Infos', 'actions'];

  // const [listRelatorios, setListRelatorios] = useState('');

  // const getListOfRelatorios = async () => {
  //   setLoading(true);
  //   const result = await api.getRelatorios(id);
  //   setLoading(false);
  //   if (result.error === '') {
  //     setListRelatorios(result.list);
  //   } else {
  //     alert(result.error);
  //   }
  //   finalizarloading();
  // };

  // const [isBegin, setIsBegin] = useState(false);

  // const [listBeginTask, setListBeginTask] = useState('');
  // const [listBeginTaskx, setListBeginTaskx] = useState('');

  // const [modalFileField, setModalFileField] = useState('');
  // const ExportToExcel = async () => {};
  // const CallListResumeTasks = async () => {
  //   const result = await api.getResumeTasks(id);
  //   if (result.error === '') {
  //     setListBeginTaskx(result.list);
  //     let atrib = '';
  //     atrib = result.list.map(tasks => {
  //       return <option value={tasks.UniqueID}>{tasks.Name}</option>;
  //     });
  //     setListBeginTask(atrib);
  //   } else {
  //     alert(result.error);
  //   }
  // };

  // const [tables, settables] = useState('');

  // const HandleRevertButton = e => {
  //   console.log('teste');
  // };

  // const [value, onChange] = useState(new Date());

  // const onClickHandler = e => {
  //   const hiddenElement = e.currentTarget.nextSibling;
  //   hiddenElement.className.indexOf('collapse show') > -1
  //     ? hiddenElement.classList.remove('show')
  //     : hiddenElement.classList.add('show');
  // };

  // const HandlerAgrupamento = e => {
  //   setModalFpla(true);
  //   setIsAgrupamento(true);
  //   setisVinculo(false);

  //   getAgrup();
  // };
  // const handlerVinculo = e => {
  //   setModalFpla(true);
  //   setIsAgrupamento(false);
  //   setisVinculo(true);

  //   getAgrup();
  // };
  // const modalSaveFile = async () => {};

  // const [news, setnews] = useState('');

  // const [datarfm, setdatarfm] = useState('');

  // const [grid, setgrid] = useState([]);

  // const loadgrid = async list => {
  //   const ofSelected = list.map(obj => {
  //     let rObj = [];
  //     rObj = [
  //       { value: obj.MesCiclo, readOnly: true },
  //       { value: obj.InicioCiclo, readOnly: true },
  //       { value: obj.TerminoCiclo, readOnly: true },
  //       { value: obj.PercentualPrevisto },
  //       { value: obj.PrevistoPeso },
  //       { value: obj.PrevistoPesoAC },
  //       { value: obj.ExecutadoRfsAC },
  //       { value: obj.ExecutadoRfs },
  //       { value: obj.Aderencia },
 
  //     ];

  //     return rObj;
  //   });

  //   const grid = [
  //     [{ readOnly: true, colSpan: 8, value: 'Tabela de Edição do RFM', width: '400px' }],
  //     [
  //       { value: 'Mês', readOnly: true }, // 0
  //       { value: 'Inicio Ciclo', readOnly: true }, // 1
  //       { value: 'Termino Ciclo', readOnly: true }, // 2
  //       { value: 'Previsto Base', readOnly: true }, // 3
  //       { value: 'Previsto Mês Rfs', readOnly: true }, // 4
  //       { value: 'Previsto AC Rfs', readOnly: true }, // 5
  //       { value: 'Executado AC Rfs', readOnly: true }, // 6
  //       { value: 'Executado Rfs', readOnly: true }, // 6
  //       { value: 'Aderencia', readOnly: true }, // 6
 
     
  //     ],
  //     [
  //       { value: 'MesCiclo', readOnly: true }, // 0
  //       { value: 'InicioCiclo', readOnly: true }, // 1
  //       { value: 'TerminoCiclo', readOnly: true }, // 2
  //       { value: 'PercentualPrevisto', readOnly: true }, // 3
  //       { value: 'PrevistoPeso', readOnly: true }, // 4
  //       { value: 'PrevistoPesoAC', readOnly: true }, // 5
  //       { value: 'ExecutadoRfsAC', readOnly: true }, // 6
  //       { value: 'ExecutadoRfs', readOnly: true }, // 6
  //       { value: 'Aderencia', readOnly: true }, // 6
 
     
  //     ],
  //   ];

  //   const newlist = grid.concat(ofSelected);
  //   console.log(newlist);
  //   setnews(newlist);
  //   setdatarfm();
  //   setgrid(newlist);
  // };
  // const handleSaveData = async data => {
  //   let result = await api.setSemanaData(data);
  //   if (result.error == '') {
  //     await getdatalist();
  //   }
  // };

  // const subfun = () => {
  //   let x = '';
  //   if (isBegin == false) {
  //     setIsBegin(true);
  //     console.log(isBegin);
  //   }
  //   if (isBegin == true) {
  //     setIsBegin(false);
  //     console.log(isBegin);
  //   }
  // };
  // const valueRenderer = cell => cell.value;
  // const onCellsChanged = async changes => {
  //   console.log(changes);
  //   const nome = grid;
  //   changes.forEach(({ cell, row, col, value }) => {

  //      let fieldname = nome[2][col].value;
  //      let fieldvalue = value;
  //      let refTerminoCiclo = nome[row][2].value;
  //     if(col != 0){
  //     let data = {
  //       IdObra: id,
  //       TerminoCiclo: refTerminoCiclo,
  //       field: fieldname,
  //       value: fieldvalue,

  //     }

  //      handleSaveData(data);

  //     nome[row][col].value = value;}
  //   });
  //   setgrid(nome);
  // };
  // const onContextMenu = (e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null);

  return (
    <>
      {/* {grid && ( */}
        <>
           <Alert severity="warning"> Cuidado ao Editar as Semanas!</Alert>

          {/* <DataSheet
            data={grid}
            valueRenderer={valueRenderer}
            onContextMenu={onContextMenu}
            onCellsChanged={onCellsChanged}
          /> */}
        </>
      {/* )} */}
    </>
  );
};
