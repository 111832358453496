//for getting  mail detail
import { fetchError, fetchStart, fetchSuccess } from './Common';
import useApi from 'services/api';
import useFormat from 'services/Formats';


const api = useApi();
const fix = useFormat();

export const getRfmData = async (id) => {

  const result = await api.getRfm(id);
 
  return dispatch => {

    dispatch(fetchStart());
    
    if (result.error == '') {
      dispatch(fetchSuccess());
      dispatch({ type: 'GET_PREVISTO', payload: result.listHeader[0].FisicoPrevisto });
      dispatch({ type: 'GET_REALIZADO', payload: result.listHeader[0].FisicoRealizado });
      dispatch({ type: 'GET_PREVISTO_AC', payload: result.listHeader[0].FisicoPrevisto });
      dispatch({ type: 'GET_REALIZADO_AC', payload: result.listHeader[0].FisicoRealizado });
      dispatch({ type: 'GET_DATASTATUS', payload: result.listHeader[0].DataStatus });
      dispatch({ type: 'GET_DESVIODOPRAZOPERC', payload: result.listHeader[0].DesviodoPrazoPerc });
      dispatch({ type: 'GET_DIASQUEFALTAM', payload: result.listHeader[0].DiasQueFaltam });
      dispatch({ type: 'GET_DIFERENCADIASUTEIS', payload: result.listHeader[0].DiferencaDiasUteis });
      dispatch({ type: 'GET_DURACAODIASUTEIS', payload: result.listHeader[0].DuracaoDiasUteis });
      dispatch({ type: 'GET_DURACAOGERENCIAL', payload: result.listHeader[0].DuracaoGerencial });
      dispatch({ type: 'GET_DURACAOMETA', payload: result.listHeader[0].DuracaoMeta });
      dispatch({ type: 'GET_FISICOPREVISTO', payload: result.listHeader[0].FisicoPrevisto });
      dispatch({ type: 'GET_FISICOREALIZADO', payload: result.listHeader[0].FisicoRealizado });
      dispatch({ type: 'GET_INICIOMETA', payload: fix.mesAbreviado(result.listHeader[0].InicioMeta)});
      dispatch({ type: 'GET_INICIOREPLANEJADO', payload: fix.mesAbreviado(result.listHeader[0].InicioReplanejado) });
      dispatch({ type: 'GET_MEDIARFM', payload: result.listHeader[0].MediaRfm });
      dispatch({ type: 'GET_TENDENCIADATA', payload: result.listHeader[0].TendenciaData });
      dispatch({ type: 'GET_REALIZADOSOBREPREVISTO', payload: result.listHeader[0].RealizadoSobrePrevisto });
      dispatch({ type: 'GET_TENDENCIADIAS', payload: result.listHeader[0].TendenciaDias });
      dispatch({ type: 'GET_TERMINOMETA', payload: fix.mesAbreviado(result.listHeader[0].TerminoMeta) });
      dispatch({ type: 'GET_TERMINOREPLANEJADO', payload: fix.mesAbreviado(result.listHeader[0].TerminoReplanejado) });
      dispatch({ type: 'GET_OBRAEXECUTADA', payload: result.listHeader[0].obraExecutada });

      dispatch({ type: 'GET_LIST', payload: result.list});

    }else {
      dispatch(fetchError('Something went wrong'));
    }
 
  };
};
