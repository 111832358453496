import React, { useEffect, useState , useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
 
import useApi from '../../../../services/api'
 
import { NavLink , useParams } from 'react-router-dom';

 
import makeStyles from '@material-ui/core/styles/makeStyles';
  

import GenericReportTable from './../Components/reports/Generics'
 
 
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


import Generics from '../Components/table/Generics';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';


 
const useStyles = makeStyles(theme => ({
  navLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    color: theme.palette.text.secondary,
  },
  iconRoot: {
    fontSize: 18,
    marginRight: 10,
  },
  titleSpace: {
    marginBottom: 20,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      marginTop: 15,
    },
    
    
  },
  carding: {
      cursor:"pointer",
      '&:hover': {
        boxShadow: '0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)',
        '& $iconThumb': {
          width: 95,
          height: '100%',
          borderRadius: 0,
        },
        '& $hoverContent': {
          transform: 'translate(-10px, -50%)',
        }
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));
const Anexos = () => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
 
  };
  const api = useApi();
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [nomeObra,setNomeObra] = useState('...');
 

  
 
  const checkdaObra = async () => {
    
    const retorno = await api.checkObraStatus(id);
   

    if (retorno.error === '') {

      setNomeObra(retorno.obra)
      setOpen(false)

 
    } else {
      alert(retorno.error);
    }

  }
  
  useEffect(() =>{
    getListOfPavimentos();
    checkdaObra();
  }, []);
  

 
  const handleToggle = () => {
    setOpen(!open);
  };
 
//####################################################################
//################# breadcrumps ######################################
//####################################################################
const breadcrumbs = [
  { label: "G+P" , link: '/' },
  { label: nomeObra , link: '/obra/'+id },
  { label: "Pavimentos", isActive: true },

];
// 

//####################################################################
//################# funções de listagem do Cronograma ################
//####################################################################
const [listCronogramas, setListCronogramas] = useState('');
const [listColumn,setListColumn] = useState('');
const [validRpt,setValidRpt] = useState(false);
function teste(value) {
  window.alert(value)
}
const getListOfPavimentos = async () => {
 
  const result = await api.getPavimentos(id);

  if (result.error === '') {
    const rows = result.pavimentos.map((dats) => {
      return {
        Ordem: dats.Ordem,
        Bloco: dats.Bloco,
        Local: dats.Local,
        QRCODE: <img src={dats.QRCODE}></img>,
        action: <button onClick={(e)=>{teste(dats.Link)}}>Teste</button>
      }
    })
    setListCronogramas(rows);
    setListColumn(['Ordem','Bloco','Local','QRCODE','action']);
    setValidRpt(true);
  } else {
    alert(result.error);
  }
}

 
  return (
    <PageContainer heading={"Obra : "+ nomeObra} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Divider />
          <div style={{ marginTop: 24 }}>
          {validRpt && 
            <Generics
            cronogramaData={listCronogramas}
            colun={listColumn}/>
          }
           
          </div>
          </Grid>
          
          
          </GridContainer>
           <GridContainer>
             <Grid item xs={12}>
               Hekllo
             </Grid>
             </GridContainer>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </PageContainer>
  );
};

export default Anexos;
