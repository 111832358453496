import React, { useState, useRef, useEffect } from 'react';
import { Chart } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import useFormats from 'services/Formats';

const rand = () => Math.round(Math.random() * 5);

const MultiType = attributes => {
  const periodos = [];
  const previsto = [];
  const previstoAC = [];
  const Executado = [];
  const ExecutadoAC = [];
  const Replanejado = [];
  const ReplanejadoAC = [];
  const ReplanejadoAnterior = [];
  const ReplanejadoAnteriorHistorico = [];
  const ReplanejadoACCurva = [];
  const ReplanejadoACs = [];
  const fachada = [];
  const fundacao = [];
  const estrutura = [];

  const fix = useFormats();

  if (attributes.list.length != 0) {
    const objects = JSON.stringify(attributes.list);
    const letsx = JSON.parse(objects);
    console.log(attributes.list);
    console.log(letsx.length);
    for (let index = 0; index < letsx.length; index++) {
      periodos.push(fix.mesAbreviado(letsx[index].Periodo));
      previsto.push(letsx[index].Previsto);
      previstoAC.push(letsx[index].PrevistoAC);
      Executado.push(letsx[index].Executado);
      if (letsx[index].ExecutadoAC == 0) {
        ExecutadoAC.push(null);
      } else {
        ExecutadoAC.push(letsx[index].ExecutadoAC);
      }
      Replanejado.push(letsx[index].Replanejado);
      if (letsx[index].ReplanejadoAC == 0) {
        ReplanejadoAC.push(null);
      } else {
        ReplanejadoAC.push(letsx[index].ReplanejadoAC);
      }
      ReplanejadoAnterior.push(letsx[index].ReplanejadoAnterior);
      ReplanejadoAnteriorHistorico.push(letsx[index].ReplanejadoAnteriorHistorico);
      ReplanejadoACCurva.push(letsx[index].ReplanejadoACCurva);
      ReplanejadoACs.push(letsx[index].ReplanejadoACs);
    }

    console.log(periodos);
  }
  // console.log(attributes.auxi)
  if (attributes.auxi.length != 0) {
    const objeto = JSON.stringify(attributes.auxi);
    const result = JSON.parse(objeto);
    console.log(result);
    for (let i = 0; i < result.length; i++) {
      // fachada.push(result[i].)
    }
  }
  const data = {
    labels: periodos,
    datasets: [
      {
        label: 'Replan. Ant.',
        type: 'line',
        borderColor: '#F2700E',
        fill: false,
        pointStyle: 'line',
        data: ReplanejadoAnteriorHistorico,
      },
      {
        label: 'Previsto Ac.',
        type: 'line',
        borderColor: '#F2700E',
        fill: false,
        pointStyle: 'line',
        borderWidth: 2,
        data: previstoAC,
      },
      {
        label: 'Replanejado Ac',
        type: 'line',
        borderColor: '#0070C0',
        fill: false,
        pointStyle: 'line',
        data: ReplanejadoAC,
      },

      {
        label: 'Executado Ac',
        type: 'line',
        borderColor: '#00B050',
        fill: false,
        pointStyle: 'line',
        data: ExecutadoAC,
      },

      {
        label: 'Previsto',
        type: 'bar',
        backgroundColor: '#006600',
        pointStyle: 'rect',
        data: previsto,
      },

      {
        label: 'Replanejado Ac',
        type: 'line',
        borderColor: '#0070C0',
        fill: false,
        pointStyle: 'line',
        data: ReplanejadoACCurva,
      },

      {
        label: 'Executado',
        type: 'bar',
        backgroundColor: '#006600',
        pointStyle: 'rect',
        data: Executado,
      },

      {
        label: 'Replanejado',
        type: 'bar',
        backgroundColor: '#0070C0',
        pointStyle: 'rect',
        data: Replanejado,
      },

      {
        label: 'Replanejado Anterior',
        type: 'bar',
        backgroundColor: '#BFBFBF',
        pointStyle: 'rect',
        data: ReplanejadoAnterior,
      },

      {
        type: 'line',
        label: 'Fachada',
        backgroundColor: 'rgba(223,238,249,0.8)',
        borderColor: 'rgba(223,238,249,0.8)',
        pointRadius: false,
        pointColor: 'rgba(223,238,249,0.8)',
        pointStrokeColor: 'rgba(223,238,249,0.8)',
        pointHighlightFill: 'rgba(223,238,249,0.8)',
        pointHighlightStroke: 'rgba(223,238,249,0.8)',
        data: fachada,
      },

      {
        type: 'line',
        label: 'Estrutura',
        backgroundColor: 'rgba(255,226,197,0.8)',
        borderColor: 'rgba(255,226,197,0.8)',
        pointRadius: false,
        pointColor: 'rgba(255,226,197,0.8)',
        pointStrokeColor: 'rgba(255,226,197,0.8)',
        pointHighlightFill: 'rgba(255,226,197,0.8)',
        pointHighlightStroke: 'rgba(255,226,197,0.8)',
        data: estrutura,
      },

      {
        type: 'line',
        label: 'Fundação',
        backgroundColor: 'rgba(223,241,203,0.8)',
        borderColor: 'rgba(223,241,203,0.8)',
        pointRadius: false,
        pointColor: 'rgba(223,241,203,0.8)',
        pointStrokeColor: 'rgba(223,241,203,0.8)',
        pointHighlightFill: 'rgba(223,241,203,0.8)',
        pointHighlightStroke: 'rgba(223,241,203,0.8)',
        data: fundacao,
      },
    ],
  };
  const options = {
    legend: {
      position: 'bottom',
    },
     
    // onClick: function(evt, element) {
    //   if (element.length > 0) {
    //     console.log( element[0]._chart.chart.data.labels);
    //     // you can also get dataset of your selected element
    //     console.log(evt);
    //   }
    // },
  };

  return (
    <>
      <div className="header"></div>
      <Bar data={data} height="150" options={options}
      onElementsClick={elem => {
        try {
          var datas = data.datasets[elem[0]._datasetIndex].data;
          console.log("Cases", elem[0]._index);
          console.log("labels", periodos[elem[0]._index]);

        } catch (error) {
          console.log('ignore')
        }
       
    
        // datas = data.datasets[elem[1]._datasetIndex].data;
        // console.log("Recovered", elem[1]);
    
        // datas = data.datasets[elem[2]._datasetIndex].data;
        // console.log("Deaths",elem[2]);
      }}
      
      />
    </>
  );
};

export default MultiType;
