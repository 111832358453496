import React, { useEffect, useState } from 'react';

import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
 
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import { Multiselect } from 'multiselect-react-dropdown';
import useApi from '../../../../services/api'
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Generics from '../../Builders/Components/table/Generics'
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import MUIDataTable from "mui-datatables";
import { NavLink , useParams , useHistory} from 'react-router-dom';
 
const useStyles = makeStyles(theme => ({
    
  }));


const Resume = () => {
    
    const classes = useStyles();
    const [dast, setDast] = React.useState([]);
    const [selecteds, setSelecteds] =  React.useState([]);
    const history = useHistory();
    const { id } = useParams();

    const [modalPermission, setModalPermission] = React.useState('');
    const [modalObra, setModalObra] = React.useState(false);
    const [modalUser, setModalUser] = React.useState(false);
    const [logoObra,setLogoObra] = React.useState('');
    const [logoConstrutora,setLogoConstrutora] = React.useState('');
    
    const api = useApi();
    const [modalLoading, setModalLoading] = useState(false);
     

  
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const [nomeObra, setNomeObra] = useState('');
 
    const [modalEmpresa, setmodalEmpresa] = useState('');
    const [modalTimelineID, setModalTimelineID] = useState('');
    const [modalResponsavel,setModalResponsavel] = useState('');
    const [modalCronogramaId, setmodalCronogramaId] = useState('');
    const [modalCidade, setmodalCidade] = useState('');
    const [modalEstado, setmodalEstado] = useState('');
    const [modalEndereco, setmodalEndereco] = useState('');


    const handleClickOpen = () => {
      setModalObra(true);
    };
    const handleClickOpenuser = () => {
      setModalUser(true);
    };
  
    const handleClose = () => {
        setModalObra(false);
      setModalUser(false);

    };

    const onSelect = (dast) => {
        setModalPermission(dast)
        console.log(dast)
    }

    const onRemove = (dast) => {
        setModalPermission(dast)
        console.log(dast)
    }
    const handleChange = (event) => {
       
       
      }

      const handleModalSave = async () => { }

    const handleChangetab = (event, newValue) => {
        setValue(newValue);
      };
    
      const handleChangetabIndex = index => {
        setValue(index);
      };

      return (
          <>
              <GridContainer xs={12} md={12} xl={12}>

              <Grid item xs={12} md={4} xl={4}>
                      <TextField
                          style={{ width: '95%' }}
                          id="outlined-basic"
                          label="Nome Obra"
                          variant="outlined"
                          value={nomeObra}
                          disabled={modalLoading}
                          onChange={e => setNomeObra(e.target.value)} />
                  </Grid>
                  <Grid item xs={12} md={4} xl={4} style={{ width: '100%' }}>
                      <TextField
                          style={{ width: '95%' }}

                          id="outlined-basic"
                          label="Construtora"
                          variant="outlined"
                          value={modalEmpresa}
                          disabled={modalLoading}
                          onChange={e => setmodalEmpresa(e.target.value)} />
                  </Grid>
                  <Grid item xs={12} md={4} xl={4}>
                      <TextField
                          style={{ width: '95%' }}
                          id="outlined-basic"
                          label="Responsavel"
                          variant="outlined"
                          value={modalResponsavel}
                          disabled={modalLoading}
                          onChange={e => setModalResponsavel(e.target.value)} />
                  </Grid>
             
                  <Grid item xs={2} md={2} xl={2}>
                      <TextField
                          style={{ width: '95%' }}
                          id="outlined-basic"
                          label="UF"
                          variant="outlined"
                          value={modalEstado}
                          disabled={modalLoading}
                          onChange={e => setmodalEstado(e.target.value)} />


                  </Grid>
                  <Grid item xs={8} md={4} xl={4}>
                      <TextField
                          style={{ width: '95%' }}
                          id="outlined-basic"
                          label="Cidade"
                          variant="outlined"
                          value={modalCidade}
                          disabled={modalLoading}
                          onChange={e => setmodalCidade(e.target.value)} />

                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                      <TextField
                          style={{ width: '95%' }}
                          id="outlined-basic"
                          label="Endereço"
                          variant="outlined"
                          value={modalEndereco}
                          disabled={modalLoading}
                          onChange={e => setmodalEndereco(e.target.value)} />

                  </Grid>
                  {/* -- //TimelineID | CronogramaID */}
        {/* ///////////////////////// */}
                 <Grid item xs={12} md={6} xl={6}>
                      <TextField
                          style={{ width: '95%' }}
                          id="outlined-basic"
                          label="TimelineID"
                          variant="outlined"
                          value={modalTimelineID}
                          disabled={modalLoading}
                          onChange={e => setModalTimelineID(e.target.value)} />
                  </Grid>


                  <Grid item xs={12} md={6} xl={6}>
                      <TextField
                          style={{ width: '95%' }}
                          id="outlined-basic"
                          label="ID Cronograma"
                          variant="outlined"
                          value={modalCronogramaId}
                          disabled={modalLoading}
                          onChange={e => setmodalCronogramaId(e.target.value)} />


                  </Grid>
 
                  <Grid  item xs={12} md={6} xl={6}>
                      <p>Logo Obra</p>
                      <Input type="file" disabled={modalLoading} id="logoObra" onChange={(e) => { handleChange(e); } } label="Logo Obra" variant="outlined" />

                      <img src={logoObra} style={{ maxWidth: 160, maxHeight: 60 }} />

                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                      <p>Logo Construtora</p>
                      <Input type="file" disabled={modalLoading} id="logoConstrutora" onChange={(e) => { handleChange(e); } } label="Logo Obra" variant="outlined" />

                      <img src={logoConstrutora} style={{ maxWidth: 160, maxHeight: 60 }} />

                  </Grid>
                <Button onClick={(e) => { handleModalSave(); } } disabled={modalLoading} color="primary">
                  Atualizar
              </Button>
                  
              </GridContainer>

          </>
              
        );}
        export default Resume;