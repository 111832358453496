import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import useApi from '../../../../services/api';

import { useParams, useHistory } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';

import GenericReportTable from './../Components/reports/Generics';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Generics from '../Components/table/Generics';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { compareAsc, format } from 'date-fns';
import useFormats from 'services/Formats';

const useStyles = makeStyles(theme => ({
  navLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    color: theme.palette.text.secondary,
  },
  iconRoot: {
    fontSize: 18,
    marginRight: 10,
  },
  titleSpace: {
    marginBottom: 20,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      marginTop: 15,
    },
  },
  carding: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)',
      '& $iconThumb': {
        width: 95,
        height: '100%',
        borderRadius: 0,
      },
      '& $hoverContent': {
        transform: 'translate(-10px, -50%)',
      },
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const Builders = () => {
  const classes = useStyles();

  const formats = useFormats();
  const api = useApi();
  const history = useHistory();
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [nomeObra, setNomeObra] = useState('...');

  const [prazoMeta, setPrazoMeta] = useState('');
  const [prazoReprogramado, setPrazoReprogramado] = useState('');
  const [tendencia, setTendencia] = useState('');
  const [totalMeses, setTotalMeses] = useState('');
  const [mesesDecorridos, setMesesDecorridos] = useState('');
  const [desvioDoPrazo, setDesvioDoPrazo] = useState('');

  const [medicoesAbertas, setMedicoesAbertas] = useState('');
  const [medicoesFechadas, setMedicoesFechadas] = useState('');
  const [relatoriosGerados, setRelatoriosGerados] = useState('');
  const [relatoriosAprovados, setRelatoriosAprovados] = useState('');
  const [cronogramasImportados, setCronogramasImportados] = useState('');
  const [value, setValue] = React.useState(0);

  const reportRegenerate = async idrelatorio => {
    let data = {
      IdRelatorio: idrelatorio,
      type: 'MEDICAO',
      relatorio: 'RFS',
    };
    const result = await api.gerarPdfs(data);
    if (result.error != '') {
      alert('Relatorios Gerados!');
    } else {
      alert(result.error);
    }
  };

  const [openx, setOpenx] = React.useState(false);
  const [loader, setloader] = useState('');

  const [listMedicoes, setListMedicoes] = useState('');
  const [listColumnMedicoes, setListColumnMedicoes] = useState('');

  const getListMedicoes = async () => {
    const result = await api.getMedicoes(id);
    console.table(result);
    if (result.error === '') {
      const rows = result.list.map(dats => {
        return {
          Medicao: dats.id,
          DataMedicao: formats.dataPTBR(dats.DataMedicao),
          Situacao: dats.Situacao,
          // DataStatus: dats.DataStatus,
          // InicioMedicao: dats.InicioMedicao,
          Link: dats.Link,
          action: (
            <>
              <Button
                onClick={e => {
                  HandleMessureAcess(dats.id);
                }}>
                MEDIR
              </Button>
              <Button
                onClick={e => {
                  getRelatorioMedicao(dats.Medicao, dats.DataMedicao, dats.SemanaCiclo);
                }}>
                ACESSAR
              </Button>
              <Button
                onClick={e => {
                  reportRegenerate(dats.id);
                }}>
                REENVIAR
              </Button>
              <Button>PUBLICAR</Button>
            </>
          ),
        };
      });

      setListMedicoes(rows);
      setListColumnMedicoes(['Medicao', 'DataMedicao', 'Situacao', 'action']);
      setloader(true);
    } else {
      alert(result.error);
    }
  };

  const getList = async () => {
    const result = await api.getMyObras();

    if (result.error === '') {
      setList(result.list);
    } else {
      alert(result.error);
    }
  };

  const checkdaObra = async () => {
    const retorno = await api.checkObraStatus(id);
    const dadosGerais = await api.getResumeObra(id);

    if (retorno.error === '') {
      localStorage.setItem('obraatual', retorno.obra);
      localStorage.setItem('idobra', id);

      setPrazoMeta(dadosGerais.prazoMeta);
      setPrazoReprogramado(dadosGerais.prazoReprogramado);
      setTendencia(dadosGerais.tendencia);
      setTotalMeses(dadosGerais.totalMeses);
      setMesesDecorridos(dadosGerais.mesesDecorridos);
      setDesvioDoPrazo(dadosGerais.desvioDoPrazo);
      setMedicoesAbertas(dadosGerais.medicoesAbertas);
      setMedicoesFechadas(dadosGerais.medicoesFechadas);
      setRelatoriosGerados(dadosGerais.relatoriosGerados);
      setRelatoriosAprovados(dadosGerais.relatoriosAprovados);
      setCronogramasImportados(dadosGerais.cronogramasImportados);
      setNomeObra(retorno.obra);
      setOpen(false);
    } else {
      alert(retorno.error);
    }
  };

  useEffect(() => {
    getList();
    getListMedicoes();
    checkdaObra();
  }, []);

  function MouseOver(event) {
    event.target.style.background = '#808080';
  }
  function MouseOut(event) {
    event.target.style.background = '';
  }

  const handleToggle = () => {
    setOpen(!open);
  };

  const getRelatorioMedicao = async (idrelatorio, semana, numerosemana) => {
    handleToggle();
    const rfs = await getRfsDetalhe(idrelatorio, semana, numerosemana);
  };

  const HandleMessureAcess = async idmedicao => {
    history.push(`/messures/${idmedicao}`);
  };

  //####################################################################
  //#################  Constantes e funções da exibição de medição #####
  //####################################################################
  const [listDetalheMedicao, setListDetalheMedicao] = useState([]);
  const [listHeaderDetailMedicao, setlistHeaderDetailMedicao] = useState('');
  const [medicaoComponent, setmedicaoComponent] = useState();

  const getRfsDetalhe = async (idreport, semana, numsemana) => {
    const result = await api.getRfs(idreport, semana);

    let previstoSemana = 'PrevistoSemana' + numsemana;
    let realsemana = 'RealSemana' + numsemana;
    console.log(previstoSemana);
    if (result.error === '') {
      const rows = result.list.map(dats => {
        return {
          IDTask: dats.IDTask,
          Servico: dats.Servico,
          Local: dats.Local,
          Empreiteiro: dats.Empreiteiro,
          Startz: dats.Startz,
          Finish: dats.Finish,
          ActualStart: dats.ActualStart,
          ActualFinish: dats.ActualFinish,
          PrevistoSemana: dats[previstoSemana],
          RealSemana: dats[realsemana],
        };
      });
      const headerAfs = [
        'IDTask',
        'Servico',
        'Local',
        'Empreiteiro',
        'Startz',
        'Finish',
        'ActualStart',
        'ActualFinish',
        'PrevistoSemana',
        'RealSemana',
      ];

      setListDetalheMedicao(rows);
      setlistHeaderDetailMedicao(headerAfs);

      console.table(rows);

      console.table(headerAfs);

      const tabelafs = <GenericReportTable header={headerAfs} dados={rows} />;

      setReportComponent(tabelafs);
      setOpen(true);
      setOpenx(false);
    } else {
      alert(result.error);
    }
  };

  //####################################################################
  //#################  Constantes e funções da exibição de relatorios###
  //####################################################################
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('xl');

  const [scroll, setScroll] = useState('paper');
  const radioGroupRef = useRef(null);
  const handleClose = () => {
    setOpen(false);
    setReportComponent(false);
  };

  const handleClickOpen = idrelatorio => () => {
    setOpen(true);
  };

  const handleDownload = idrelatorio => () => {
    // window.open('http://localhost:8000/api/files/getmedicao/' + idrelatorio);
    let token = localStorage.getItem('token');

    // Change this to use your HTTP client
    fetch('https://main.gmaisp.tech/api/files/getmedicao/' + idrelatorio, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${token}`,
      },
    }) // FETCH BLOB FROM IT
      .then(response => response.blob())
      .then(blob => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        var _url = window.URL.createObjectURL(blob);
        window.open(_url, '_blank').focus(); // window.open + focus
      })
      .catch(err => {
        console.log(err);
      });
  };
  const handlesetStatus = () => {
    uploadStatus();
  };
  const handlePostFileTimeliner = idrelatorio => () => {
    let token = localStorage.getItem('token');

    // Change this to use your HTTP clzient
    fetch('https://main.gmaisp.tech/api/files/getmedicao/' + idrelatorio, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${token}`,
      },
    }) // FETCH BLOB FROM IT
      .then(response => response.blob())
      .then(blob => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        let file = new File([blob], 'RFS-teste.pdf');
        let data = {
          obra: 453,
          relatorios: file,
        };
        console.log(data);
        let apo = uploadFilex(data);
        // putfile(file)
      })
      .catch(err => {
        console.log(err);
      });
  };

  const uploadFilex = async data => {
    console.log('--START HERE');
    let formData = new FormData();
    formData.append('obra', data.obra);
    formData.append('relatorios[]', data.relatorios, 'AFS-tesdte.pdf');

    let req = await fetch(`https://gmaisp.com.br/app/web/integracao/timeline/relatorios`, {
      method: 'POST',
      // mode: 'no-cors',
      headers: {
        // 'Authorization': "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MzQ5MTgzNDQsInVpZCI6NjUxLCJub21lIjoiTWF0ZXVzIFNlcnBhIiwiZW1haWwiOiJlbmcubXNlcnBhQGdtYWlsLmNvbSJ9.PStvaATTnla+B3kiZcYAtXPjFgaAWOFrR8MITAsiw=",
        // 'Content-Type' : "multipart/form-data"
      },
      body: formData,
    });
    let json = await req.json();
    alert(json);
  };
  const uploadStatus = async () => {
    console.log('-- Initialize');
    // 	const data = { login: 'mateus@gmaisp.com.br' , password: '123456' };

    // fetch('https://gmaisp.com.br/app/web/integracao/login', {
    //   method: 'POST', // or 'PUT'
    //   headers: {
    //     /* 'Content-Type': 'application/json', */
    //   },
    //   body: JSON.stringify(data),
    // })
    // .then(response => response.json())
    // .then(status => {
    //   console.log('Success:', status);
    // })
    // .catch((message) => {
    //     console.error('Error:', message);

    // });
    // let formData = new FormData();
    // formData.append('obra', data.obra);
    // formData.append('relatorios[]', data.relatorios, "AFS-tesdte.pdf");
    // const data = { login: 'mateus@gmaisp.com.br' , password: '123456' };
    // let req = await fetch(`https://gmaisp.com.br/app/web/integracao/login`, {
    //     method: 'POST',
    //     // mode: 'no-cors',
    //     // headers: {
    //     //   // 'Authorization': "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MzQ4MzY0NTMsInVpZCI6NjUxLCJub21lIjoiTWF0ZXVzIFNlcnBhIiwiZW1haWwiOiJlbmcubXNlcnBhQGdtYWlsLmNvbSJ9.O8DrOfzcMnMxswH7klZ0WKBDzhOkacY1rBV4WV9FU=",
    //     //   'Content-Type' : "application/json"
    //     // },
    //     body: data
    //        });
    //     let json = await req.json();
    //     alert(json);
    let req = await fetch(`https://gmaisp.com.br/app/web/integracao/timeline/status`, {
      method: 'POST',
      // mode: 'no-cors',
      headers: {
        // 'Authorization': "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MzQ5MTgzNDQsInVpZCI6NjUxLCJub21lIjoiTWF0ZXVzIFNlcnBhIiwiZW1haWwiOiJlbmcubXNlcnBhQGdtYWlsLmNvbSJ9.PStvaATTnla+B3kiZcYAtXPjFgaAWOFrR8MITAsiw="
        // 'Content-Type' : "application/json"
      },
      body: {
        conteudo: 'testexxpo',
        construcao: 453,
        usuario: 651,
        admin: false,
      },
    });
    let json = await req.json();
    alert(json);
  };
  const putfile = async file => {
    // uploadFilex(data);
    // console.log(data);
    // let result  =  await api.addTRelatorio(data);
    // window.alert(result);
    // if (result.status == "error") {
    //   window.alert(result.status)
    // }
  };

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  const descriptionElementRef = React.useRef(null);

  //
  const getReportDetail = async (sigla, idrelatorio, semana) => {
    handleToggle();
    console.log(sigla, idrelatorio, semana);

    switch (sigla) {
      case 'RFM':
        break;
      case 'RFS':
        const rfs = await getRfsDetail(idrelatorio, semana);

        break;
      case 'AFS':
        break;
      case 'MFS':
        break;
      case 'DBM':
        break;
      case 'DBM':
        break;
      default:
        setOpenx(false);
        alert('Relatorio não suportado');
        break;
    }
  };
  const [listReport, setListReport] = useState([]);
  const [listHeaderReport, setListHeaderReport] = useState('');
  const [reportComponent, setReportComponent] = useState();

  const getRfsDetail = async (idreport, semana) => {
    const result = await api.getRfs(idreport, semana);

    if (result.error === '') {
      const rows = result.list.map(dats => {
        return {
          IDTask: dats.IDTask,
          Servico: dats.Servico,
          Local: dats.Local,
          Empreiteiro: dats.Empreiteiro,
          Startz: dats.Startz,
          Finish: dats.Finish,
          ActualStart: dats.ActualStart,
          ActualFinish: dats.ActualFinish,
          PrevistoSemana1: dats.PrevistoSemana1,
          PrevistoSemana2: dats.PrevistoSemana2,
          PrevistoSemana3: dats.PrevistoSemana3,
          PrevistoSemana4: dats.PrevistoSemana4,
          PrevistoSemana5: dats.PrevistoSemana5,
          RealSemana1: dats.RealSemana1,
          RealSemana2: dats.RealSemana2,
          RealSemana3: dats.RealSemana3,
          RealSemana4: dats.RealSemana4,
          RealSemana5: dats.RealSemana5,
        };
      });
      const headerAfs = [
        'IDTask',
        'Servico',
        'Local',
        'Empreiteiro',
        'Startz',
        'Finish',
        'ActualStart',
        'ActualFinish',
        'PrevistoSemana1',
        'PrevistoSemana2',
        'PrevistoSemana3',
        'PrevistoSemana4',
        'PrevistoSemana5',
        'RealSemanaUm',
        'RealSemana2',
        'RealSemana3',
        'RealSemana4',
        'RealSemana5',
      ];

      setListReport(rows);
      setListHeaderReport(headerAfs);

      const tabelafs = <GenericReportTable header={headerAfs} dados={rows} />;
      setReportComponent(tabelafs);
      setOpen(true);
      setOpenx(false);
    } else {
      alert(result.error);
    }
  };

  //####################################################################
  //################# breadcrumps ######################################
  //####################################################################
  const breadcrumbs = [
    { label: 'G+P', link: '/' },
    { label: nomeObra, link: '/obra/' + id },
    { label: 'Medições', isActive: true },
  ];
  //
  return (
    <PageContainer heading={'Obra : ' + nomeObra} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Divider />
          <div style={{ marginTop: 24 }}>
            {loader && <Generics cronogramaData={listMedicoes} colun={listColumnMedicoes} />}
          </div>
        </Grid>
      </GridContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        onEntering={handleEntering}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title"> Detalhe do Relatorio x </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
            {listReport && reportComponent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Publicar
          </Button>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </PageContainer>
  );
};

export default Builders;
