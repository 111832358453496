import React, { useEffect, useState } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useApi from 'services/api';
import useFormats from 'services/Formats';
import { Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import { useParams, useHistory } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 650,
  },
}));

export default function DenseTable() {
  const classes = useStyles();
  const api = useApi();
  const formats = useFormats();
  const { id } = useParams();
  const history = useHistory();
  const [list, setList] = useState('');

  //
  const getReportDetail = async (sigla, idrelatorio, semana) => {
    switch (sigla) {
      case 'RFM':
        history.push(`/dynamic/rfm/${id}/${idrelatorio}`);
        break;
      case 'RFS':
        history.push(`/dynamic/rfs/${id}/${idrelatorio}`);
        break;
      case 'AFS':
        break;
      case 'MFS':
        break;
      case 'DBM':
        break;
      default:
        alert('Relatorio não suportado');
        break;
    }
  };
  const getListOfRelatorios = async () => {
    let result = await api.getRelatorios(id);
    // filter 5 results of result.list
    if (result.error === '') {
      result = result.list.filter((item, index) => index < 4);
      const rows = result.map(dats => {
        return {
          SiglaRelatorio: dats.SiglaRelatorio,
          DataInfo: formats.dataPTBR(dats.DataInfo),
          Infos: dats.Infos,
          Primaryc: dats.Primaryc,
          action: (
            <div>
              <Button
                onClick={e => {
                  getReportDetail(dats.SiglaRelatorio, dats.id, dats.SemanaInfo);
                }}>
                Acessar
              </Button>
            </div>
          ),
        };
      });
      setList(rows);
    } else {
      alert(result.error);
    }
  };

  useEffect(() => {
    getListOfRelatorios();
  }, []);
  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Sigla</TableCell>
            <TableCell align="right">Data</TableCell>

            <TableCell align="right">Acesso</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list &&
            list.map(row => (
              <TableRow key={row.Medicao}>
                <TableCell component="th" scope="row" align="right">
                  {row.SiglaRelatorio}
                </TableCell>
                <TableCell align="right">{row.DataInfo}</TableCell>

                <TableCell align="right">{row.action}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
