import React from 'react';
import { TreeItem, TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from 'mui-datatables';
import {
  Box,
  FormGroup,
  FormHelperText,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import Multiselect from 'multiselect-react-dropdown';
import { makeStyles } from '@material-ui/core/styles';

const breadcrumbs = [
  { label: 'G+P', link: '/' },
  { label: 'ADMIN', link: '/admin/home' },
  { label: 'MANAGER', isActive: true },
];
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(6),
  },
}));
const options = {
  filterType: 'checkbox',
  viewColumns: false,
  print: false,
  download: false,
  rowsPerPage: 1000,
};
const data = [
  { name: 'Joe James', company: 'Test Corp', city: 'Yonkers', state: 'NY' },
  { name: 'John Walsh', company: 'Test Corp', city: 'Hartford', state: 'CT' },
  { name: 'Bob Herm', company: 'Test Corp', city: 'Tampa', state: 'FL' },
  { name: 'James Houston', company: 'Test Corp', city: 'Dallas', state: 'TX' },
];

const columns = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'city',
    label: 'City',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'state',
    label: 'State',
    options: {
      filter: true,
      sort: false,
    },
  },
];
export default function FileSystemNavigator() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    fisico: true,
    financeiro: false,
    rdo: false,
  });
  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { fisico, financeiro, rdo } = state;
  const error = [fisico, financeiro, rdo].filter(v => v).length !== 2;

  return (
    <PageContainer heading="Gerenciar Usuario" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={6}>
          <GridContainer>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Nome Usuario"
                variant="outlined"
                style={{ width: '100%' }}
                // value={modalUsername}
                onChange={e => {
                  //   setModalUsername(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                style={{ width: '100%' }}
                // value={modalEmail}
                onChange={e => {
                  //   setModalEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="TimelineID"
                variant="outlined"
                style={{ width: '100%' }}
                // value={modalTimelineID}
                onChange={e => {
                  //   setModalTimelineID(e.target.value);
                }}
              />
            </Grid>
          </GridContainer>
        </Grid>
        <Grid xs={6}>
          <GridContainer>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Box>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Tipo de Acesso</FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    // value={radioValue}
                    // onChange={e => setRadioValue(e.target.value)}
                  >
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Cliente" />
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Manager" />
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Admin" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    <FormLabel component="legend">Modulos de Acesso</FormLabel>

                    <FormControlLabel
                      control={<Checkbox checked={fisico} onChange={handleChange} name="fisico" />}
                      label="Fisico"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={financeiro} onChange={handleChange} name="financeiro" />}
                      label="Financeiro"
                    />
                    <FormControlLabel control={<Checkbox checked={rdo} onChange={handleChange} name="rdo" />} label="RDO" />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </GridContainer>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable title={'Listagem de Obras Com Acesso'} data={data} columns={columns} options={options} />
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
