import React , {useState,useEffect} from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

// import Select from '@material-ui/core/Select';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import  useApi  from '../../../services/api';
import  useFormats  from '../../../services/Formats';
import Select from 'react-select'



import DataSheet from 'react-datasheet';
import './style.css'


// Be sure to include styles at some point, probably during your bootstrapping

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.samplePage'} />, isActive: true },
];

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));

 
  

// VERSÃO WEB = DATATABLE
// VERSÃO MOBILE CARDS

const SamplePage = () => {
const api = useApi();
const { id } = useParams();
const [loading,setLoading] = useState();
const [ data, setData ] = useState([
  { active: true, firstName: 'Elon', lastName: 'Musk' },
  { active: false, firstName: 'Jeff', lastName: 'Bezos' },
])

const columnsGrid =   [
  [{readOnly: true, colSpan: 8, value: 'Tabela de Edição do RFM', width: '400px'}],

  [
    { value: 'IdTask', readOnly: true }, // 0
    { value: 'Local', readOnly: true }, // 1
    { value: 'Servico', readOnly: true }, // 2
    { value: 'Bloco', readOnly: true }, // 3
    { value: 'Setor', readOnly: true }, // 4
    { value: 'Empreiteiro', readOnly: true }, // 5
    { value: 'Startz', readOnly: true }, // 6
    { value: 'Finish', readOnly: true }, // 7
    { value: 'ActualStart', readOnly: true }, // 7
    { value: 'ActualFinish', readOnly: true }, // 7
    { value: 'UltimaMedicao', readOnly: true }, // 7
    { value: 'Previsto', readOnly: true }, // 7
    { value: 'Realizado', readOnly: true }, // 7
    
  ],
  ];

const [grid,setgrid] = useState([]);
const [optionsbloc,setOptionsBloc] = useState([]);

const [optionEmpreiteiros,setOptionEmpreiteiros] = useState('');
const [firstList,setFirstList] = useState([]);
const [firstListAltered,setFirstListALtered] = useState([]);
const [numSemanaAtual,setNumSemanaAtual ] = useState('');

let ultimaMedicao = '';
let previstoSemana = '';
let realsemana = '';

const getRfsDetalhe = async (idreport) => {
 
 const result = await api.getDetailMess(idreport);

  if (result.error === '') {
    setLoading(1);
 
    let optionBlocos = result.tableLocal.map((bloco)=>{
     return  <option value={bloco.Bloco}>{bloco.Bloco}</option>
    })
    setOptionsBloc(optionBlocos);
    let datax = [];

    let optionEmpreitero = result.tableEmpreiteiros[0].Empreteiro.map((emp)=>{
      
        datax.push({value:emp.Empreiteiro, label: emp.Empreiteiro});
      // return  <option value={emp.Empreiteiro}>{emp.Empreiteiro}</option>
     })
     datax.unshift({value:"", label:""});
     setOptionEmpreiteiros(datax);
   
     setNumSemanaAtual("RealSemana"+result.numsemana);
     ultimaMedicao = 'PercentComplete';
     previstoSemana = "PrevistoSemana"+result.numsemana;
     realsemana = "RealSemana"+result.numsemana;
    if(result.numsemana === 1){
        ultimaMedicao = 'PercentComplete';
    }else {
      let numeroultimaMedicao =  result.numsemana - 1;
      ultimaMedicao = "RealSemana"+numeroultimaMedicao;
    }
    let formated = '';

    const listtower = result.list.map((row)=> { return {
        IdTask: row.IDTask,
        Local: row.Local,
        Servico: row.Servico,
        Bloco: row.Bloco,
        Setor: row.Setor,
        Empreiteiro: row.Empreiteiro,
        Startz: row.Startz,
        Finish: row.Finish,
        ActualStart: row.ActualStart == "//" ? "":row.ActualStart,
        ActualFinish: row.ActualFinish == "//" ? "":row.ActualFinish,
        UltimaMedicao: row[ultimaMedicao]*100,
        Previsto: row[previstoSemana]*100,
        Realizado: row[realsemana]
      }});
      const listDatasheet = listtower.map( obj => {
        let rObj = [];
        rObj = [{value : obj.IdTask ,  readOnly: true},
                {value : obj.Local} ,
                {value : obj.Servico},
                {value : obj.Bloco, readOnly: true},
                {value : obj.Setor, readOnly: true},
                {value : obj.Empreiteiro, readOnly: true},
                {value : obj.Startz, readOnly: true},
                {value : obj.Finish, readOnly: true},
                {value : obj.ActualStart, readOnly: obj.ActualStart == "" ? false: true },
                {value : obj.ActualFinish, readOnly:  obj.ActualFinish == "" ? false: true },
                {value : obj.UltimaMedicao, readOnly: true},
                {value : obj.Previsto, readOnly: true},
                {value : obj.Realizado, readOnly: false},
            ]
        
        return rObj
      });
  const gridconcat = columnsGrid.concat(listDatasheet);
  setgrid(gridconcat)
  setFirstListALtered(gridconcat)
  setFirstList(listtower)
  } else {
    alert(result.error);
  }
}





// Se tiver Setor Aparece 
// Se tiver bloco aparece 
// se for bloco unico vai direto pro andar
// se for setor unico vai direto pro andar
// ao selecionar obloco ele  aparece setor se tiver
// se não tiver setor so aparece andar

// Função da Tabela de Edição de Medição+
 




 

const handleSaveRfm =  async (data) => {

  let result  = await api.setManageData(data);
 }

//  const subfun = () => {
//   let x  = '';
//   if (isBegin == false){
//    setIsBegin(true);
//    console.log(isBegin);

//   } 
//   if (isBegin == true ) {
//     setIsBegin(false);
//    console.log(isBegin);


//   }

//  }
const valueRenderer = cell => cell.value;
const onCellsChanged = changes => {
  console.log(changes)
  let table = grid
  changes.forEach(({ cell, row, col, value }) => {
    
    // COluna de Valor Realizado
    if ( col == 12) {
      // console.log(value)
      console.log(table[row][0].value)
      let idTask  = table[row][0].value

      // Coluna de Medição Anterior
      let MedicaoAnterior  = table[row][10].value
      let valorRealizado = value

      if (valorRealizado < MedicaoAnterior){
        table[row][col].value = MedicaoAnterior;
        alert("Atenção : a medição não pode ser menor que a ultima medição")
      }
      if(valorRealizado > 100 ) {
        table[row][col].value = 100
        alert("Atenção a medição não pode ser maior que 100")
      }
      if(valorRealizado < 0 ) {
        table[row][col].value = 0;
        alert("Atenção a medição não pode ser menor que 0")
      }
      if(valorRealizado > MedicaoAnterior && valorRealizado <= 100 && valorRealizado > 0 ) {
        table[row][col].value = valorRealizado
        HandleSaveRealizado(valorRealizado,table[row][0].value);
      }

      // Coluna de Medição Anterior
      
      // console.log(changes[row][1])
      // let data = {
      //   IdObra: id,
      //   Periodo: table[row][0].value,
      //   field:'Previsto',
      //   value:value
      // }
      // handleSaveRfm(data);
      // getdatalist()
      // console.log('Previsto');
      // console.log(table[row][0].value)
      // setPeriodo(changes[row][1])
    }
   
    //Coluna Inicio Atual
    if ( col == 8) {
      var date = new Date(value).toISOString();;
      alert(date);
    }
    //Coluna Término Atual
    if ( col == 8) {
      
    }
    

    
    // table[row][col].value = value

  });
  setgrid(table)
};
const HandleSaveRealizado = async (valor,IdTask) => {
    const data = {
      IdRfs: id,
      IdTask: IdTask,
      PercentComplete: valor,
      Semana: numSemanaAtual
    }
    const result = api.setPercentComplete(valor);

    if(result.error == ''){
          
    }
}
const onContextMenu = (e, cell, i, j) =>
  cell.readOnly ? e.preventDefault() : null;
// end


    const classes = useStyles();
    const [state, setState] = useState({
    age: '',
    name: 'Bloco',
  });
  const [emprState, setEmpState] = useState({
    age: '',
    name: 'Bloco',
  });
  const handleChange = event => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const handleEmpChage = event => {
    const name = event.target.Empreiteiro
    setEmpState({...state,[name]: event.target.name})
  }
const handleval =  (e) => {
  console.log(e);
  console.log("eslo")
}
const [selectedOption, setSelectedOption] = useState([]);
 
  useEffect(() =>{
    getRfsDetalhe(id);

}, [id]);


const changeFIlterEmpt = () => {
  
  
  if ( selectedOption.label == "") {
    setgrid(firstListAltered)
  }else { 
    // console.log([selectedOption])
    let objectFiltered = selectedOption;
    console.log(objectFiltered.label);
    let filtro = objectFiltered.label

    const listtower = firstList.filter(objeto => objeto.Empreiteiro === filtro).map((row)=> { 
      return {
               
              IdTask: row.IDTask,
              Local: row.Local,
              Servico: row.Servico,
              Bloco: row.Bloco,
              Setor: row.Setor,
              Empreiteiro: row.Empreiteiro,
              Startz: row.Startz,
              Finish: row.Finish,
              ActualStart: row.ActualStart == "//" ? "":row.ActualStart,
              ActualFinish: row.ActualFinish == "//" ? "":row.ActualFinish,
              UltimaMedicao: row[ultimaMedicao]*100,
              Previsto: row[previstoSemana]*100,
              Realizado: row[realsemana]
      }
        }
      );
      const listDatasheet = listtower.map( obj => {
        let rObj = [];
        rObj = [{value : obj.IdTask ,  readOnly: true},
                {value : obj.Local} ,
                {value : obj.Servico},
                {value : obj.Bloco, readOnly: true},
                {value : obj.Setor, readOnly: true},
                {value : obj.Empreiteiro, readOnly: true},
                {value : obj.Startz, readOnly: true},
                {value : obj.Finish, readOnly: true},
                {value : obj.ActualStart, readOnly: obj.ActualStart == "" ? false: true},
                {value : obj.ActualFinish, readOnly:  obj.ActualFinish == "" ? false: true },
                {value : obj.UltimaMedicao, readOnly: true},
                {value : obj.Previsto, readOnly: false},
                {value : obj.Realizado, readOnly: false},
            ]
        
        return rObj
      });

      const gridconcat = columnsGrid.concat(listDatasheet);
      setgrid(gridconcat)
     
  }

}


useEffect(() =>{
  changeFIlterEmpt();

}, [selectedOption]);
  return (
    <PageContainer heading={"MODULO - Medições"} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
        <GridContainer>
        <Grid item lg={4} xs={4} md={4}>
        <div style={{ marginBottom: 10 }}>
            <Select placeholder={"Empreiteiro"} options={optionEmpreiteiros} 
             defaultValue={""}
             value={selectedOption}
             onChange={setSelectedOption}
            ></Select>
        </div>
        </Grid>
        <Grid item lg={4} xs={4} md={4}>
        <div style={{ marginBottom: 10 }}>
            <Select options={optionEmpreiteiros}></Select>
        </div>
        </Grid>
        </GridContainer>
          <Divider />
         
        <Grid item xs={12}>
        <Box className={classes.root}>
            { grid && 
                        <>
                        <DataSheet
                        data={grid}
                        valueRenderer={valueRenderer}
                        onContextMenu={onContextMenu}
                        onCellsChanged={onCellsChanged}
                      />
                    </>
                        } 
            </Box>
                      </Grid>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default SamplePage;
