import React from 'react';
import { Box, Grid } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import PropTypes from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import blue from '@material-ui/core/colors/blue';
import GridContainer from '@jumbo/components/GridContainer';
import { useSelector } from 'react-redux';


import AccessTimeIcon from '@material-ui/icons/Today';
import AssignmentTurnedInIcon from '@material-ui/icons/Event';




const useStyles = makeStyles(theme => ({
  iconView: {
    backgroundColor: alpha(blue['500'], 0.1),
    color: blue['500'],
    padding: 8,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      display: 'block',
    },
    '&.web': {
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
      color: theme.palette.warning.main,
    },
    '&.phone': {
      backgroundColor: alpha(theme.palette.success.main, 0.15),
      color: theme.palette.success.dark,
    },
  },
  wordAddress: {
    wordBreak: 'break-all',
    cursor: 'pointer',
  },
}));

const Contact = props  => {

  // const { email, website, phone } = props;
  const classes = useStyles();
  // const fix = useFormat();
  const rfm = useSelector(({ Rfm }) => Rfm);

  // const dados = {
  //   inicioBase: fix.mesAbreviado(props.list[0].InicioMeta),
  //   terminoBase: fix.mesAbreviado(props.list[0].TerminoMeta),
  //   inicioReplan:  fix.mesAbreviado(props.list[0].InicioReplanejado),
  //   terminoReplan: fix.mesAbreviado(props.list[0].TerminoReplanejado),
  // }
  
  return (
    <CmtCard>
      <CmtCardHeader title="Informações" />
      <CmtCardContent>
      <GridContainer>
        <Grid xl={6} md={6} xs={6} sm={6} lg={6}>
        <Box display="flex" alignItems="center">
          <Box className={clsx(classes.iconView, 'phone')}>
            <AccessTimeIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12} color="text.secondary">
             Inicio Base
            </Box>
            <Box component="p" className={classes.wordAddress} fontSize={16} color="text.primary">
              {rfm.InicioMeta}
            </Box>
          </Box>
        </Box>
        </Grid>
        <Grid xl={6} md={6} xs={6} sm={6} lg={6}>
        <Box display="flex" alignItems="center">
          <Box className={clsx(classes.iconView, 'phone')}>
            <AssignmentTurnedInIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12} color="text.secondary">
             Término Base
            </Box>
            <Box component="p" className={classes.wordAddress} fontSize={16} color="text.primary">
                {rfm.TerminoMeta}
            </Box>
          </Box>
        </Box>
          </Grid>
          <br></br>
          <Grid  xl={6} md={6} xs={6} sm={6} lg={6}>
        <Box display="flex" alignItems="center">
          <Box className={clsx(classes.iconView, 'phone')}>
            <AccessTimeIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12} color="text.secondary">
             Inicio Replanejado
            </Box>
            <Box component="p" className={classes.wordAddress} fontSize={16} color="text.primary">
             {rfm.InicioReplanejado}
            </Box>
          </Box>
        </Box>
        </Grid>
        <Grid xl={6} md={6} xs={6} sm={6} lg={6}>
        <Box display="flex" alignItems="center">
          <Box className={clsx(classes.iconView, 'phone')}>
            <AssignmentTurnedInIcon />
          </Box>
          <Box ml={5}>
            <Box component="span" fontSize={12} color="text.secondary">
             Término Replanejado
            </Box>
            <Box component="p" className={classes.wordAddress} fontSize={16} color="text.primary">
            {rfm.TerminoReplanejado}

            </Box>
          </Box>
        </Box>
          </Grid>
        </GridContainer>
       
               
      </CmtCardContent>
    </CmtCard>
  );
};

export default Contact;

Contact.prototype = {
  userDetail: PropTypes.object.isRequired,
};
