import React , { useState ,useRef , useEffect } from 'react'

import { Doughnut } from 'react-chartjs-2';

const rand = () => Math.round(Math.random() * 5 );


const MultiType = attributes => {
  
  const periodos = [];
  const Executado = [];
  const previsto = [];


 
  console.log(attributes.AllData['list'])
  if(attributes.AllData.length != 0){

    const objects = JSON.stringify(attributes.AllData);
    const letsx = JSON.parse(objects);
    // console.log( Object.values(letsx['list'])[1].Empreiteiro);


    let i = 1;
    for (let index = 0; index < Object.keys(attributes.AllData["list"]).length; index++) {
      let previstname = ""
      const element =  Object.values(letsx['list'])[index].Empreiteiro;
      periodos.push(element)
      i++;
    }
    for (let index = 0; index < Object.keys(attributes.AllData["list"]).length ; index++) {
      const elements = Object.values(letsx['list'])[index].TarefasAtrasadas;
      Executado.push(elements)
    }
    console.log(periodos)
  
  }



  const data = {
    labels: periodos,
    datasets: [
      {
        label: '# of Votes',
        data: Executado,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    legend: {
      position: 'bottom'
    }
  }

 return (
 <>
    <div className='header'>
    
    </div>
    <Doughnut data={data}  height="150" options={options} />
  </>);
}

export default MultiType;