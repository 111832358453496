import React , { useState ,useRef , useEffect } from 'react'

import { Bar } from 'react-chartjs-2';
 

const AndamentoFisicoSemanalAC = attributes => { 
  const periodosx = [];
  const ExecutadoAC = [];
  const previstoAC = [];

  console.log(attributes);
  if(attributes.AllData.length != 0){

    for (let index = 1; index < Object.keys(attributes.AllData["data"]).length + 1; index++) {
      const element = attributes.AllData.data[index];
      periodosx.push(element)
    }
    for (let index = 1; index < Object.keys(attributes.AllData["Executado"]).length + 1; index++) {
      const elements = attributes.AllData.ExecutadoAC[index];
      ExecutadoAC.push(elements ==  0 ? null : elements)
    }
    for (let index = 1; index < Object.keys(attributes.AllData["previsto"]).length + 1; index++) {
      const elementsx = attributes.AllData.previstoAC[index];
      previstoAC.push(elementsx)
    }
    // console.log(periodos )
  
  }
  const data = {
    labels: periodosx,
    datasets: [ 
      
      {
          label: "Execução Ac.Prevista",
          type: "line",
          borderColor: "#00B050",
          fill: false,
          pointStyle: 'line',
        data: previstoAC,
      },
       
      
      {
          label: "Execução Ac. Realizada",
          type: "line",
          borderColor: "#0070C0",
          fill: false,
          pointStyle: 'line',
        data: ExecutadoAC,
      } 
      
      
    ],
  };
  const options = {
    legend: {
      position: 'bottom'
    },
    scales: {
      yAxes: [{ 
          display: true,
          ticks: {
             
              // OR //
              beginAtZero: false   // minimum value will be 0.
          }
      }]
  }
  }
  return (
  <>
    <div className='header'>
    
    </div>
    <Bar data={data}  height="150" options={options} />
  </>)
};

export default AndamentoFisicoSemanalAC;