import React, { useState } from 'react';
import { NavLink , useParams } from 'react-router-dom';

import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography, useTheme } from '@material-ui/core';
import CmtAvatarGroup from '@coremat/CmtAvatarGroup';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Box from '@material-ui/core/Box';
import CmtGridView from '@coremat/CmtGridView';
import PropTypes from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import CakeIcon from '@material-ui/icons/Cake';
import SchoolIcon from '@material-ui/icons/School';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CurvaS from '../graficos/CurvaS';
import RfmTable from '../tabelas/rfmtable';
import { useSelector  } from 'react-redux';
const tabs = [
  { id: 33232, title: 'Graficos', slug: 'Graficos' },
  { id: 45345, title: 'Tabela', slug: 'Tabela' },
  { id: 76545, title: 'PDF', slug: 'PDF' },
];

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 3,
      paddingBottom: 0,
    },
  },
  tabsList: {
    position: 'relative',
    minHeight: 60,
    '& .MuiTabs-indicator': {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
  },
  tabItem: {
    maxWidth: 'none',
    minWidth: 10,
    minHeight: 60,
    padding: '5px 10px',
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
  },
  columnRoot: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.5px',
  },
}));

 

const About = ( props ) => {
  const [tabValue, setTabValue] = useState('overview');
  const theme = useTheme();
  const rfm = useSelector(({ Rfm }) => Rfm);
  const [filex,setFilexs] =  useState('');
  const [resx,setResx] = useState('');
  const [fileto, setfileto] = useState('');
  const { relatorio } = useParams();

const handleDownload =  () => {
  // window.open('http://localhost:8000/api/files/getmedicao/' + idrelatorio);
  let token = localStorage.getItem('token');
  console.log(token)
    // idrelatorio  = 655 ;
    // Change this to use your HTTP client
    fetch('https://main.gmaisp.tech/api/files/getreport/' + relatorio, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/pdf',
        'Authorization':`Bearer ${token}`
      } } ) // FETCH BLOB FROM IT
    .then((response) => response.blob())
    .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
      var _url = window.URL.createObjectURL(blob);
      setFilexs(_url);
      setfileto(new File([blob],'AFS-teste.pdf'))
      console.log(_url)
      // window.open(_url, "_blank").focus(); // window.open + focus
  }).catch((err) => {
    console.log(err);
  });
  }

    let Curva =   'Graficos';
  console.log('1');
  setTimeout(function() {
    console.log(2);
  },10)
  setTimeout(function() {
    console.log(3);
  },0)
  console.log('4')
    const [ group , setGroup ] = useState(Curva);
    const [tables,settables] = useState('');
    const [pdfs,setPdfs] = useState('');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    console.log(newValue)
    if(newValue == 'Graficos'){
      setGroup(newValue)
      settables(null)
      setPdfs(null)


    }
    if(newValue == 'Tabela') {
      setGroup(null)
      setPdfs(null)

      settables(newValue)
    }
    if(newValue == 'PDF') {
      handleDownload()
      setGroup(null)
      settables(null)
      setPdfs(newValue)
    }
  };
  const classes = useStyles();


  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader
        separator={{
          color: theme.palette.borderColor.dark,
        }}
        title="Andamento Fisico">
        <Tabs className={classes.tabsList} value={tabValue} onChange={handleTabChange} aria-label="profile tabs">
          {tabs.map((item, index) => {
            return <Tab className={classes.tabItem} key={index} value={item.slug} label={item.title} />;
          })}
        </Tabs>
      </CmtCardHeader>
      <CmtCardContent>
      <div >
        {group &&
        (
          <CurvaS  list={props.dataList} auxi={props.dataHandle}/>
        )}
       {tables && 
       (
        <div> <RfmTable  list={props.dataList}/></div>
       )} 
       {pdfs && (
         <div>
             <iframe src={filex} type="application/pdf" width='100%' height='800px'  >
            </iframe>
           </div>
       )}
    </div>
      </CmtCardContent>
    </CmtCard>
  );
};

export default About;

About.prototype = {
  props: PropTypes.object.isRequired,
};
