import React , { useEffect , useState } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useApi from 'services/api'
import useFormats from 'services/Formats'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import { NavLink , useParams ,  useHistory} from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 650,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function DenseTable() {
  const classes = useStyles();
  const api = useApi();
  const formats = useFormats();
  const {id} = useParams();
  const  history = useHistory();
  const [ list,setList] = useState('');
 

  const HandleMessureAcess = async (idmedicao) => {
    history.push(`/messures/${idmedicao}`);
 }

  const getMedicoes = async () => {
    const result = await api.getMedicoes(id);
    console.table(result);
    if (result.error === '') {
      const rows = result.list.filter(dats => dats.Situacao === 'ABERTA').map((dats) => {
        return {
          Medicao: dats.id,
          DataMedicao: formats.dataPTBR(dats.DataMedicao),
          Situacao: dats.Situacao,
          Link: dats.Link,
          action: <>
                    <Button onClick={(e)=>{HandleMessureAcess(dats.id)}} startIcon={<SendIcon/>} variant="outlined" color="primary">MEDIR</Button>
                   </>
        }
      })
      console.log(rows)
      setList(rows);
     
    } else {
      alert(result.error);
    }



  }
  useEffect(() =>{
    getMedicoes();
    
  }, []);
  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="right">id</TableCell>
            <TableCell align="right">Data Medição</TableCell>
            <TableCell align="right">Situação</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list && 
          list.map(row => (
            <TableRow key={row.Medicao}>
              <TableCell component="th" scope="row" align="right"> {row.Medicao}</TableCell>
              <TableCell align="right">{row.DataMedicao}</TableCell>
              <TableCell align="right">{row.Situacao}</TableCell>
              <TableCell align="right">{row.action}</TableCell>
            </TableRow>
          ))} 
        </TableBody>
      </Table>
    </TableContainer>
  );
}