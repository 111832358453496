import React , { useEffect, useState , useRef } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CmtProgressBar from '@coremat/CmtProgressBar';
import { Box } from '@material-ui/core';

import MessageIcon from '@material-ui/icons/Message';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HoverInfoCard from '@jumbo/components/Common/HoverInfoCard';



import useFormats from 'services/Formats'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 150,
    maxWidth: 500,
    margin: '0 auto',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  iconRoot: {
    fontSize: 18,
    marginRight: 10,
  }
}));

export default function SimpleCard(props) {
  const classes = useStyles();
  const formats = useFormats();
 


  const bull = <span className={classes.bullet}>•</span>;
 
   
  return (
    <Card className={classes.root}>
      <CardContent>
    
 
        <Box> <MessageIcon className={classes.iconRoot} /> Semana Atual :  {props.ordem}</Box>
                        <Box> <CheckCircleIcon className={classes.iconRoot} /> CC: Caminho Critico : {props.DesvioCritico} Dia(s)  </Box> 
                        <Box>  <NotificationsIcon className={classes.iconRoot} /> Término Planejado: {formats.dataAtualFormatada(props.TerminoObra)}  </Box>
                        <Box>  <NotificationsIcon className={classes.iconRoot} />  Término Atualizado: {formats.dataAtualFormatada(props.TerminoCritico)}  </Box>
        
        <Typography variant="body2" component="p">
          
          <br />
      {props.Aderencia && 
      
      (
        <CmtProgressBar
        onlyContained={false}
        thickness={4}
        pointer={false}
        pointerSize={8}
        label="Aderencia ao CC"
        labelPos="top-left"
        hideValue={false}
        valuePos="right"
        value={props.RealPrevisto.toFixed(2)}
        total={props.Aderencia}
        renderValue={(value, total) => {
            return (value + '/' + total);
        }}
        emptyColor={'#e9ecef'} 
        containedColor="#1a90ff"
        />

      )}
       

        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small"><NotificationsIcon className={classes.iconRoot} />   Detalhes</Button>
      </CardActions>
    </Card>
  );
}