import React, { useEffect, useState } from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Box, Typography } from '@material-ui/core';
import useApi from '../../../services/api';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import SendIcon from '@material-ui/icons/Send';
import { Button } from '@material-ui/core';

import MessageIcon from '@material-ui/icons/Message';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HoverInfoCard from '@jumbo/components/Common/HoverInfoCard';

import ListAltIcon from '@material-ui/icons/ListAlt';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import AssessmentIcon from '@material-ui/icons/Assessment';
import StraightenIcon from '@material-ui/icons/Straighten';

import GamepadIcon from '@material-ui/icons/Gamepad';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountTree from '@material-ui/icons/AccountTree';
import BlurLinear from '@material-ui/icons/BlurLinear';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import DenseTable from './Components/table/MedicoesDense';
import DenseReports from './Components/table/ReportsDense';

const breadcrumbs = [
  { label: <IntlMessages id={'G+P'} />, link: '/' },
  { label: 'Manage', isActive: true },
];
const useStyles = makeStyles(theme => ({
  navLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    color: theme.palette.text.secondary,
  },
  iconRoot: {
    fontSize: 18,
    marginRight: 10,
  },
  titleSpace: {
    marginBottom: 20,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      marginTop: 15,
    },
  },
  carding: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)',
      '& $iconThumb': {
        width: 95,
        height: '100%',
        borderRadius: 0,
      },
      '& $hoverContent': {
        transform: 'translate(-10px, -50%)',
      },
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const Builders = () => {
  const classes = useStyles();
  const history = useHistory();

  const api = useApi();
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [nomeObra, setNomeObra] = useState('...');

  const [prazoMeta, setPrazoMeta] = useState('');
  const [prazoReprogramado, setPrazoReprogramado] = useState('');
  const [tendencia, setTendencia] = useState('');
  const [totalMeses, setTotalMeses] = useState('');
  const [mesesDecorridos, setMesesDecorridos] = useState('');
  const [desvioDoPrazo, setDesvioDoPrazo] = useState('');

  const [medicoesAbertas, setMedicoesAbertas] = useState('');
  const [medicoesFechadas, setMedicoesFechadas] = useState('');
  const [relatoriosGerados, setRelatoriosGerados] = useState('');
  const [relatoriosAprovados, setRelatoriosAprovados] = useState('');
  const [cronogramasImportados, setCronogramasImportados] = useState('');
  const [value, setValue] = React.useState(0);

  const getList = async () => {
    const result = await api.getMyObras();

    if (result.error === '') {
      setList(result.list);
    } else {
      alert(result.error);
    }
  };

  const checkdaObra = async () => {
    const retorno = await api.checkObraStatus(id);
    const dadosGerais = await api.getResumeObra(id);

    if (retorno.error === '') {
      localStorage.setItem('obraatual', retorno.obra);
      localStorage.setItem('idobra', id);
      if (retorno.ciclo > 0) {
        console.log('error');
      } else {
        history.push('/implantar/obra/' + id);
        // console.log(retorno.ciclo)
        // setisCheckCiclo(retorno.ciclo);
      }
      setPrazoMeta(dadosGerais.prazoMeta);
      setPrazoReprogramado(dadosGerais.prazoReprogramado);
      setTendencia(dadosGerais.tendencia);
      setTotalMeses(dadosGerais.totalMeses);
      setMesesDecorridos(dadosGerais.mesesDecorridos);
      setDesvioDoPrazo(dadosGerais.desvioDoPrazo);
      setMedicoesAbertas(dadosGerais.medicoesAbertas);
      setMedicoesFechadas(dadosGerais.medicoesFechadas);
      setRelatoriosGerados(dadosGerais.relatoriosGerados);
      setRelatoriosAprovados(dadosGerais.relatoriosAprovados);
      setCronogramasImportados(dadosGerais.cronogramasImportados);
      setNomeObra(retorno.obra);
      setOpen(false);
    } else {
      alert(retorno.error);
    }
  };

  useEffect(() => {
    getList();
    checkdaObra();
  }, []);

  // function MouseOver(event) {
  //   event.target.style.background = '#808080';
  // }
  // function MouseOut(event){
  //   event.target.style.background="";
  // }
  const handleClose = () => {
    setOpen(false);
  };
  // const handleToggle = () => {
  //   setOpen(!open);
  // };
  const navtoallMess = () => {
    history.push('/medicoes/' + id);
  };
  const navToReports = () => {
    history.push('/relatorios/' + id);
  };
  function navtoFpla() {
    history.push('/fpla/obra/' + id);
  }
  return (
    <PageContainer heading={'Obra : ' + nomeObra} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12} md={12} xl={12} lg={12}>
          <Divider />
          <div style={{ marginTop: 24 }}></div>
          <GridContainer>
            <Grid item xs={12} md={5} xl={4}>
              <NavLink to={'/ciclos/' + id}>
                <CmtCard className={classes.carding}>
                  <CmtCardHeader
                    title={
                      <>
                        <DateRangeIcon /> Ciclos da Obra
                      </>
                    }
                    titleProps={{
                      variant: 'h2',
                      component: 'div',
                    }}
                  />
                  <CmtCardContent>
                    <GridContainer>
                      <Grid item xs={12} sm={8} md={12} xl={8} lg={8}>
                        <div>
                          <Typography component="div" variant="h5" className={classes.titleSpace}>
                            Dados
                          </Typography>
                          <Box>
                            <MessageIcon className={classes.iconRoot} /> Prazo Meta | {prazoMeta}
                          </Box>
                          <Box>
                            <MailOutlineIcon className={classes.iconRoot} /> Replanejado |{prazoReprogramado}
                          </Box>
                          <Box>
                            <CheckCircleIcon className={classes.iconRoot} /> Tendencia | {tendencia} M
                          </Box>
                          <Box>
                            <NotificationsIcon className={classes.iconRoot} /> Total Meses | {totalMeses}
                          </Box>
                          <Box>
                            <NotificationsIcon className={classes.iconRoot} /> Meses Decorridos | {mesesDecorridos}
                          </Box>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4} md={12} xl={4} lg={8} className={classes.order3}>
                        <Typography component="div" variant="h5" className={classes.titleSpace}>
                          Desvio do Prazo
                        </Typography>
                        <Box display="flex" alignItems="flex-end" mb={5}>
                          <Box
                            component="span"
                            fontSize={{ xs: 36, md: 40, xl: 48 }}
                            fontWeight="fontWeightBold"
                            color="common.dark"
                            lineHeight="1">
                            {desvioDoPrazo}
                          </Box>
                          <Typography component="span" variant="subtitle1">
                            Dias
                          </Typography>
                        </Box>
                      </Grid>
                    </GridContainer>
                  </CmtCardContent>
                </CmtCard>
              </NavLink>
              <Divider />
              <br />
              <Grid item xs={12} md={12} sm={12} xl={12}>
                <HoverInfoCard
                  backgroundColor="#000000"
                  icon={<ListAltIcon style={{ color: '#ffffff' }} />}
                  title={'Pavimentos'}
                  linkOnArrow={'/anexos/' + id}
                  subTitle={
                    <>
                      <Box>
                        {' '}
                        <MessageIcon className={classes.iconRoot} /> Importados | {cronogramasImportados}{' '}
                      </Box>
                    </>
                  }
                />
              </Grid>
              <Divider />
              <br />
              <Grid item xs={12} md={12} sm={12} xl={12}>
                <HoverInfoCard
                  backgroundColor="#6EC1E4"
                  icon={<ListAltIcon style={{ color: '#ffffff' }} />}
                  title={'Cronogramas'}
                  linkOnArrow={'/cronogramas/' + id}
                  subTitle={
                    <>
                      <Box>
                        {' '}
                        <MessageIcon className={classes.iconRoot} /> Importados | {cronogramasImportados}{' '}
                      </Box>
                    </>
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={7} xl={8}>
              {/* <NavLink to={'/ciclos/'+id}> */}
              <CmtCard className={classes.carding} style={{ cursor: 'auto' }}>
                <CmtCardHeader
                  title={
                    <>
                      <DashboardIcon /> Relatorios
                    </>
                  }
                  titleProps={{
                    variant: 'h2',
                    component: 'div',
                  }}
                  actions={''}
                  actionHandleIcon={<SendIcon fontSize="default" />}>
                  <Button color="primary" onClick={e => navToReports()} startIcon={<SendIcon />} variant="outlined">
                    {' '}
                    Todos
                  </Button>
                </CmtCardHeader>
                <CmtCardContent>
                  <GridContainer>
                    <DenseReports />
                  </GridContainer>
                </CmtCardContent>
              </CmtCard>

              <div>
                <br />
              </div>
              <CmtCard className={classes.carding} style={{ cursor: 'auto' }}>
                <CmtCardHeader
                  title={
                    <>
                      <StraightenIcon /> Mediçoes Abertas
                    </>
                  }
                  titleProps={{
                    variant: 'h2',
                    component: 'div',
                  }}
                  actions={''}
                  actionHandleIcon={<SendIcon fontSize="default" />}>
                  <Button color="primary" onClick={e => navtoallMess()} startIcon={<SendIcon />} variant="outlined">
                    {' '}
                    Todas
                  </Button>
                </CmtCardHeader>
                <CmtCardContent>
                  <GridContainer>
                    <DenseTable />
                  </GridContainer>
                </CmtCardContent>
              </CmtCard>
              {/* </NavLink> */}
            </Grid>

            <Grid item xs={12} xl={12} md={12}>
              <GridContainer>
                <Grid item xs={12} sm={12} xl={12}>
                  <HoverInfoCard
                    backgroundColor="#FF8C00"
                    icon={<TrendingUpIcon style={{ color: '#ffffff' }} />}
                    title={'Soluções'}
                    linkOnArrow={false}
                    subTitle={
                      <>
                        <Box>
                          <BottomNavigation
                            value={value}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            showLabels
                            className={classes.root}>
                            <BottomNavigationAction label="Gantt" icon={<AccountTree />} />
                            <BottomNavigationAction label="DASHBOARD" icon={<DashboardIcon />} />
                            <BottomNavigationAction label="Linha de Balanço" icon={<BlurLinear />} />
                            <BottomNavigationAction
                              onClick={e => {
                                navtoFpla();
                              }}
                              label="FPLA"
                              icon={<ScatterPlotIcon />}
                            />
                          </BottomNavigation>
                        </Box>
                      </>
                    }
                  />
                </Grid>
              </GridContainer>
            </Grid>
          </GridContainer>
        </Grid>
      </GridContainer>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </PageContainer>
  );
};

export default Builders;
