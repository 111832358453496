import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DataSheet from 'react-datasheet';

import 'react-datasheet/lib/react-datasheet.css';

import useApi from 'services/api';
import useFormats from 'services/api';

export default () => {
  const history = useHistory();
  const { id } = useParams();
  const [visible, setVisible] = useState(!visible);

  const [statusdata, setstatusdata] = useState('');
  const [opcao, setopcao] = useState('');

  const api = useApi();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalAtrob, setModalAtrob] = useState(false);

  const [modalLoading, setModalLoading] = useState(false);
  const [ModalFpla, setModalFpla] = useState(false);

  const [dast, setDast] = useState([]);
  const [isagrupamento, setIsAgrupamento] = useState('');
  const [isvinculo, setisVinculo] = useState('');
  const [radiovalue, setRadioValue] = useState(1);

  let main;
  // serve para quando carregar a pagina
  useEffect(() => {
    setLoading(true);
    // checkdaObra();
    CallListResumeTasks();
    getList();
    getListOfCronogramas();
    getListOfRelatorios();
    getAgrup();
    getdatalist();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setModalFpla(false);
    setModalAtrob(false);
  };

  const [active, setActive] = useState(1);

  const fields = ['DataHash', 'Quem', 'Situacao', 'created_at', 'actions'];
  const getList = async () => {
    setLoading(true);
    const result = await api.getCiclos(id);
    setLoading(false);
    if (result.error === '') {
      setList(result.list);
    } else {
      alert(result.error);
    }
  };

  const getdatalist = async () => {
    
    setLoading(true);

    const result = await api.getManageData(id);
    if (result.error === '') {
      loadgrid(result.list);
      console.log(result.list);
      setLoading(false);
    } else {
      alert(result.error);
    }
  };
  const finalizarloading = async () => {
    setVisible(!visible);
  };

  const [listAgrupamento, setListAgrupamento] = useState('');
  const [listVinculos, setListVinculos] = useState('');

  const getAgrup = async () => {
    setLoading(true);
    const result = await api.getAgrupamento(id);
    if (result.error === '') {
      setListAgrupamento(result.agrupamento);
      setListVinculos(result.vinculos);
    } else {
      alert(result.error);
    }
  };
  const [listCronogramas, setListCronogramas] = useState('');

  const getListOfCronogramas = async () => {
    setLoading(true);
    const result = await api.getCronogramas(id);
    setLoading(false);
    if (result.error === '') {
      setListCronogramas(result.list);
    } else {
      alert(result.error);
    }
  };

  const camposRelatorios = ['id', 'SiglaRelatorio', 'QuemCadastrou', 'DataCadastro', 'Infos', 'actions'];

  const [listRelatorios, setListRelatorios] = useState('');

  const getListOfRelatorios = async () => {
    setLoading(true);
    const result = await api.getRelatorios(id);
    setLoading(false);
    if (result.error === '') {
      setListRelatorios(result.list);
    } else {
      alert(result.error);
    }
    finalizarloading();
  };

  const [isBegin, setIsBegin] = useState(false);

  const [listBeginTask, setListBeginTask] = useState('');
  const [listBeginTaskx, setListBeginTaskx] = useState('');

  const [modalFileField, setModalFileField] = useState('');
  const ExportToExcel = async () => {};
  const CallListResumeTasks = async () => {
    const result = await api.getResumeTasks(id);
    if (result.error === '') {
      setListBeginTaskx(result.list);
      let atrib = '';
      atrib = result.list.map(tasks => {
        return <option value={tasks.UniqueID}>{tasks.Name}</option>;
      });
      setListBeginTask(atrib);
    } else {
      alert(result.error);
    }
  };

  const [tables, settables] = useState('');

  const HandleRevertButton = e => {
    console.log('teste');
  };

  const [value, onChange] = useState(new Date());

  const onClickHandler = e => {
    const hiddenElement = e.currentTarget.nextSibling;
    hiddenElement.className.indexOf('collapse show') > -1
      ? hiddenElement.classList.remove('show')
      : hiddenElement.classList.add('show');
  };

  const HandlerAgrupamento = e => {
    setModalFpla(true);
    setIsAgrupamento(true);
    setisVinculo(false);

    getAgrup();
  };
  const handlerVinculo = e => {
    setModalFpla(true);
    setIsAgrupamento(false);
    setisVinculo(true);

    getAgrup();
  };
  const modalSaveFile = async () => {};

  const [news, setnews] = useState('');

  const [datarfm, setdatarfm] = useState('');

  const [grid, setgrid] = useState([]);

  const loadgrid = async list => {
    const ofSelected = list.map(obj => {
      let rObj = [];
      rObj = [
        { value: obj.Periodo, readOnly: true },
        { value: obj.Previsto },
        { value: obj.Executado },
        { value: obj.Replanejado, readOnly: true },
        { value: obj.PrevistoAC, readOnly: true },
        { value: obj.ExecutadoAC, readOnly: true },
        { value: obj.ReplanejadoAC, readOnly: true },
        { value: obj.NotaPlanejamento },
        { value: obj.NotaArremate },
      ];

      return rObj;
    });

    const grid = [
      [{ readOnly: true, colSpan: 8, value: 'Tabela de Edição do RFM', width: '400px' }],

      [
        { value: 'Periodo', readOnly: true }, // 0
        { value: 'Previsto', readOnly: true }, // 1
        { value: 'Executado', readOnly: true }, // 2
        { value: 'Replanejado', readOnly: true }, // 3
        { value: 'PrevistoAC', readOnly: true }, // 4
        { value: 'ExecutadoAC', readOnly: true }, // 5
        { value: 'ReplanejadoAC', readOnly: true }, // 6
        { value: 'NotaPlanejamento', readOnly: true }, // 8
        { value: 'NotaArremate', readOnly: true }, // 9
        
      ],
    ];

    const newlist = grid.concat(ofSelected);
    console.log(newlist);
    setnews(newlist);
    setdatarfm();
    setgrid(newlist);
  };
  const handleSaveRfm = async data => {
    let result = await api.setManageData(data);
    if (result.error == '') {
      await getdatalist();
    }
  };

  const subfun = () => {
    let x = '';
    if (isBegin == false) {
      setIsBegin(true);
      console.log(isBegin);
    }
    if (isBegin == true) {
      setIsBegin(false);
      console.log(isBegin);
    }
  };
  const valueRenderer = cell => cell.value;
  const onCellsChanged = async changes => {
    console.log(changes);
    const nome = grid;
    changes.forEach(({ cell, row, col, value }) => {
      if (col == 1) {
        let data = {
          IdObra: id,
          Periodo: nome[row][0].value,
          field: 'Previsto',
          value: value,
        };
        handleSaveRfm(data);
        // console.log('Previsto');
        // console.log(nome[row][0].value)
        // setPeriodo(changes[row][1])
      }
      if (col == 2) {
        console.log('Executado');

        let data = {
          IdObra: id,
          Periodo: nome[row][0].value,
          field: 'Executado',
          value: value,
        };
        handleSaveRfm(data);
      }
      if (col == 7) {
        let data = {
          IdObra: id,
          Periodo: nome[row][0].value,
          field: 'NotaPlanejamento',
          value: value,
        };
        handleSaveRfm(data);

      }
      if (col == 8) {
        let data = {
          IdObra: id,
          Periodo: nome[row][0].value,
          field: 'NotaArremate',
          value: value,
        };
        handleSaveRfm(data);

      }
      nome[row][col].value = value;
    });
    setgrid(nome);
  };
  const onContextMenu = (e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null);

  return (
    <>
      {grid && (
        <>
          <button color="info" onClick={() => getdatalist()}>
            Atualizar Dados
          </button>
          <button color="secondary" onClick={() => setModalAtrob(true)}>
            {' '}
            Refazer Curva META
          </button>

          <DataSheet
            data={grid}
            valueRenderer={valueRenderer}
            onContextMenu={onContextMenu}
            onCellsChanged={onCellsChanged}
          />
        </>
      )}
    </>
  );
};
