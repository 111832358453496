import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtMediaObject from '@coremat/CmtMediaObject';
import CmtImage from '@coremat/CmtImage';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtList from '@coremat/CmtList';


import LocationOnIcon from '@material-ui/icons/LocationOn';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import AssessmentIcon from '@material-ui/icons/Assessment';
import TimelineIcon from '@material-ui/icons/Timeline';
import StraightenIcon from '@material-ui/icons/Straighten';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GamepadIcon from '@material-ui/icons/Gamepad';
import PhoneIcon from '@material-ui/icons/Phone';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import TableCiclos from './table/ciclos'
import Cronogramas from './table/cronogramas';
import Generics from './table/Generics';
import WeeklySales from './dashboard/WeeklySales'; 
import YearlySalesReport from './dashboard/YearlySalesReport';
import Grid from '@material-ui/core/Grid';
import GridContainer from '../../../../@jumbo/components/GridContainer';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  subTitleRoot: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: 0,
    marginTop: 6,
  },
  
  badgeRoot: {
    padding: '5px 12px',
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    borderRadius: 5,
    fontSize: 14,
    display: 'inline-block',
    marginBottom: 16,
  },
  linkRoot: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      display: 'block',
    },
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));
 
const Events = ({ 
    events,
    cronogram,
    colunas,
    relatorios,
    relatoriosHeader,
    medicoes,
    headerMedicoes,
    mediaAtual }) => {
 
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
 
  return (
    <>
    <CmtCard>
           <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example">
          <Tab label="Dashboard" icon={<DashboardIcon />} {...a11yProps(0)} />
          <Tab label="Ciclos" icon={<DateRangeIcon />} {...a11yProps(1)} />
          <Tab label="Relatorios" icon={<AssessmentIcon />} {...a11yProps(2)} />
          <Tab label="Medições" icon={<StraightenIcon />} {...a11yProps(3)} />
          <Tab label="Cronogramas" icon={<ListAltIcon />} {...a11yProps(4)} />
          <Tab label="SOLUÇÕES" icon={<TrendingUpIcon />} {...a11yProps(5)} />
          <Tab label="Implantação/Ajustes" icon={<GamepadIcon />} {...a11yProps(6)} />
          {/* <Tab label="Contratações" icon={<ThumbDown />} {...a11yProps(6)} />

          <Tab label="Gantt/Fluxo" icon={<ThumbUp />} {...a11yProps(7)} />
          <Tab label="Timeline" icon={< ThumbUp />} {...a11yProps(8)} /> */}

        </Tabs>
      </AppBar>
      <CmtCardContent>
      <Box className={classes.root}>
        {/* Dashboard */}
      <TabPanel value={value} index={0}>
       {/*  Colocar resumo da dashbaord aqui */}
      </TabPanel>
       {/* Ciclos */}
      <TabPanel value={value} index={1}>
      <TableCiclos/>
      </TabPanel>
      {/* Relatorios */}
      <TabPanel value={value} index={2}>
      <Generics
         cronogramaData={relatorios}
         colun={relatoriosHeader}/>
      
      
      </TabPanel>
      {/* Medições */}

      <TabPanel value={value} index={3}>
      <Generics
         cronogramaData={medicoes}
        colun={headerMedicoes}/>
      </TabPanel>
      {/* Cronogramas */}

      <TabPanel value={value} index={4}>
      <Generics
          cronogramaData={cronogram}
          colun={colunas}/>
      </TabPanel>
      {/* Soluções ['Contratações', 'FPLA', 'Gantt', 'Medição'] */}
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      {/* Implantação / Ajustes  */}
      <TabPanel value={value} index={6}>
   
      </TabPanel>
 
    </Box>
      </CmtCardContent>
    </CmtCard>

    {/* Colocar Dashboard Aui dentro */}
        <TabPanel value={value} index={0}>
        <GridContainer>
            <Grid item xs={4} style={{height: '180px'}}>
                <YearlySalesReport
                 mediaMensal={''}/>
            </Grid>
            
            <Grid item xs={8}>
              <WeeklySales/>
            </Grid>
        </GridContainer>
        </TabPanel>
      </>
  );
};

export default Events;

Events.prototype = {
  events: PropTypes.array.isRequired,
};
