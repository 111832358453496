import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import useApi from 'services/api';

import { useHistory, useParams } from 'react-router-dom';

import GenericReportTable from '../../Components/reports/Generics';
import WeekCard from '../../Components/weekcard';
import ReportCard from '../../Components/ReportCard';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GridOnIcon from '@material-ui/icons/GridOn';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// import Generics from '../../Components/table/Generics';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import AndamentoFisicoSemanal from '../graficos/AndamentoFisicoSemanal';
import AndamentoFisicoSemanalAC from '../graficos/AndamentoFisicoSemanalAC';
import AtrasoPorEmpreiteiro from '../graficos/AtrasoPorEmpreiteiro';
import AtrasoPorEmpreiteiroPond from '../graficos/AtrasoPorEmpreiteiroPond';
import AtrasoPorEmpreiteiroCritico from '../graficos/AtrasoPorEmpreiteiroCritico';
import CriticalCard from '../../Components/criticalcard';
import useFormats from 'services/Formats';
import { ArrowBack } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  navLink: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    color: theme.palette.text.secondary,
  },
  iconRoot: {
    fontSize: 18,
    marginRight: 10,
  },
  titleSpace: {
    marginBottom: 20,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('sm')]: {
      marginTop: 15,
    },
  },
  carding: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)',
      '& $iconThumb': {
        width: 95,
        height: '100%',
        borderRadius: 0,
      },
      '& $hoverContent': {
        transform: 'translate(-10px, -50%)',
      },
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps2(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const Relatorios = () => {
  const history = useHistory();

  const classes = useStyles();
  const formats = useFormats();

  const api = useApi();
  const { id } = useParams();
  const { relatorio } = useParams();

  const [open, setOpen] = React.useState(false);
  const [nomeObra, setNomeObra] = useState('...');
  const [backdropActivate, setBackdropActivate] = useState(false);

  const checkdaObra = async () => {
    const retorno = await api.checkObraStatus(id);

    if (retorno.error === '') {
      setNomeObra(retorno.obra);
      setOpen(false);
    } else {
      alert(retorno.error);
    }
  };

  useEffect(() => {
    getListOfRelatorios();
    checkdaObra();
    getListOfCards();
  }, []);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleBackdrop = () => {
    setBackdropActivate(!backdropActivate);
  };
  //####################################################################
  //#################  Constantes e funções da exibição de relatorios###
  //####################################################################

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  const descriptionElementRef = React.useRef(null);

  const getRelatorioMedicao = async () => {
    handleToggle();
  };
  //
  const getReportDetail = async (sigla, idrelatorio, semana) => {
    handleToggle();
    console.log(sigla, idrelatorio, semana);

    switch (sigla) {
      case 'RFM':
        break;
      case 'RFS':
        const rfs = await getRfsDetail(idrelatorio, semana);

        break;
      case 'AFS':
        break;
      case 'MFS':
        break;
      case 'DBM':
        break;
      case 'DBM':
        break;
      default:
        alert('Relatorio não suportado');
        break;
    }
  };

  // const [listReport, setListReport] = useState([]);
  // const [listHeaderReport, setListHeaderReport] = useState('');
  // const [reportComponent,setReportComponent] = useState();

  const getRfsDetail = async (idreport, semana) => {
    const result = await api.getRfs(idreport, semana);

    if (result.error === '') {
      const rows = result.list.map(dats => {
        return {
          IDTask: dats.IDTask,
          Servico: dats.Servico,
          Local: dats.Local,
          Empreiteiro: dats.Empreiteiro,
          Startz: dats.Startz,
          Finish: dats.Finish,
          ActualStart: dats.ActualStart,
          ActualFinish: dats.ActualFinish,
          PrevistoSemana1: dats.PrevistoSemana1,
          PrevistoSemana2: dats.PrevistoSemana2,
          PrevistoSemana3: dats.PrevistoSemana3,
          PrevistoSemana4: dats.PrevistoSemana4,
          PrevistoSemana5: dats.PrevistoSemana5,
          RealSemana1: dats.RealSemana1,
          RealSemana2: dats.RealSemana2,
          RealSemana3: dats.RealSemana3,
          RealSemana4: dats.RealSemana4,
          RealSemana5: dats.RealSemana5,
        };
      });
      const headerAfs = [
        'IDTask',
        'Servico',
        'Local',
        'Empreiteiro',
        'Startz',
        'Finish',
        'ActualStart',
        'ActualFinish',
        'PrevistoSemana1',
        'PrevistoSemana2',
        'PrevistoSemana3',
        'PrevistoSemana4',
        'PrevistoSemana5',
        'RealSemana1',
        'RealSemana2',
        'RealSemana3',
        'RealSemana4',
        'RealSemana5',
      ];

      // setListReport(rows);
      // setListHeaderReport(headerAfs);

      const tabelafs = <GenericReportTable header={headerAfs} dados={rows} />;
      // setReportComponent(tabelafs)
      setOpen(true);
    } else {
      alert(result.error);
    }
    let datas = [];

    if (result.error === '') {
      const grafico1 = result.graficoAderenciaSemanal.Semana.map(dados => {
        datas.push(dados);
      });
    }
    console.log(datas);
  };

  //####################################################################
  //################# breadcrumps ######################################
  //####################################################################
  const breadcrumbs = [
    { label: 'G+P', link: '/' },
    { label: nomeObra, link: '/obra/' + id },
    { label: 'Relatorios', isActive: true },
  ];
  //
  //############ Consulta aos Cabeçalhos ##############
  //##################################################
  const [listCards, setListCards] = useState([]);
  const [letcount, setletCount] = useState(4);
  const [AndamentoFisico, setAndamentoFisico] = useState([]);
  const [atrasoporempreiteiro, setatrasoporempreiteiro] = useState([]);
  const [graficoAtrasoPorEmpreiteiroPonderado, setatrasoporempreiteiropond] = useState([]);
  const [graficoAtrasoCC, setgraficoAtrasoCC] = useState([]);
  const [dadosCritical, setDadosCritical] = useState([]);
  const [dadosAderenciaCritical, setDadosAderenciaCritical] = useState([]);
  const getListOfCards = async () => {
    const result = await api.getDyncamicRfs(relatorio);
    if (result.error === '') {
      setListCards(result.Dados);
      // console.log(result);
      // console.log(result.graficosAndamentoFisicoSemanaleAC);
      console.log(result);
      console.table(result.Dados);

      setatrasoporempreiteiro(result.graficoAtrasoPorEmpreiteiro);
      setatrasoporempreiteiropond(result.graficoAtrasoPorEmpreiteiroPonderado);
      setgraficoAtrasoCC(result.graficoAtrasoPonderadoDoCC);
      setAndamentoFisico(result.graficosAndamentoFisicoSemanaleAC);
      setDadosCritical(result.graficoDadosDesvioCC);
      setDadosAderenciaCritical(result.graficoAderenciaAoCC);
      console.log(result.graficoDadosDesvioCC);
      if (result.Dados.length == 5) {
        setletCount(2);
      }
    }
  };

  //####################################################################
  //################# funções de listagem do Relatorios ################
  //####################################################################
  const [listRelatorios, setListRelatorios] = useState('');
  const [listColumnRelatorios, setListColumnRelatorios] = useState('');
  const [validRpt, setValidRpt] = useState('');

  const getListOfRelatorios = async () => {
    const result = await api.getRelatorios(id);

    if (result.error === '') {
      const rows = result.list.map(dats => {
        return {
          SiglaRelatorio: dats.SiglaRelatorio,
          Infos: dats.Infos,
          DataInfo: dats.DataInfo,
          Primaryc: dats.Primaryc,
          action: (
            <Button
              onClick={e => {
                getReportDetail(dats.SiglaRelatorio, dats.id, dats.SemanaInfo);
              }}>
              Teste
            </Button>
          ),
        };
      });

      setListRelatorios(rows);
      setListColumnRelatorios(['SiglaRelatorio', 'Infos', 'DataInfo', 'Primaryc', 'action']);
      setValidRpt(true);
    } else {
      alert(result.error);
    }
  };

  //####################################################################
  //#################  Constantes e funções da exibição de relatorios###
  //####################################################################
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('xl');

  const [scroll, setScroll] = useState('paper');
  const radioGroupRef = useRef(null);
  const handleClose = () => {
    setOpen(false);
    // setReportComponent(false);
  };

  const handleClickOpen = idrelatorio => () => {
    setOpen(true);
  };
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [value2, setValue2] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };
  const handleChangeIndex2 = index => {
    setValue2(index);
  };
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [filex, setFilexs] = useState('');
  const [resx, setResx] = useState('');
  const [fileto, setfileto] = useState('');

  const handleDownload = idrelatorio => () => {
    handleBackdrop();
    // window.open('http://localhost:8000/api/files/getmedicao/' + idrelatorio);
    let token = localStorage.getItem('token');
    // idrelatorio  = 655 ;
    // Change this to use your HTTP client
    fetch('https://main.gmaisp.tech/api/files/getmedicao/' + idrelatorio, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${token}`,
      },
    }) // FETCH BLOB FROM IT
      .then(response => response.blob())
      .then(blob => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        setBackdropActivate(false);
        var _url = window.URL.createObjectURL(blob);
        setFilexs(_url);
        setidrelatorios(idrelatorio);

        getRelatorioMedicao();
        setfileto(new File([blob], 'AFS-teste.pdf'));
        // window.open(_url, "_blank").focus(); // window.open + focus
      })
      .catch(err => {
        setBackdropActivate(false);

        console.log(err);
      });
  };
  const handleDownloadRfs = idrelatorio => () => {
    handleBackdrop();
    // window.open('http://localhost:8000/api/files/getmedicao/' + idrelatorio);
    let token = localStorage.getItem('token');
    // idrelatorio  = 655 ;
    // Change this to use your HTTP client
    fetch('https://main.gmaisp.tech/api/files/getreport/' + idrelatorio, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${token}`,
      },
    }) // FETCH BLOB FROM IT
      .then(response => response.blob())
      .then(blob => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        setBackdropActivate(false);
        var _url = window.URL.createObjectURL(blob);
        setFilexs(_url);
        setidrelatorios(idrelatorio);

        getRelatorioMedicao();
        setfileto(new File([blob], 'AFS-teste.pdf'));
        // window.open(_url, "_blank").focus(); // window.open + focus
      })
      .catch(err => {
        setBackdropActivate(false);

        console.log(err);
      });
  };
  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = 'abc.pdf';
    downloadLink.href = pdf;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  const handleToKbn = async () => {
    handleBackdrop();

    console.log('aqui');
    const result = await api.getKbnBase64(relatorio);
    if (result.error == '') {
      console.log(result.file);
      // var _url = window.URL.createObjectURL(result.file);
      setFilexs(result.file);
      setBackdropActivate(false);

      getRelatorioMedicao();
      setfileto(new File([result.file], 'AFS-teste.pdf'));
    }
    return;
  };

  const handleToOS = async () => {
    handleBackdrop();

    console.log('aqui');
    const result = await api.getOsBase64(relatorio);
    if (result.error == '') {
      console.log(result.file);
      // var _url = window.URL.createObjectURL(result.file);
      setFilexs(result.file);
      setBackdropActivate(false);

      getRelatorioMedicao();
      setfileto(new File([result.file], 'AFS-teste.pdf'));
    }
    return;
  };
  const handleKbn = idrelatorio => () => {
    // window.open('http://localhost:8000/api/files/getmedicao/' + idrelatorio);
    let token = localStorage.getItem('token');
    // idrelatorio  = 655 ;
    // Change this to use your HTTP client
    fetch('http://localhost:8000/kanban/665', {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${token}`,
      },
    }) // FETCH BLOB FROM IT
      .then(response => response.blob())
      .then(blob => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        var _url = window.URL.createObjectURL(blob);
        setFilexs(_url);
        setidrelatorios(idrelatorio);
        getRelatorioMedicao();
        setfileto(new File([blob], 'AFS-teste.pdf'));

        // window.open(_url, "_blank").focus(); // window.open + focus
      })
      .catch(err => {
        console.log(err);
      });
  };

  const uploadFilex = async () => {
    console.log('--START HERE');
    let formData = new FormData();
    formData.append('obra', 453);
    formData.append('relatorios[]', fileto, 'RFS-tesdte.pdf');

    let req = await fetch(`https://gmaisp.com.br/app/web/integracao/timeline/relatorios`, {
      method: 'POST',
      // mode: 'no-cors',
      headers: {
        Authorization:
          'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2Mzg4MTU4NDcsInVpZCI6OTg5LCJub21lIjoiR01BSVNQX0RFVkVMT1BNRU5UIiwiZW1haWwiOiJtYXRldXNAZ21haXNwLmNvbS5iciJ9.K6RD5Gn6PSBYNIsCRpWtVltll5ROw7yraVPnQ4WjfZk=',
        'Content-Type': 'multipart/form-data',
      },
      body: formData,
    });
    let json = await req.json();
    alert(json);
  };

  const [idrelatorios, setidrelatorios] = useState('');

  const handlePostFileTimelinera = idrelatorio => () => {
    let token = localStorage.getItem('token');

    // Change this to use your HTTP clzient
    fetch('https://main.gmaisp.tech/api/files/getmedicao/' + idrelatorio, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${token}`,
      },
    }) // FETCH BLOB FROM IT
      .then(response => response.blob())
      .then(blob => {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        let file = new File([blob], 'AFS-teste.pdf');
        let data = {
          obra: 453,
          relatorios: file,
        };
        console.log(data);
        let apo = uploadFilexa(data);
        // putfile(file)
      })
      .catch(err => {
        console.log(err);
      });
  };

  const uploadFilexa = async data => {
    console.log('--START HERE');
    const formData = new FormData();
    formData.append('relatorios[]', data.relatorios, 'AFS_tesdte.pdf');
    formData.append('obra', 453);
    fetch('https://gmaisp.com.br/app/web/integracao/timeline/relatorios', {
      method: 'POST',
      // mode: 'cors',
      headers: {
        Authorization:
          'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2Mzg4MTU4NDcsInVpZCI6OTg5LCJub21lIjoiR01BSVNQX0RFVkVMT1BNRU5UIiwiZW1haWwiOiJtYXRldXNAZ21haXNwLmNvbS5iciJ9.K6RD5Gn6PSBYNIsCRpWtVltll5ROw7yraVPnQ4WjfZk=',
        // 'Content-Type' : "multipart/form-data"
      },
      body: formData,
    })
      .then(response => {
        console.log(response);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = anchor => (
    <Box
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>

      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <React.Fragment key={'right'}>
      <PageContainer
        heading={
          <>
            <Button
              color="primary"
              onClick={e => {
                history.push(`/relatorios/${id}`);
              }}
              startIcon={<ArrowBack />}
              variant="outlined"></Button>

            {' OBRA : ' + nomeObra}
          </>
        }
        breadcrumbs={breadcrumbs}>
        <GridContainer>
          {listCards.map((dados, index) => {
            return (
              <Grid item lg={2}>
                <WeekCard
                  DataMedicao={dados.DataMedicao}
                  Situacao={dados.Situacao}
                  Previsto={dados.Previsto}
                  PrevistoMes={dados.PrevistoNoMes}
                  ExecutadoAC={dados.ExecutadoAC}
                  Executado={dados.Executado}
                  Aderencia={dados.Aderencia}
                  ordem={index + 1}
                />
                <Button size="small" onClick={handleDownload(dados.id)}>
                  <PictureAsPdfIcon className={classes.iconRoot} />{' '}
                </Button>
                <Button size="small">
                  <GridOnIcon className={classes.iconRoot} />{' '}
                </Button>
                <Button size="small">
                  <ZoomOutMapIcon className={classes.iconRoot} />{' '}
                </Button>
              </Grid>
            );
          })}

          <Grid item lg={2} sm={letcount} md={letcount} xl={letcount} xs={letcount}>
            <List>
              <GridContainer>
                <Grid Item style={{ paddingLeft: '15px' }}>
                  <ul style={{ cursor: 'pointer' }}>
                    {/* <li onClick={e => handleToKbn()}>RFM</li> */}
                    <li  ><a href={`https://main.gmaisp.tech/dynamics-export/rfs/${relatorio}`} > MEDICAO EXCEL</a></li>
                    <li ><Button variant='outlined' onClick={e => handleToKbn()}>Kanban</Button></li>
                    <li ><Button variant='outlined' onClick={e => handleToOS()}>OS</Button></li>
                    <li  ><Button variant='outlined' onClick={handleDownloadRfs(relatorio)}>Inicial</Button></li>
                    {listCards.map((dados, index) => {
                      return <li > <Button variant='outlined' onClick={handleDownload(dados.id)}>Medição : {formats.dataPTBR(dados.DataMedicao)}</Button></li>;
                    })}
                  </ul>
                </Grid>
              </GridContainer>
            </List>
            {/* <Button onClick={toggleDrawer('right', true)}>Relatorios:</Button> */}
          </Grid>

          <Grid item xs={12}>
            <Divider />
            <div style={{ marginTop: 24 }}></div>
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item lg={6} xl={4} md={6} sm={12}>
            <h3>Andamento fisico Semanal </h3>
            <br></br>
            <Divider />
            <div style={{ marginTop: 24 }}></div>
            <Box className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example">
                  <Tab label=" Semanal" {...a11yProps(0)} />
                  <Tab label=" Acumulado" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                  {AndamentoFisico && <AndamentoFisicoSemanal AllData={AndamentoFisico} />}
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  {AndamentoFisico && <AndamentoFisicoSemanalAC AllData={AndamentoFisico} />}
                </TabPanel>
              </SwipeableViews>
            </Box>
          </Grid>
          <Grid item lg={6} xl={4} md={6} sm={12}>
            <h3>Atraso por empreiteiro </h3>
            <br></br>
            <Divider />
            <div style={{ marginTop: 24 }}></div>
            <Box className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value2}
                  onChange={handleChange2}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example">
                  <Tab label=" Total" {...a11yProps2(0)} />
                  <Tab label=" Ponderado" {...a11yProps2(1)} />
                  <Tab label=" Critico" {...a11yProps2(2)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value2}
                onChangeIndex={handleChangeIndex2}>
                <TabPanel value={value2} index={0} dir={theme.direction}>
                  {atrasoporempreiteiro && <AtrasoPorEmpreiteiro AllData={atrasoporempreiteiro} />}
                </TabPanel>
                <TabPanel value={value2} index={1} dir={theme.direction}>
                  <AtrasoPorEmpreiteiroPond AllData={graficoAtrasoPorEmpreiteiroPonderado} />
                </TabPanel>
                <TabPanel value={value2} index={2} dir={theme.direction}>
                  {graficoAtrasoCC && <AtrasoPorEmpreiteiroCritico AllData={graficoAtrasoCC} />}
                </TabPanel>
              </SwipeableViews>
            </Box>
          </Grid>
          <Grid item lg={12} xl={4} md={12} sm={12}>
            <h3>Desvio do Caminho Critico </h3>
            <br></br>
            <Divider />
            <div style={{ marginTop: 24 }}></div>
            <CriticalCard
              DataMedicao={'10/10/2021'}
              Situacao={'as'}
              Previsto={10}
              deAderencia={dadosAderenciaCritical.AderenciaCritica}
              Aderencia={dadosAderenciaCritical.TotalTarefasCriticas}
              RealPrevisto={dadosAderenciaCritical.realprevistoTotal}
              ordem={1}
              DesvioCritico={dadosCritical.DesvioCritico}
              TerminoCritico={dadosCritical.TerminoCritico}
              TerminoMedicaoCritico={dadosCritical.TerminoMedicaoCritical}
              TerminoObra={dadosCritical.TerminoObra}
            />
          </Grid>
          <Grid item lg={4}></Grid>
          <Grid item lg={4}></Grid>
        </GridContainer>
        <Backdrop className={classes.backdrop} open={backdropActivate} onClick={handleBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          onEntering={handleEntering}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description">
          <DialogTitle id="scroll-dialog-title"> Detalhe do Relatorio x </DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
              <iframe src={filex} type="application/pdf" width="100%" height="800px"></iframe>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePostFileTimelinera(idrelatorios)} color="secondary">
              Publicar
            </Button>
            <Button onClick={handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </PageContainer>
      <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
        {list('right')}
      </Drawer>
    </React.Fragment>
  );
};

export default Relatorios;
