import React from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';

import Grid from '@material-ui/core/Grid';

import Divider from '@material-ui/core/Divider';

import HoverInfoCard from '@jumbo/components/Common/HoverInfoCard';
import { Box } from '@material-ui/core';
 
import makeStyles from '@material-ui/core/styles/makeStyles';


import MessageIcon from '@material-ui/icons/Message';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import ListAltIcon from '@material-ui/icons/ListAlt';

import AssessmentIcon from '@material-ui/icons/Assessment';
import StraightenIcon from '@material-ui/icons/Straighten';

 




const useStyles = makeStyles(theme => ({
    navLink: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,
      color: theme.palette.text.secondary,
    },
    iconRoot: {
      fontSize: 18,
      marginRight: 10,
    },
    titleSpace: {
      marginBottom: 20,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.up('sm')]: {
        marginTop: 15,
      },
      
      
    },
    carding: {
        cursor:"pointer",
        '&:hover': {
          boxShadow: '0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2)',
          '& $iconThumb': {
            width: 95,
            height: '100%',
            borderRadius: 0,
          },
          '& $hoverContent': {
            transform: 'translate(-10px, -50%)',
          }
        }
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  }));






const breadcrumbs = [
  { label: "G+P", link: '/' },
  { label: "ADMIN", isActive: true },
];

const Admin = () => {
  const classes = useStyles();
  return (

    <PageContainer heading="ADMIN" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{ marginBottom: 10 }}>
            
          </div>
          <Divider />
           
          <div style={{ marginTop: 10 }}> </div>

          <Grid item xs={12} md={12} xl={12}>
          <GridContainer>
            <Grid item xs={12} md={4}  sm={4}>
            <HoverInfoCard
                backgroundColor="#FF8C00"
                icon={<StraightenIcon style={{ color: '#ffffff' }} />}
                title={"Obras"}
                linkOnArrow={'/admin/obras'}
                subTitle={<>
                <Box> <MessageIcon className={classes.iconRoot} /> Aprovadas |   </Box>
                        <Box> <MailOutlineIcon className={classes.iconRoot} /> Abertas |  </Box>
                       </>}
              />
            </Grid>

            <Grid item xs={12}  md={4} sm={4}>
            <HoverInfoCard
                backgroundColor="#808080"
                icon={<AssessmentIcon style={{ color: '#ffffff' }} />}
                title={"Usuarios"}
                linkOnArrow={'/admin/usuarios'}
                subTitle={<>
                <Box> <MessageIcon className={classes.iconRoot} /> Gerados |   </Box>
                        <Box> <MailOutlineIcon className={classes.iconRoot} /> Aprovads |  </Box>
                      </>}
              />
            </Grid>

            <Grid item xs={12} md={4}  sm={4}>
            <HoverInfoCard
                backgroundColor="#6EC1E4"
                icon={<ListAltIcon style={{ color: '#ffffff' }} />}
                title={"Empresas"}
                linkOnArrow={'/admin/empresas'}
                subTitle={<>
                <Box> <MessageIcon className={classes.iconRoot} /> Importados |  </Box>
                       </>}
              />
            </Grid>
          </GridContainer>
        </Grid>
            
           
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Admin;
