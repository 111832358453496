const initialState = {
    list: [],
    auxiliar: [],
    previsto: 0,
    realizado: 0,
    previsto_ac: 0,
    realizado_ac: 0,
    DataStatus: 0,
    DesviodoPrazoPerc: 0,
    DiasQueFaltam: 0,
    DiferencaDiasUteis: 0,
    DuracaoDiasUteis: 0,
    DuracaoGerencial: 0,
    DuracaoMeta: 0,
    FisicoPrevisto: 0,
    FisicoRealizado: 0,
    IdObra: 0,
    IdRelatorio: 0,
    InicioMeta: 0,
    InicioReplanejado: 0,
    MediaRfm: 0,
    RealizadoSobrePrevisto: 0,
    TendenciaData: 0,
    TendenciaDias:0,
    TerminoMeta: 0,
    TerminoReplanejado: 0,
    created_at:0,
    id: 0,
    obraExecutada: 0,
    updated_at: 0,

}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LIST': {
            return {
            ...state,
            list: action.payload,
            };
        }
        case 'GET_PREVISTO': {
            return {
            ...state,
            previsto: action.payload,
            };
        }
        case 'GET_REALIZADO': {
                return {
                ...state,
                realizado: action.payload,
                };
            }
        case 'GET_PREVISTO_AC': {
                return {
                ...state,
                previsto_ac: action.payload,
                };
            }
        case 'GET_REALIZADO_AC': {
                return {
                ...state,
                realizado_ac: action.payload,
                };
            }
        case 'GET_DATASTATUS': {
                return {
                ...state,
                DataStatus: action.payload,
                };
            }
        case 'GET_DESVIODOPRAZOPERC': {
                return {
                ...state,
                DesviodoPrazoPerc: action.payload,
                };
            }
        case 'GET_DIASQUEFALTAM': {
                return {
                ...state,
                DiasQueFaltam: action.payload,
                };
            }
        case 'GET_DIFERENCADIASUTEIS': {
                return {
                ...state,
                DiferencaDiasUteis: action.payload,
                };
            }
        case 'GET_DURACAODIASUTEIS': {
                return {
                ...state,
                DuracaoDiasUteis: action.payload,
                };
            }
        case 'GET_DURACAOGERENCIAL': {
                return {
                ...state,
                DuracaoGerencial: action.payload,
                };
            }
        case 'GET_DURACAOMETA': {
                return {
                ...state,
                DuracaoMeta: action.payload,
                };
            }
        case 'GET_FISICOPREVISTO': {
                return {
                ...state,
                FisicoPrevisto: action.payload,
                };
            }
        case 'GET_FISICOREALIZADO': {
                return {
                ...state,
                FisicoRealizado: action.payload,
                };
            }
        case 'GET_IDOBRA': {
                return {
                ...state,
                IdObra: action.payload,
                };
            }
        case 'GET_IDRELATORIO': {
                return {
                ...state,
                IdRelatorio: action.payload,
                };
            }
        case 'GET_INICIOMETA': {
                return {
                ...state,
                InicioMeta: action.payload,
                };
            }
        case 'GET_INICIOREPLANEJADO': {
                return {
                ...state,
                InicioReplanejado: action.payload,
                };
            }
        case 'GET_MEDIARFM': {
                return {
                ...state,
                MediaRfm: action.payload,
                };
            }
        case 'GET_REALIZADOSOBREPREVISTO': {
                return {
                ...state,
                RealizadoSobrePrevisto: action.payload,
                };
            }
        case 'GET_TENDENCIADATA': {
                return {
                ...state,
                TendenciaData: action.payload,
                };
            }
        case 'GET_TENDENCIADIAS': {
                return {
                ...state,
                TendenciaDias: action.payload,
                };
            }
        case 'GET_TERMINOMETA': {
                return {
                ...state,
                TerminoMeta: action.payload,
                };
            }
        case 'GET_TERMINOREPLANEJADO': {
                return {
                ...state,
                TerminoReplanejado: action.payload,
                };
            }
        case 'GET_CREATED_AT': {
                return {
                ...state,
                created_at: action.payload,
                };
            }
        case 'GET_ID': {
                return {
                ...state,
                id: action.payload,
                };
            }
        case 'GET_OBRAEXECUTADA': {
                return {
                ...state,
                obraExecutada: action.payload,
                };
            }
        case 'GET_UPDATED_AT': {
                return {
                ...state,
                updated_at: action.payload,
                };
            }



      default:
        return state;
    }
  };