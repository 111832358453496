import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';
import {  useParams } from 'react-router-dom';
// import { getUserDetail } from '../../../redux/actions/ProfileApp';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import { getUserDetail } from 'redux/actions/ProfileApp';
// import StatDesempenho  from '../graficos/StatDesempenho';
import { Button } from '@material-ui/core';
import useApi from 'services/api'
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';

import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AutoRenew from '@material-ui/icons/Autorenew';
import Divider from '@material-ui/core/Divider';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Generics from 'routes/Pages/Constructor/Components/table/Generics';
import CicloEdit from './CicloEdit';
import SemanaEdit from './SemanaEdit';

import Resume from './Resume';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}






const useStyles = makeStyles((theme) => ({
  pageFull: {
    width: '100%',
  },
  profileSidebar: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  profileMainContent: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  boxInfo: {
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    margin: 'auto',
    alignItems: 'center'
  },
}));

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [tabValue, setTabValue] = useState('about');
  const [nomeObra,setNomeObra] = useState('');
  const [situacaoPavimentos,setSituacaoPavimentos] = useState('');
  // const { userDetail } = useSelector(({ profileApp }) => profileApp);
  const api = useApi();
  const { id } = useParams();
  const handlemakepaviments = async () => {
    if (situacaoPavimentos == '') {

    } else {
      if (window.confirm('Tem certeza que deseja apagar todos os pavimentos e recriar ??')){
         
      }else {
        return ;
      }
    }
    const data = {
      IdObra: id
    }
    const result = await api.createPavimentos(data);
    if(result.error == '') {
      getListOfPavimentos();
    }else {
      alert("algum erro!")
    }
  }
  const [listCronogramas, setListCronogramas] = useState('');
  const [listColumn,setListColumn] = useState('');
  // const [validRpt,setValidRpt] = useState(false);
  function teste(value) {
    window.alert(value)
  }


  const getListOfPavimentos = async () => {
   
    const result = await api.getPavimentos(id);
  
    if (result.error === '') {
      const rows = result.pavimentos.map((dats) => {
        return {
          Ordem: dats.Ordem,
          Bloco: dats.Bloco,
          Setor: dats.Setor,
          Andar: dats.Andar,
          QRCODE: <img src={dats.QRCODE}></img>,
          action: <button onClick={(e)=>{teste(dats.Link)}}>ACESSAR</button>
        }
      })

      if (result.pavimentos.length > 0) {
        setSituacaoPavimentos("Gerado")
      }
      setListCronogramas(rows);
      setListColumn(['Ordem','Bloco','Setor','Andar','QRCODE','action']);
      // setValidRpt(true);
    } else {
      alert(result.error);
    }
  }




  const breadcrumbs = [
    { label: 'G+P', link: '/' },
    { label: 'ADMIN', link: '/admin/obras' },
    { label: 'MANAGER', link: '/admin/obras' },
    { label: `OBRA:${nomeObra}`, isActive: true },
  ];

  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //  HOMELANDER
  const [listBeginTask, setListBeginTask] = useState('');
  // const [listBeginTaskx, setListBeginTaskx] = useState('');

  // const [checked, setChecked] = React.useState([]);
  // const [left, setLeft] = React.useState([]);
  // const [right, setRight] = React.useState([]);
 

const [inicioFundacao,setInicioFundacao] = useState('');
const [terminoFundacao,setTerminoFundacao] = useState('');
const [inicioEstrutura,setInicioEstrutura] = useState('');
const [terminoEstrutura,setTerminoEstrutura] = useState('');
const [inicioFachada,setInicioFachada] = useState('');
const [terminoFachada,setTerminoFachada] = useState('');
// const [primeiroCicloObra,setPrimeiroCicloObra] = useState('');
// const [primeiraMedicaoObra,setPrimeiraMedicaoObra] = useState('');
// const [quantidadeBlocos,setQuantidadeBlocos] = useState(1);


const CallListResumeTasks = async () => {
  const result = await api.getResumeTasks(id);
  if (result.error === '') {

    
   const mappedArray = result.list.map((student => {
     return student.Name != '' ? 
     { ...student, 'resumoLista': student.UniqueID + "-" + student.Name + "-" + student.Text4 }
     : student;
   })
   );

    // setListBeginTaskx(result.list);

     console.log(mappedArray);
     console.log(result.list);

    setListBeginTask(mappedArray);        
  } else {
    alert(result.error);
  }
}


  useEffect(() => {
    getListOfPavimentos();
    CallListResumeTasks();
    // dispatch(getUserDetail());
  }, [dispatch]);

  return (
    <React.Fragment>
      <PageContainer heading={"Admin > Obra > Manager"} breadcrumbs={breadcrumbs}>
    
          <Box className={classes.root}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}>
                <Tab label="Resumo" {...a11yProps(0)} />
                <Tab label="Pavimentos" {...a11yProps(1)} />
                <Tab label="Marcos" {...a11yProps(2)} />
                <Tab label="Ciclos" {...a11yProps(3)} />
                <Tab label="Semanas" {...a11yProps(4)} />
                <Tab label="Integrações" {...a11yProps(5)} />
                <Tab label="Editar Dados" {...a11yProps(6)} />
                <Tab label="Usuarios" {...a11yProps(7)} />

              </Tabs>
              <TabPanel value={value} index={0}>
              <Box tem  xs={12}  sm={12} md={12} xl={12} >
                <p>Tela de Gerenciamento da obra </p>
                <p>
                  <div style={{paddingLeft:20}}>
                <ul>
                  <li>Edição de Ciclos</li>
                  <li>Edição de Pavimentos</li>
                  <li>Edição de MARCOS</li>
                </ul>
                </div>
                </p>
               </Box>
                 

              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box tem  xs={12}  sm={12} md={12} xl={12} >
                <GridContainer>
                    <Grid item  xs={12} sm={6} md={6} xl={6} >  
                      <div className={classes.boxInfo}>
                        Situação : {situacaoPavimentos}
                      </div>
                    </Grid>
                    <Grid item  xs={12} sm={6} md={6} xl={6} > 
                    <Button variant="contained" color="primary" size="small" startIcon={<AutoRenew />} onClick={(e)=>handlemakepaviments()}> 
                    { situacaoPavimentos ? 'Refazer pavimento': 'Gerar pavimento'}
                    </Button>
                   </Grid>
               
                  <Divider/>
                  <Grid item  xs={12} sm={12} md={12} xl={12} >  
                  <Generics
                      cronogramaData={listCronogramas}
                      colun={listColumn}
                      /> 
                              
                    </Grid>
                </GridContainer>
                </Box>
               
              </TabPanel>
              <TabPanel value={value} index={2}>
                <GridContainer>
                    <Grid item  xs={12} sm={6} md={6} xl={6} >  
                      <div className={classes.boxInfo}>
                        Situação : {situacaoPavimentos}
                      </div>
                    </Grid>
                    <Grid item  xs={12} sm={6} md={6} xl={6} > 
                    <Button variant="contained" color="primary" size="small" startIcon={<AutoRenew />} onClick={(e)=>handlemakepaviments()}> 
                     Salvar Alterações
                    </Button>
                   </Grid>
               
                  <Divider/>
                  <Grid item  xs={12} sm={12} md={12} xl={12} >  
                   
                  <GridContainer>
                        <Grid item md={6}>
                          <Autocomplete
                          id="inicioFundacao"
                          options={listBeginTask}
                          getOptionLabel={option => option.resumoLista}
                          style={{ width: 300 }}
                          renderInput={params => <TextField {...params} label="Inicio Fundação" variant="outlined"    />}
                          onChange={(event, newValue) => {
                            setInicioFundacao(newValue);
                          }}
                          inputValue={inicioFundacao}
                          onInputChange={(event, newInputValue) => {
                            setInicioFundacao(newInputValue);
                          }}  
                        /> 
                        </Grid>
                      
                        <Grid item md={6}>
                            <Autocomplete
                          id="terminoFundacao"
                          options={listBeginTask}
                          getOptionLabel={option => option.resumoLista}
                          style={{ width: 300 }}
                          renderInput={params => <TextField {...params} label="Término Fundação" variant="outlined"  onChange={(e)=>setTerminoFundacao(e.target.value)} />}
                          onChange={(event, newValue) => {
                            setTerminoFundacao(newValue);
                          }}
                          inputValue={terminoFundacao}
                          onInputChange={(event, newInputValue) => {
                            setTerminoFundacao(newInputValue);
                          }}
                            
                        /> 
                        </Grid>
                    </GridContainer>
                      
                      <GridContainer>
                        <Grid item md={6}>
                            <Autocomplete
                          id="combo-box-demo"
                          options={listBeginTask}
                          getOptionLabel={option => option.resumoLista}
                          style={{ width: 300 }}
                          renderInput={params => <TextField {...params} label="Inicio Estrutura" variant="outlined"   onChange={(e)=>setInicioEstrutura(e.target.value)}/>}
                          onChange={(event, newValue) => {
                            setInicioEstrutura(newValue);
                          }}
                          inputValue={inicioEstrutura}
                          onInputChange={(event, newInputValue) => {
                            setInicioEstrutura(newInputValue);
                          }}

                          /> 
                        </Grid>
                        
                        <Grid item md={6}>
                            <Autocomplete
                          id="combo-box-demo"
                          options={listBeginTask}
                          getOptionLabel={option => option.resumoLista}
                          style={{ width: 300 }}
                          renderInput={params => <TextField {...params} label="Término Estrutura" variant="outlined"   onChange={(e)=>setTerminoEstrutura(e.target.value)} />}
                          onChange={(event, newValue) => {
                            setTerminoEstrutura(newValue);
                          }}
                          inputValue={terminoEstrutura}
                          onInputChange={(event, newInputValue) => {
                            setTerminoEstrutura(newInputValue);
                          }}

                        /> 
                        </Grid>
                      </GridContainer>
                      <GridContainer>
                        <Grid item md={6}>
                            <Autocomplete
                          id="combo-box-demo"
                          options={listBeginTask}
                          getOptionLabel={option => option.resumoLista}
                          style={{ width: 300 }}
                          renderInput={params => <TextField {...params} label="Inicio Fachada" variant="outlined" onChange={(e)=>setInicioFachada(e.target.value)}/>}
                          onChange={(event, newValue) => {
                            setInicioFachada(newValue);
                          }}
                          inputValue={inicioFachada}
                          onInputChange={(event, newInputValue) => {
                            setInicioFachada(newInputValue);
                          }}


                        /> 
                        </Grid>
                        <Grid item md={6}>
                            <Autocomplete
                            id="combo-box-demo"
                            options={listBeginTask}
                            getOptionLabel={option => option.resumoLista}
                            style={{ width: 300 }}
                            renderInput={params => <TextField {...params} label="Término Fachada" variant="outlined" onChange={(e)=>setTerminoFachada(e.target.value)} />}
                            onChange={(event, newValue) => {
                              setTerminoFachada(newValue);
                            }}
                            inputValue={terminoFachada}
                            onInputChange={(event, newInputValue) => {
                              setTerminoFachada(newInputValue);
                            }}
              

                          />
                          </Grid>
                      </GridContainer>       
                    </Grid>
                </GridContainer>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <CicloEdit/>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <SemanaEdit/>
              </TabPanel>
              <TabPanel value={value} index={5}>
                Item Six
              </TabPanel>
              <TabPanel value={value} index={6}>
                <Box item  xs={12} sm={12} md={12} xl={12} >  
                    <Resume/>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={7}>
                Item  Eight
              </TabPanel>
            </Box>
            {/* <GridContainer>
              <Grid item xs={12} lg={4} className={classes.profileSidebar}>
                <Box mb={6}>

                </Box>
                <Box mb={6}>

                  <Button onClick={(e)=>{handlemakepaviments()}}> as </Button>
                </Box>
                <Box mb={6}>
                
                </Box>
                <Box mb={6}>
                  <GooglePixel />
                </Box>
              </Grid>
              <Grid item xs={12} lg={8} className={classes.profileMainContent}>
            
                <Box mb={6}>
                  <Biography />
                </Box>
                
              </Grid>
            </GridContainer> */}
        
      </PageContainer>
    </React.Fragment>
  );
};

export default Profile;
