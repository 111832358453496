import React, { useEffect, useState } from 'react'; 
import { NavLink , useParams , useHistory} from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import { Box, Button, Card, Grid, Input } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Alert } from '@material-ui/lab';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import NoteAdd from '@material-ui/icons/NoteAddSharp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TableGeneric from './tables'
import useAPi from '../../../../../services/api'



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.samplePage'} />, isActive: true },
];

const SamplePage = () => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [isOrcamento,setIsOrcamento] = useState(false);
  const [isVinculo,setIsVinculo] = useState(false);
  const [tabelaGenerica,setTableGeneric]  = useState('');
  const [showTable,setShowTable]  = useState(false)
  const [dataAgrupamento,setDataAgrupamento] = useState('');
  const [dataVinculos,setDataVinculos] = useState('');
  const [fileToUpload,setFileToUpload] = useState('');
  const api = useAPi();

  const handleClickOpen = (info) => {
    if ( info == 'orcamento'){
      setIsOrcamento(true);
      setIsVinculo(false);
    }else {
      setIsOrcamento(false);
      setIsVinculo(true);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [HeaderTable,setHeaderTable] = useState('');
  const handleViewSelected = async (info) => {
    console.log(info)
    let ValoresTabela = '';
    let TitulosTabela = '';
    let TableName = '';
    if ( info == 'orcamento'){
      ValoresTabela = dataAgrupamento;
      TableName = 'Agrupamento'
       TitulosTabela =  [
        { title: 'id', field: 'id' },
        { title: 'Item', field: 'Item' },
        { title: 'Descricao', field: 'Descricao' },
        { title: 'Valor', field: 'Valor' },
        { title: 'Peso', field: 'Peso' },
      ];
    } else {
      TableName = 'Vinculos'

      ValoresTabela = dataVinculos;
      TitulosTabela =['id','item','UniqueID'];
      TitulosTabela =  [
        { title: 'id', field: 'id' },
        { title: 'Item', field: 'Item' },
        { title: 'UniqueID', field: 'UniqueID' },
       
      ];

    }
    console.log(ValoresTabela)
    setTableGeneric(<TableGeneric
      tableName={TableName}
      HeaderTable={TitulosTabela}
      DadosTable={ValoresTabela}/>)
    setShowTable(true);
  }

  const getTable = async () => {
    const result = await api.getBaseFpla(id);
        console.log(result)
      console.table(result.Agrupamento)
      setDataAgrupamento(result.Agrupamento);
      setDataVinculos(result.Vinculos);
     
  }
  const handleChange = (event) => {
        setFileToUpload(event.target.files[0])
  }

  const handleModalSave = async () => {
       let result = '';
        
        let data  = {
            IdObra: id,
            file: fileToUpload,
        };

        if (fileToUpload == '') {
          alert("Selecione o arquivo de importação");
        }else {
          if (isOrcamento == true) { 
            result  = await api.uploadAgrupamento(data);
          }
          if (isVinculo == true) { 
            result  = await api.uploadVinculos(data);
          }
        }
        
       
      
}
const gerarDistribuicao = async () => {

let data = {
  IdObra: id,
  base: '0',
  isInitial: '1'
};

let result = api.setDistribuicao(data);
if (result.error != ''){
  alert(result.error);
}
}
  useEffect(() =>{
    getTable();
  }, []);
  return (
    <PageContainer heading={ 
      <>
      DFM - DISTRIBUIÇÃO FISICA MENSAL
      </>
    } breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          
          <Divider />
         
      



        </Grid>
{/* ##  */}
        <Grid item xs={4} xl={4} >
     <Card>
        <table>
          <tr >
            <th>Item</th>
            
            <th>Actions</th>
            <th>Status</th>

          </tr>
            <tr>
             <td><Alert severity="success"></Alert></td>

              <td>Orçamento / Agrupamento</td>
              
             
              <td>
              <Button variant="contained" color="default" startIcon={<CloudUploadIcon />}  onClick={(e)=>{handleClickOpen('orcamento')}}>
                Upload
              </Button>
              <Button variant="contained" color="default" startIcon={<EyeIcon />} onClick={(e)=>{handleViewSelected('orcamento')}}>
                Ver
              </Button>
              </td>
            </tr>
            <tr>
              <td><Alert severity="success"></Alert></td>

              <td>Vinculo </td>
              
             
              <td>
              <Button variant="contained" color="default" startIcon={<CloudUploadIcon />}  onClick={(e)=>{handleClickOpen('vinculo')}}>

                Upload
              </Button>
              <Button variant="contained" color="default" startIcon={<EyeIcon />} onClick={(e)=>{handleViewSelected('vinculo')}}>
                Ver
              </Button>
              </td>
            </tr>
            <tr>
              <td><Alert severity="success"></Alert></td>
                
              <td>Gerar Distribuicao do Cronograma </td>
               
              <td>
              <Button variant="contained" color="default" onClcik={(e)=>{gerarDistribuicao()}} startIcon={<NoteAdd />}>
                Gerar
              </Button>
              <Button variant="contained" color="default" startIcon={<EyeIcon />}>
                Ver
              </Button>
              </td>
              
            </tr>
            <tr>
              <td><Alert severity="success"></Alert></td>
              <td>Distribuicao Fisica Ponderada </td>
              <td>
              <Button variant="contained" color="default" startIcon={<NoteAdd />}>
                Gerar
              </Button>
              <Button variant="contained" color="default" startIcon={<EyeIcon />}>
                Ver
              </Button>
              </td>

            </tr>
            <tr>
              <td><Alert severity="success"></Alert></td>
              <td>Distribuicao Fisica Percentual </td>
            </tr>
            <tr>
              <td><Alert severity="success"></Alert></td>
              <td>Gerar Distribuicao do Cronograma </td>
             
              <td>
              <Button variant="contained" color="default" startIcon={<NoteAdd />}>
                Gerar
              </Button>
              <Button variant="contained" color="default" startIcon={<EyeIcon />}>
                Ver
              </Button>
              </td>

            </tr>
          </table>
        
          </Card>
        </Grid>
        <Grid item xs={8} xl={8} >
          {showTable && 
           tabelaGenerica
          }
        </Grid>
         
      </GridContainer>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">{"Importações!"}</DialogTitle>
        <DialogContent>
         { isOrcamento &&
            <DialogContentText id="alert-dialog-slide-description">
            <p>Download modelo de Orçamento : Link</p>    
              <Divider/>
            <p> Importar Novo Agrupamento / Orçamento</p>
            <Input type="file"   id="Agrupamento"  label="Agrupamento"   onChange={(e)=>{handleChange(e)}}  variant="outlined" />
            
          </DialogContentText>
         }          
           { isVinculo &&
            <DialogContentText id="alert-dialog-slide-description">
            <p>Download modelo de Vinculos : Link</p>    
              <Divider/>
            <p> Importar Novo Vinculo</p>
            <Input type="file"   id="Vinculos"  label="Vinculos"    onChange={(e)=>{handleChange(e)}}  variant="outlined" />
            
          </DialogContentText>
         }  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={(e)=>{handleModalSave()}} color="primary">
            Importar/Salvar
          </Button>
        </DialogActions>
      </Dialog>
      
    </PageContainer>

    
  );
};

export default SamplePage;
