 
export default () => {
    return {
        dataAtualFormatada: (datainfo) => {
            
                var data = new Date(datainfo),
                dia  = data.getDate().toString(),
                diaF = (dia.length === 1) ? '0'+dia : dia,
                mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
                mesF = (mes.length === 1) ? '0'+mes : mes,
                anoF = data.getFullYear();
            return diaF+"/"+mesF+"/"+anoF;
            
           
        },
        dataPTBR: (datainfo) => {
            
            let dataString = datainfo;
            if (datainfo > "" ) {
                let data =  datainfo.split("-");

                let dia = data[2];
                let mes = data[1];
                let ano = data[0];
    
                
                dataString = data[2]+"/"+data[1]+"/"+data[0];
                
            }
            return dataString
        
       
        },
        percentualArred: (valor) => {
            try {
               if ( typeof valor == 'string' ){
                var data = Math.floor(valor * 100) / 100;
                if (data == NaN) {
                   return "";
                }else{
                    return data;
                }
               }
            } catch (error) {
                return ""; 
            }
           
        },
        mesAbreviado: (datainfo) => {
            let date = datainfo.toString();
            let mes = date.substring(5,7);
            let ano = date.substring(2,4);

            switch (mes) {
                case '01':
                    date = 'jan-'+ano;
                break;
                case '02':
                    date = 'fev-'+ano;
                break;
                case '03':
                    date = 'mar-'+ano;
                break;
                case '04':
                    date = 'abr-'+ano;
                    
                break;
                case '05':
                    date = 'mai-'+ano;
                    
                break;
                case '06':
                    date = 'jun-'+ano;
                    
                break;
                case '07':
                    date = 'jul-'+ano;
                    
                break;
                case '08':
                    date = 'ago-'+ano;
                    
                break;
                case '09':
                    date = 'set-'+ano;
                    
                break;
                case '10':
                    date = 'out-'+ano;
                    
                break;
                case '11':
                    date = 'nov-'+ano;

                break;
                case '12':
                    date = 'dez-'+ano;
                break;
                default:
                    date = date;
                    break;
            } 
            return date;
        }
    }
} 