import React, { useState, useEffect, useRef, Component } from 'react';
import { useParams, Link, Redirect, NavLink , useHistory  } from 'react-router-dom';

import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import useApi from '../../../../../services/api'
import MUIDataTable from "mui-datatables";


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
    console.log();
     

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row"> {row.MesInfo}</TableCell>
        <TableCell align="right">{row.MesCiclo}</TableCell>
        <TableCell align="right">{row.InicioCiclo}</TableCell>
        <TableCell align="right">{row.TerminosCiclo}</TableCell>
        <TableCell align="right">{row.PercentualPrevisto}</TableCell>
        <TableCell align="right">{row.detail.list.length}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Semana</TableCell>
                    <TableCell>Mês</TableCell>
                    <TableCell align="right">Inicio</TableCell>
                    <TableCell align="right">Término</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.detail.list.map(historyRow => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">{historyRow.SemanaCiclox}</TableCell>
                      <TableCell>{historyRow.MesCiclox}</TableCell>
                      <TableCell align="right">{historyRow.InicioCiclox}</TableCell>
                      <TableCell align="right">{historyRow.TerminoCiclox}</TableCell>
                      <TableCell align="right">{historyRow.SemanaCiclox}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

export default function CollapsibleTable() {

    const history  = useHistory();
    const { id } = useParams();
  const [list, setList] = useState([]);
   
  const api = useApi();

  const getList = async () => {
 
    const result = await api.getCiclos(id);
 
    if (result.error === '') {
        console.log(result.list)
      setList(result.list);
    } else {
      alert(result.error);
    }
  }
  useEffect(() => {

  
    getList();
     
  }, []);
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table aria-label="collapsible table"  size="small" >
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Mês</TableCell>
            <TableCell align="right">Data</TableCell>
            <TableCell align="right">Inicio</TableCell>
            <TableCell align="right">Término</TableCell>
            <TableCell align="right">Previsto</TableCell>
            <TableCell align="right">Total Semanas</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map(row => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}