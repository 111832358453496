import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
 
import { Box } from '@material-ui/core';
 
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import useFormats from 'services/Formats';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 150,
    maxWidth: 320,
    margin: '0 auto',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
    cursor: 'pointer',
    '&:hover': {
      background: "#C14d07",
      color: "#fff",
    }
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  iconRoot: {
    fontSize: 18,
    marginRight: 10,
  },
  boxs: {
    textAlign: 'center',
    
  },
}));

export default function SimpleCard(props) {
  const classes = useStyles();
  const formats = useFormats();

  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box className={classes.boxs}>
          <strong>
            <p> <PictureAsPdfIcon className={classes.iconRoot} /> {props.reportName} </p>
          </strong>
          <p> {props.date} </p>
        </Box>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}
