import React, { useEffect, useState } from 'react';

import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import { Multiselect } from 'multiselect-react-dropdown';
import useApi from '../../../../services/api';
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Generics from '../../Builders/Components/table/Generics';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import MUIDataTable from 'mui-datatables';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';

import Empresas from '../Empresas';

function typeUser(type) {
  if (type == 3) {
    return 'Cliente';
  }
  if (type == 2) {
    return 'Manager';
  }
  if (type == 1) {
    return 'Admin';
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(2),
    },
    color: green[400],
    '&$checked': {
      color: green[600],
    },
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));
const breadcrumbs = [
  { label: 'G+P', link: '/' },
  { label: 'ADMIN', link: '/admin/home' },
  { label: 'MANAGER', isActive: true },
];

const Obras = () => {
  const classes = useStyles();
  const [dast, setDast] = React.useState([]);
  const [selecteds, setSelecteds] = React.useState([]);
  const history = useHistory();

  const [modalPermission, setModalPermission] = React.useState('');
  const [modalObra, setModalObra] = React.useState(false);
  const [modalUser, setModalUser] = React.useState(false);
  const [logoObra, setLogoObra] = React.useState('');
  const [logoConstrutora, setLogoConstrutora] = React.useState('');

  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState('a');
  const [listObras, setListObras] = useState('');
  const [listOriginal, setListOriginal] = React.useState([]);

  const [headerObras, setHeaderObras] = React.useState('');

  const [listUsers, setListUsers] = React.useState('');
  const [headerUsers, setHeaderUsers] = React.useState('');
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const [nomeObra, setNomeObra] = useState('');

  const [modalEmpresa, setmodalEmpresa] = useState('');
  const [modalType, setModalType] = useState('');
  const [modalPassword, setModalPassword] = useState('');
  const [modalTimelineID, setModalTimelineID] = useState('');
  const [modalResponsavel, setModalResponsavel] = useState('');
  const [modalCronogramaId, setmodalCronogramaId] = useState('');
  const [modalCidade, setmodalCidade] = useState('');
  const [modalEstado, setmodalEstado] = useState('');
  const [modalEndereco, setmodalEndereco] = useState('');
  const [modalRDO, setModalRDO] = useState(false);

  const [modalFileConstrutora, setModalFileLogoConstrutora] = useState('');
  const [modalFileObra, setModalFileLogoObra] = useState('');

  const [modalId, setModalId] = useState('');

  const [stateActual, setStateActual] = useState('');

  const handleChangeRdo = event => {
    setModalRDO(event.target.checked);
  };

  const handleChangetab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangetabIndex = index => {
    setValue(index);
  };
  const getListUsers = async () => {
    setLoading(true);
    const result = await api.getAllUser();
    setLoading(false);
    if (result.error === '') {
      setHeaderUsers(['email', 'name', 'tipo', 'actions']);
      const listaUsuarios = result.list.map((user, index) => ({
        id: user.id,
        name: user.name,
        email: user.email,
        type: user.type,
        tipo: typeUser(user.type),
        TimelineID: user.TimelineID,
        created_at: user.created_at,
        listaObras: user.listaObras,
        actions: (
          <div>
            <Button onClick={() => handleUserEdit(index)}>Editar</Button> <Button>Desativar</Button>{' '}
            <Button>Gerenciar</Button>
          </div>
        ),
      }));

      localStorage.setItem('listaUsuarios', JSON.stringify(listaUsuarios));

      setListUsers(listaUsuarios);
      console.log(listaUsuarios);
    } else {
      alert(result.error);
    }
  };

  const HandleRedirectManager = IdObra => {
    history.push(`/admin/manager/${IdObra}`);
  };

  const getListObras = async () => {
    setLoading(true);
    const result = await api.getObras();
    setLoading(false);
    if (result.error === '') {
      setHeaderObras(['obra', 'empresa', 'TimelineID', 'actions']);
      const thelist = result.list;

      let keys = 0;
      const rowObras = thelist.map((obra, index) => ({
        id: obra.id,
        obra: obra.obra,
        empresa: obra.empresa,
        Responsavel: obra.Responsavel,
        cronogramaId: obra.cronogramaId,
        TimelineID: obra.TimelineID,
        cidade: obra.cidade,
        estado: obra.estado,
        endereco: obra.endereco,
        LogoObra: obra.LogoObra,
        LogoConstrutora: obra.LogoConstrutora,

        actions: (
          <div>
            <Button onClick={() => HandleEditButton(index)}>Editar</Button>
            <Button
              onClick={e => {
                clearContentObra(obra.id);
              }}>
              Limpar
            </Button>
            <Button
              onClick={e => {
                HandleRedirectManager(obra.id);
              }}>
              Gerenciar
            </Button>
          </div>
        ),
      }));

      localStorage.setItem('listaObras', JSON.stringify(rowObras));
      setListObras(rowObras);
    } else {
      alert(result.error);
    }
  };

  const checkUserPermission = async () => {
    setLoading(true);
    const result = await api.checkMe();
    setLoading(false);
    if (result.permission != '3') {
    } else {
    }
  };
  const clearContentObra = async index => {
    let data = {
      IdObra: index,
    };

    if (window.confirm('TEM CERTEZA QUE DESEJA LIMPAR A OBRA ? não poderá ser revertido!!!')) {
      let result = await api.clearObra(data);
    } else {
    }
  };

  const getObraList = async index => {
    setLoading(true);
    const result = await api.getAllUser();
    const resultx = await api.getAcessUsers(index);

    setLoading(false);
    if (result.error === '') {
      const optionlist = result.list;
      setDast(
        optionlist.map(({ id, name }) => ({
          name: name,
          id: id,
        })),
      );
      const selectedOptionList = resultx.list;
      const listaComAcesso = selectedOptionList.filter(name => name.obra_id != null);
      const ofSelected = listaComAcesso.map(({ id, name }) => ({
        name: name,
        id: id,
      }));
      console.log(ofSelected);
      setSelecteds(ofSelected);
      setModalPermission(ofSelected);
    } else {
      alert(result.error);
    }
  };
  const handleClickOpen = () => {
    getObraList();
    setModalObra(true);
  };
  const handleClickOpenuser = () => {
    getObraList();
    setModalUser(true);
  };

  const handleClose = () => {
    setModalObra(false);
    setModalUser(false);
  };

  const onSelect = dast => {
    setModalPermission(dast);
    console.log(dast);
  };

  const onRemove = dast => {
    setModalPermission(dast);
    console.log(dast);
  };
  const handleChange = event => {
    let imageApend = URL.createObjectURL(event.target.files[0]);
    if (event.target.id == 'logoObra') {
      setLogoObra(imageApend);
      setModalFileLogoObra(event.target.files[0]);
    }
    if (event.target.id == 'logoConstrutora') {
      setLogoConstrutora(imageApend);
      setModalFileLogoConstrutora(event.target.files[0]);
    }
  };

  const HandleEditButton = async index => {
    let listalObras = JSON.parse(localStorage.getItem('listaObras'));

    getObraList(listalObras[index]['id']);
    setModalId(listalObras[index]['id']);
    setmodalEstado(listalObras[index]['estado']);
    setmodalEndereco(listalObras[index]['endereco']);
    setmodalCidade(listalObras[index]['cidade']);
    setmodalCronogramaId(listalObras[index]['cronogramaId']);
    setModalTimelineID(listalObras[index]['TimelineID']);
    setmodalEmpresa(listalObras[index]['empresa']);
    setNomeObra(listalObras[index]['obra']);
    setModalFileLogoConstrutora('');
    setModalFileLogoObra('');
    setLogoObra(listalObras[index]['LogoObra']);
    setLogoConstrutora(listalObras[index]['LogoConstrutora']);
    setModalResponsavel(listalObras[index]['Responsavel']);

    setModalObra(true);
  };

  const handleNewButton = index => {
    getObraList('');
    setModalId('');
    setmodalEstado('');
    setmodalEndereco('');
    setmodalCidade('');
    setmodalCronogramaId('');
    setModalTimelineID('');
    setmodalEmpresa('');
    setNomeObra('');
    setModalFileLogoConstrutora('');
    setModalFileLogoObra('');
    setLogoObra('');
    setLogoConstrutora('');
    setModalResponsavel('');
    setSelecteds([]);

    setModalObra(true);
  };
  const HandleRemoveButton = async index => {
    if (window.confirm('tem certeza que deseja excluir?')) {
      const result = await api.removeUser(list[index]['id']);
      if (result.error === '') {
        getListObras();
      } else {
        alert(result.error);
      }
    }
  };

  const handleAcessReport = () => {
    window.open('https://main.gmaisp.tech/api/getallperm', '_blank');
  };

  const handleModalSave = async () => {
    if (modalObra) {
      localStorage.setItem('listaObras', '');

      setModalLoading(true);
      let result;
      let element = [''];
      for (let index = 0; index < modalPermission.length; index++) {
        element += modalPermission[index].id + ',';
      }
      let eletrant = modalPermission.length > 1 ? element.slice(0, -1) : element.slice(0, -1);
      console.log(eletrant);

      let data = {
        obra: nomeObra,
        empresa: modalEmpresa,
        Responsavel: modalResponsavel,
        cronogramaId: modalCronogramaId,
        TimelineID: modalTimelineID,
        estado: modalEstado,
        cidade: modalCidade,
        endereco: modalEndereco,
        rdo: modalRDO,
        users: eletrant,
      };

      if (modalId == '') {
        if (modalFileObra) {
          data.fileObra = modalFileObra;
          data.fileConstrutora = modalFileConstrutora;
          result = await api.addObra(data);
        } else {
          alert('Selecione um arquivo!');
          setModalLoading(false);
          return;
        }
      } else {
        data.fileObra = modalFileObra;
        data.fileConstrutora = modalFileConstrutora;
        console.log(data);

        result = await api.updateObra(modalId, data);
      }

      setModalLoading(false);
      if (result.error === '') {
        setModalObra(false);
        getListObras();
      } else {
        alert(result.error);
      }
    } else {
      alert('preencha os campos!');
    }
  };
  //  ################### EDIÇÂO DE USUARIO
  // ####################
  const [modalEmail, setModalEmail] = useState('');
  const [modalUsername, setModalUsername] = useState('');
  const [radioValue, setRadioValue] = useState('');

  const handleUserEdit = index => {
    let listaUsers = JSON.parse(localStorage.getItem('listaUsuarios'));

    getUsersLists(listaUsers[index]['id']);

    setModalId(listaUsers[index]['id']);
    setModalEmail(listaUsers[index]['email']);
    setModalUsername(listaUsers[index]['name']);
    setModalType(listaUsers[index]['type']);
    setModalTimelineID(listaUsers[index]['TimelineID']);
    setRadioValue(listaUsers[index]['type']);

    console.log(listaUsers[index]['type']);
    setModalUser(true);
  };

  const handleUserNew = index => {
    getUsersLists();
    setModalId('');
    setModalEmail('');
    setRadioValue('1');
    setModalUsername('');
    setModalType('');
    setModalPermission('');
    setModalTimelineID('');
    setSelecteds(['']);

    setModalUser(true);
  };
  const getUsersLists = async index => {
    setLoading(true);
    const result = await api.getObras();
    const resultx = await api.getAcessObras(index);

    setLoading(false);
    if (result.error === '') {
      const optionlist = result.list;
      setDast(
        optionlist.map(({ id, obra }) => ({
          name: obra,
          id: id,
        })),
      );
      const selectedOptionList = resultx.list;
      const listaComAcesso = selectedOptionList.filter(user => user.usuario_id != null);
      const ofSelected = listaComAcesso.map(({ id, obra }) => ({
        name: obra,
        id: id,
      }));
      console.log(ofSelected);
      setSelecteds(ofSelected);
      setModalPermission(ofSelected);
    } else {
      alert(result.error);
    }
  };

  const handleUserSave = async () => {
    if (modalEmail) {
      setModalLoading(true);
      let result;
      let element = [''];
      for (let index = 0; index < modalPermission.length; index++) {
        element += modalPermission[index].id + ',';
      }
      let eletrant = modalPermission.length > 1 ? element.slice(0, -1) : element.slice(0, -1);
      console.log(eletrant);

      let data = {
        email: modalEmail,
        name: modalUsername,
        type: radioValue,
        obras: eletrant,
        TimelineID: modalTimelineID,
      };
      console.log(data);

      if (modalId == '') {
        result = await api.addUser(data);
      } else {
        result = await api.updateUser(modalId, data);
      }

      setModalLoading(false);
      if (result.error === '') {
        setShowModal(false);
        getListUsers();
      } else {
        alert(result.error);
      }
    } else {
      alert('preencha os campos!');
    }
  };

  useEffect(() => {
    getListObras();
    getListUsers();
    checkUserPermission();
  }, []);

  return (
    <PageContainer heading="Gerenciar Obras" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{ marginBottom: 10 }}>
            <Box className={classes.root}>
              <Button
                variant="contained"
                color="default"
                onClick={e => {
                  handleUserNew();
                }}
                startIcon={<CloudUploadIcon />}>
                Cadastrar Usuario
              </Button>
              <Button
                variant="contained"
                color="default"
                onClick={e => {
                  handleNewButton();
                }}
                startIcon={<CloudUploadIcon />}>
                Cadastrar Obra
              </Button>
              <Button
                variant="contained"
                color="default"
                onClick={e => {
                  handleNewButton();
                }}
                startIcon={<CloudUploadIcon />}>
                Cadastrar Empresa
              </Button>
              <Button
                variant="contained"
                color="default"
                onClick={e => {
                  handleNewButton();
                }}
                startIcon={<CloudUploadIcon />}>
                Cadastrar Permissões
              </Button>
              <Button
                variant="contained"
                color="default"
                onClick={e => {
                  handleAcessReport();
                }}
                startIcon={<CloudUploadIcon />}>
                Relatorios
              </Button>
            </Box>
          </div>
          <Divider />
          <div style={{ marginTop: 24 }}></div>
          <GridContainer>
            <Box className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChangetab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example">
                  <Tab label="Obras" {...a11yProps(0)} />
                  <Tab label="Usuarios" {...a11yProps(1)} />
                  <Tab label="Empresas" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangetabIndex}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <h3> Lista de Obras</h3>
                  {listObras && <Generics cronogramaData={listObras} colun={headerObras} />}
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <h3> Lista de Usuarios</h3>
                  {listUsers && <Generics cronogramaData={listUsers} colun={headerUsers} />}
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  <Empresas />
                </TabPanel>
              </SwipeableViews>
            </Box>
          </GridContainer>
          <GridContainer>
            <Grid item xs={12} sm={12} md={6} xl={6}></Grid>
            <Grid item xs={12} sm={12} md={6} xl={6}></Grid>
          </GridContainer>
        </Grid>
      </GridContainer>

      <Dialog open={modalObra} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth="xl" maxWidth="xl">
        <DialogTitle id="form-dialog-title">Cadastro de Obras</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 24, marginBotton: 24 }}></div>
          <GridContainer>
            <GridContainer xs={12} md={8} xl={8}>
              {/*
             //Obra |    Construtora | Responsavel 
             */}
              <Grid item xs={12} md={4} xl={4}>
                <TextField
                  style={{ width: '95%' }}
                  id="outlined-basic"
                  label="Nome Obra"
                  variant="outlined"
                  value={nomeObra}
                  disabled={modalLoading}
                  onChange={e => setNomeObra(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={4} style={{ width: '100%' }}>
                <TextField
                  style={{ width: '95%' }}
                  id="outlined-basic"
                  label="Construtora"
                  variant="outlined"
                  value={modalEmpresa}
                  disabled={modalLoading}
                  onChange={e => setmodalEmpresa(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <TextField
                  style={{ width: '95%' }}
                  id="outlined-basic"
                  label="Responsavel"
                  variant="outlined"
                  value={modalResponsavel}
                  disabled={modalLoading}
                  onChange={e => setModalResponsavel(e.target.value)}
                />
              </Grid>
              {/* -- UF | Cidade | Endereço */}
              <Grid item xs={2} md={2} xl={2}>
                <TextField
                  style={{ width: '95%' }}
                  id="outlined-basic"
                  label="UF"
                  variant="outlined"
                  value={modalEstado}
                  disabled={modalLoading}
                  onChange={e => setmodalEstado(e.target.value)}
                />
              </Grid>
              <Grid item xs={8} md={4} xl={4}>
                <TextField
                  style={{ width: '95%' }}
                  id="outlined-basic"
                  label="Cidade"
                  variant="outlined"
                  value={modalCidade}
                  disabled={modalLoading}
                  onChange={e => setmodalCidade(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <TextField
                  style={{ width: '95%' }}
                  id="outlined-basic"
                  label="Endereço"
                  variant="outlined"
                  value={modalEndereco}
                  disabled={modalLoading}
                  onChange={e => setmodalEndereco(e.target.value)}
                />
              </Grid>
              {/* -- //TimelineID | CronogramaID */}

              <Grid item xs={12} md={6} xl={6}>
                <TextField
                  style={{ width: '95%' }}
                  id="outlined-basic"
                  label="TimelineID"
                  variant="outlined"
                  value={modalTimelineID}
                  disabled={modalLoading}
                  onChange={e => setModalTimelineID(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6} xl={6}>
                <TextField
                  style={{ width: '95%' }}
                  id="outlined-basic"
                  label="ID Cronograma"
                  variant="outlined"
                  value={modalCronogramaId}
                  disabled={modalLoading}
                  onChange={e => setmodalCronogramaId(e.target.value)}
                />
              </Grid>
            </GridContainer>
            <GridContainer xs={12} md={4} xl={4}>
              <Grid item xs={12}>
                <p>Lista de Usuarios com Acesso</p>
                <Multiselect
                  options={dast}
                  displayValue="name"
                  selectedValues={selecteds}
                  disabled={modalLoading}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  onChange={e => setSelecteds(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <p>Logo Obra</p>
                <Input
                  type="file"
                  disabled={modalLoading}
                  id="logoObra"
                  onChange={e => {
                    handleChange(e);
                  }}
                  label="Logo Obra"
                  variant="outlined"
                />

                <img src={logoObra} style={{ maxWidth: 160, maxHeight: 60 }} />
              </Grid>
              <Grid item xs={12}>
                <p>Logo Construtora</p>
                <Input
                  type="file"
                  disabled={modalLoading}
                  id="logoConstrutora"
                  onChange={e => {
                    handleChange(e);
                  }}
                  label="Logo Obra"
                  variant="outlined"
                />

                <img src={logoConstrutora} style={{ maxWidth: 160, maxHeight: 60 }} />
              </Grid>
              <Grid>
                <Checkbox checked={modalRDO} onChange={handleChangeRdo} inputProps={{ 'aria-label': 'primary checkbox' }} />{' '}
                RDO
              </Grid>
            </GridContainer>
          </GridContainer>
          <div style={{ marginTop: 24, marginBotton: 24 }}></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={modalLoading} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={e => {
              handleModalSave();
            }}
            disabled={modalLoading}
            color="primary">
            Cadastrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={modalUser} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth="xl" maxWidth="xl">
        <DialogTitle id="form-dialog-title">Cadastro de Usuarios</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 24, marginBotton: 24 }}></div>
          <GridContainer>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Nome Usuario"
                variant="outlined"
                style={{ width: '100%' }}
                value={modalUsername}
                onChange={e => {
                  setModalUsername(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                style={{ width: '100%' }}
                value={modalEmail}
                onChange={e => {
                  setModalEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="TimelineID"
                variant="outlined"
                style={{ width: '100%' }}
                value={modalTimelineID}
                onChange={e => {
                  setModalTimelineID(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Box>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Tipo de Acesso</FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={radioValue}
                    onChange={e => setRadioValue(e.target.value)}>
                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Cliente" />
                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Manager" />
                    <FormControlLabel value="3" control={<Radio color="primary" />} label="Admin" />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <p>Lista de Usuarios com Acesso</p>
              <Multiselect
                options={dast}
                displayValue="name"
                selectedValues={selecteds}
                onSelect={onSelect}
                onRemove={onRemove}
                onChange={e => setSelecteds(e.target.value)}
              />
            </Grid>
          </GridContainer>

          <div style={{ marginTop: 24, marginBotton: 24 }}></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={e => {
              handleUserSave();
            }}
            color="primary">
            Cadastrar
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default Obras;
