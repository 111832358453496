import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import Obra from './Pages/Constructor';
import Builders from './Pages/Builders';
import Medicoes from './Pages/Builders/medicoes';
import Relatorios from './Pages/Builders/relatorios';
import Cronogramas from './Pages/Builders/cronogramas';
import Gantt from './Pages/Builders/solucoes/gantt';
import Distribuicao from './Pages/Builders/solucoes/distribuicao';
import Rfm from './Pages/Builders/dynamic/rfm';
import Rfs from './Pages/Builders/dynamic/rfs';

import Anexos from './Pages/Builders/anexos';

import Implantacao from './Pages/Implantacao';

import Admin from './Pages/Admin';

import Manager from './Pages/Admin/Manager';

import User from './Pages/Admin/User';

import Super from './Pages/Builders/solucoes/super';

import Obras from './Pages/Admin/Obras';

import Ciclos from './Pages/Builders/ciclos';

import Messures from './App/messure';

import Error404 from './Pages/404';
import Login from './Auth/Login';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/home'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/home'} />;
  }

  return (
    <React.Fragment>
      <Suspense>
        <Switch>
          <Route path="/signin" component={Login} />
          <RestrictedRoute path="/home" component={Home} />
          <RestrictedRoute path="/obra/manager/:id" component={Obra} />
          <RestrictedRoute path="/obra/:id" component={Builders} />
          <RestrictedRoute path="/medicoes/:id" component={Medicoes} />
          <RestrictedRoute path="/relatorios/:id" component={Relatorios} />
          <RestrictedRoute path="/ciclos/:id" component={Ciclos} />
          <RestrictedRoute path="/cronogramas/:id" component={Cronogramas} />
          <RestrictedRoute path="/anexos/:id" component={Anexos} />
          <RestrictedRoute path="/obra/pavimento/:id" component={Anexos} />

          <RestrictedRoute path="/implantar/obra/:id" component={Implantacao} />
          <RestrictedRoute path="/gantt/obra/:id" component={Gantt} />
          <RestrictedRoute path="/fpla/obra/:id" component={Distribuicao} />

          <RestrictedRoute path="/dynamic/rfm/:id/:relatorio" component={Rfm} />
          <RestrictedRoute path="/dynamic/rfs/:id/:relatorio" component={Rfs} />
          <RestrictedRoute path="/dynamic/obra/:id" component={Distribuicao} />

          <RestrictedRoute path="/obra/dashboard/:id" component={Home} />
          <RestrictedRoute path="/obra/editar/:id" component={Home} />

          <RestrictedRoute path="/admin/home" component={Admin} />
          <RestrictedRoute path="/admin/obras" component={Obras} />
          <RestrictedRoute path="/admin/logs" component={Admin} />
          <RestrictedRoute path="/admin/manager/:id" component={Manager} />
          <RestrictedRoute path="/admin/user/:id" component={User} />


          <RestrictedRoute path="/super/:id" component={Super} />

          <RestrictedRoute path="/messures/:id" component={Messures} />

          <Route component={Error404} />
        </Switch>
      </Suspense>
    </React.Fragment>
  );
};

export default Routes;
