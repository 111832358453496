import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useFormat from 'services/Formats';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 650,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const TableRfm = props => { 
  const classes = useStyles();
  const fix = useFormat();
  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Periodo</TableCell>
            <TableCell align="right">Previsto</TableCell>
            <TableCell align="right">Executado</TableCell>
            <TableCell align="right">Replanejado</TableCell>
            <TableCell align="right">PrevistoAC</TableCell>
            <TableCell align="right">ExecutadoAC</TableCell>
            <TableCell align="right">ReplanejadoAC</TableCell>
            <TableCell align="right">ReplanejadoAnterior</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {props.list.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {fix.mesAbreviado(row.Periodo)}
              </TableCell>
              <TableCell align="right">{row.Previsto}</TableCell>
              <TableCell align="right">{row.Executado}</TableCell>
              <TableCell align="right">{row.Replanejado}</TableCell>
              <TableCell align="right">{row.PrevistoAC}</TableCell>
              <TableCell align="right">{row.ExecutadoAC}</TableCell>
              <TableCell align="right">{row.ReplanejadoAC}</TableCell>
              <TableCell align="right">{row.ReplanejadoAnterior}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default TableRfm;
