import React, { useState } from 'react';
import ToggleHoverCard from '@jumbo/components/Common/ToggleHoverCard';
import Box from '@material-ui/core/Box';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import VisitedChart from './VisitedChart';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import useStyles from './index.style';
import { useSelector } from 'react-redux';
// import { intranet } from '../../../../@fake-db';

import { getCustomDateTime, getNewDate } from '@jumbo/utils/dateHelper';

const today = new Date();
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const currentMonth = monthNames[today.getMonth()];
const pastMonth = monthNames[today.getMonth() - 1];

const vistedChart   = {
  today: [
    { label: '01 am', value: 100, visits: 125 },
    { label: '03 am', value: 120, visits: 103 },
    { label: '05 am', value: 130, visits: 89 },
    { label: '07 am', value: 120, visits: 96 },
    { label: '09 am', value: 110, visits: 122 },
    { label: '11 am', value: 105, visits: 111 },
    { label: '01 pm', value: 110, visits: 78 },
    { label: '03 pm', value: 120, visits: 91 },
    { label: '05 pm', value: 130, visits: 32 },
    { label: '07 pm', value: 140, visits: 35 },
    { label: '09 pm', value: 145, visits: 85 },
    { label: '11 pm', value: 140, visits: 123 },
  ],
  yesterday: [
    { label: '01 am', value: 100, visits: 100 },
    { label: '03 am', value: 120, visits: 100 },
    { label: '05 am', value: 130, visits: 100 },
    { label: '07 am', value: 120, visits: 100 },
    { label: '09 am', value: 110, visits: 100 },
    { label: '11 am', value: 105, visits: 100 },
    { label: '01 pm', value: 110, visits: 100 },
    { label: '03 pm', value: 100, visits: 100 },
    { label: '05 pm', value: 90, visits: 100 },
    { label: '07 pm', value: 95, visits: 100 },
    { label: '09 pm', value: 80, visits: 100 },
    { label: '11 pm', value: 60, visits: 100 },
  ],
}

const AnalyticContent = ({ hovered }) => {
  const classes = useStyles();
  const rfm = useSelector(({ Rfm }) => Rfm);
  
  return hovered ? (
    <Box className={classes.toggleAnalyticsContent}>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box className={classes.toggleAnalyticsContentInner} mr={{ sm: 16 }}>
          <Typography className={classes.titleRoot} component="div" variant="h1">
             {rfm.RealizadoSobrePrevisto} %
          </Typography>
          <Box display="flex" alignItems="center" component="p" color="text.secondary" whiteSpace="nowrap" fontSize={16}>
            <Box component="span">Real / Previsto</Box>
            <Box display="flex" alignItems="center" component="span" ml={2} color={'#6200EE'}>
            963,35
              <Box component="span" ml={2}>
                <TrendingUpIcon />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.toggleAnalyticsContentInner} ml={{ sm: 16 }}>
          <Typography className={classes.titleRoot} component="div" variant="h1">
           14.5%
          </Typography>
          <Box display="flex" alignItems="center" component="p" color="text.secondary" whiteSpace="nowrap" fontSize={16}>
            <Box component="span">% Desvio de Prazo</Box>
            <Box display="flex" alignItems="center" component="span" ml={2} color={'#6200EE'}>
            812,37 
              <Box component="span" ml={2}>
                <TrendingDownIcon />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box className={classes.toggleAnalyticsContent}>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box className={classes.toggleAnalyticsContentInner} mr={{ sm: 16 }}>
          <Typography className={classes.titleRoot} component="div" variant="h1">
         {rfm.RealizadoSobrePrevisto}
          </Typography>
          <Box display="flex" alignItems="center" component="p" color="text.secondary" whiteSpace="nowrap" fontSize={16}>
            <Box component="span">Real/Previsto</Box>
            <Box display="flex" alignItems="center" component="span" ml={2} color={'#ADDC4C'}>
           {rfm.DataStatus}
              <Box component="span" ml={2}>
                <TrendingUpIcon />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.toggleAnalyticsContentInner} ml={{ sm: 16 }}>
          <Typography className={classes.titleRoot} component="div" variant="h1">
          {rfm.DiferencaDiasUteis} dias
          </Typography>
          <Box display="flex" alignItems="center" component="p" color="text.secondary" whiteSpace="nowrap" fontSize={16}>
            <Box component="span">% Desvio do Prazo</Box>
            <Box display="flex" alignItems="center" component="span" ml={2} color={'#C8372D'}>
            {rfm.DesviodoPrazoPerc}%
              <Box component="span" ml={2}>
                <TrendingDownIcon />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      
    </Box>
  );
};

const VisitedToggleAnalyticsCard = () => {
  const { today, yesterday } = vistedChart;
  const [hovered, setHovered] = useState(false);
  const [toggled, setToggled] = useState(false);
  const classes = useStyles();

  return (
    <ToggleHoverCard
      className={clsx(classes.toggleCardRoot, classes.visitedDoubleToggle, toggled ? 'chart-active' : '')}
      title="Desempenho"
      isHovered={setHovered}
      isToggled={setToggled}
      hoverAction={
        <Box
          component="span"
          className={classes.toggleHoverBtn}
          style={{
            backgroundColor: hovered ? '#6200EE' : '#F2E7FE',
            color: hovered ? '#fff' : '#6200EE',
          }}>
          {hovered ? 'No Mês' : 'Geral'}
        </Box>
      }
      toggleAction={<ShowChartIcon color={toggled ? 'primary' : 'action'} />}>
      <AnalyticContent hovered={hovered} />

      {toggled && (
        <Box className={classes.dataChartRoot}>
          <VisitedChart
            data={hovered ? yesterday : today}
            color={hovered ? '#6200EE' : '#ADDC4C'}
            bgColor={hovered ? '#F2E7FE' : '#D7F5B1'}
          />
        </Box>
      )}
    </ToggleHoverCard>
  );
};

export default VisitedToggleAnalyticsCard;
