import React, { useEffect, useState, Button } from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CustomersList from './NewCustomers/CustomersList';
import { useHistory } from 'react-router';
import { TextField } from '@material-ui/core';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import useApi from '../../../services/api';
import useFormat from '../../../services/Formats';

import Scroll from 'react-scroll';
import CmtAvatar from '@coremat/CmtAvatar';

import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import CloseIcon from '@material-ui/icons/Close';

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* background-color:#000; */
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
const Card = styled.div`
  width: 200px;
  height: 200px;
  background-color: white;
  margin: 15px;
  border: 1px;
  opacity: 0.75;

  /* border-color:red; */
  text-align: center;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;
const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
`;
const FooterCard = styled.div`
  position: relative;
  /* padding-top:-50px; */
  background-color: rgba(0, 0, 0, 0.58);
  color: white;
  height: 50px;
  width: 100%;
  z-index: 3;
  top: -55px;
`;
const NomeObra = styled.p`
  text-align: left;
  padding-left: 10px;
  max-width: 190px;
  overflow: hidden;
`;

const Cidade = styled.p`
  text-align: left;
  padding-left: 10px;
  max-width: 190px;
  overflow: hidden;
  font-size: xx-small;
`;

const columns = [
  { id: 'id', label: '#', minWidth: 10 },
  { id: 'local', label: 'Localização', minWidth: 15 },
  { id: 'logos', label: 'logos', minWidth: 15 },

  { id: 'construtora', label: 'Construtora', minWidth: 15 },
  { id: 'obra', label: 'Obra', minWidth: 15 },
  { id: 'medicoes', label: 'Medições', minWidth: 15 },
  // { id: 'status', label: 'status', minWidth: 15 },
  // { id: 'replan', label: 'replanejado', minWidth: 15 },
  { id: 'UltimaMedicao', label: 'UltimaMedicao', minWidth: 15 },

  // { id: 'actions', label: 'Ações', minWidth: 15 },
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  container: {
    maxHeight: '500px',
  },
}));

const breadcrumbs = [
  { label: <IntlMessages id={'G+P'} />, link: '/' },
  { label: <IntlMessages id={'Home'} />, isActive: true },
];

const Home = () => {
  const api = useApi();
  const formats = useFormat();

  const history = useHistory();
  const [list, setList] = useState([]);

  const getList = async () => {
    const result = await api.getMyObras();

    if (result.error === '') {
      let newlist = result.list.map(item => {
        return {
          id: item.id,
          local: item.estado,
          construtora: item.empresa,
          obra: item.obra,
          logos: (
            <div style={{ display: 'flex' }}>
              <CmtAvatar size={30} variant="circular">
                <img src={item.LogoConstrutora} />
              </CmtAvatar>
              <CmtAvatar size={30} variant="circular">
                <img src={item.LogoObra} />
              </CmtAvatar>
            </div>
          ),

          medicoes:
            item.medicoes.length > 0 ? (
              <div style={{ display: 'flex' }}>
                {item.medicoes.map(medicao => {
                  return (
                    <CmtAvatar color={medicao.Situacao == 'MEDIDA' ? 'green' : 'amber'} size={30} variant="circular">
                      {medicao.Situacao == 'MEDIDA' ? <CheckIcon /> : <PriorityHighIcon />}
                    </CmtAvatar>
                  );
                })}
                {item.medicoes.length == 4 && (
                  <CmtAvatar color="grey" size={30} variant="circular">
                    <CloseIcon />
                  </CmtAvatar>
                )}
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <CmtAvatar color="grey" size={30} variant="circular">
                  <CloseIcon />
                </CmtAvatar>
                <CmtAvatar color="grey" size={30} variant="circular">
                  <CloseIcon />
                </CmtAvatar>
                <CmtAvatar color="grey" size={30} variant="circular">
                  <CloseIcon />
                </CmtAvatar>
                <CmtAvatar color="grey" size={30} variant="circular">
                  <CloseIcon />
                </CmtAvatar>
                <CmtAvatar color="grey" size={30} variant="circular">
                  <CloseIcon />
                </CmtAvatar>
              </div>
            ),
          status: 'pendente',
          UltimaMedicao: item.UltimaMedicao != null ? formats.dataPTBR(item.UltimaMedicao) : 'EM IMPLANTAÇÃO',
          replan: item.replan,
          responsavel: item.responsavel,
          actions: 'teste',
        };
      });
      setList(newlist);
    } else {
      alert(result.error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const searchOptions = text => {
    let newtext = text.toLowerCase();
    console.log(newtext);
    if (text == '') {
      getList();
    } else {
      const listtower = list.map(row => {
        return { id: row.id, obra: row.obra.toLowerCase(), estado: row.estado };
      });
      const newllist = listtower
        .filter(list => list.obra.includes(newtext))
        .map(filteredName => {
          return {
            id: filteredName.id,
            local: filteredName.estado,
            construtora: filteredName.empresa,
            obra: filteredName.obra.toUpperCase(),
            logos: (
              <div style={{ display: 'flex' }}>
                <CmtAvatar size={30} variant="circular">
                  <img src={filteredName.LogoConstrutora} />
                </CmtAvatar>
                <CmtAvatar size={30} variant="circular">
                  <img src={filteredName.LogoObra} />
                </CmtAvatar>
              </div>
            ),

            medicoes: (
              <div style={{ display: 'flex' }}>
                {filteredName.medicoes.map(medicao => {
                  return (
                    <CmtAvatar color={medicao.Situacao == 'MEDIDA' ? 'green' : 'amber'} size={30} variant="circular">
                      {medicao.Situacao == 'MEDIDA' ? <CheckIcon /> : <PriorityHighIcon />}
                    </CmtAvatar>
                  );
                })}
              </div>
            ),
            status: 'pendente',
            UltimaMedicao:
              filteredName.UltimaMedicao != null ? formats.dataPTBR(filteredName.UltimaMedicao) : 'EM IMPLANTAÇÃO',
            replan: filteredName.replan,
            responsavel: filteredName.responsavel,
            actions: 'teste',
          };
        });
      setList(newllist);

      console.log(newllist);
    }
  };
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(200);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <PageContainer
      heading={
        <>
          <div style={{ marginBottom: 10, textAlign: 'center' }}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <SearchTwoToneIcon />
              </Grid>
              <Grid item>
                <TextField
                  id="input-with-icon-grid"
                  label="Procurando uma Obra?"
                  onChange={e => searchOptions(e.target.value)}
                />
              </Grid>
            </Grid>
          </div>
        </>
      }
      breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Divider />
          <div style={{ marginTop: 24 }}></div>
        </Grid>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={e => {
                              history.push(`/obra/${row.id}`);
                            }}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100, 200]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </GridContainer>
    </PageContainer>
  );
};

export default Home;
