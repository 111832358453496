import React , { useState ,useRef , useEffect } from 'react'

import { Bar } from 'react-chartjs-2';
 

const AndamentoFisicoSemanal = attributes  => {
  const periodos = [];
  const Executado = [];
  const previsto = [];

  console.log(attributes);
  if(attributes.AllData.length != 0){

    for (let index = 1; index < Object.keys(attributes.AllData["data"]).length + 1; index++) {
      const element = attributes.AllData.data[index];
      periodos.push(element)
    }
    for (let index = 1; index < Object.keys(attributes.AllData["Executado"]).length + 1; index++) {
      const elements = attributes.AllData.Executado[index];
      Executado.push(elements)
    }
    for (let index = 1; index < Object.keys(attributes.AllData["previsto"]).length + 1; index++) {
      const elementsx = attributes.AllData.previsto[index];
      previsto.push(elementsx)
    }
    // console.log(periodos )
  
  }
 
const data = {
  labels: periodos,
  datasets: [
     
    
    {
        label: "Execução Prevista",
        type: "bar",
        backgroundColor: "#ABC4F1",
        pointStyle: 'rect',
      data: previsto,
    },
    {
      label: "Execução Realizada",
      type: "bar",
      backgroundColor: "#335BEF",
      pointStyle: 'rect',
    data: Executado,
  }  
  ],
};
const options = {
  legend: {
    position: 'bottom'
  }
} 
 
  

  
  return ( <>
    <div className='header'>
    
    </div>
    <Bar data={data}  height="150" options={options} />
  </>)
}
 


export default AndamentoFisicoSemanal;