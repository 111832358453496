import React, { useEffect, useState } from 'react';

import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';

import { Gantt } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import {  useParams } from 'react-router-dom';
import useApi from 'services/api'
const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.samplePage'} />, isActive: true },
];

const SamplePage = () => {
  const { id } = useParams();
  const api = useApi();
 const [list,setList] = useState('');


  const loadcronograma = async () => {

    const result = await api.getCronogramaAtual(id);
    if(result.error == ''){
    setList(result.list)
    }
  }

const tasks = [
    {
      start: new Date(2020, 1, 1),
      end: new Date(2020, 1, 2),
      name: 'Idea',
      id: 'Task 0',
      type:'task',
      progress: 45,
      isDisabled: true,
      styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    },
    
];
useEffect(() => {
    loadcronograma();
    // dispatch(getUserDetail());
  }, []);

  return (
    <PageContainer heading={<IntlMessages id="pages.samplePage" />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
            {list &&
            <Gantt tasks={tasks} />
            }
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default SamplePage;
