import React  from 'react'
import VisitedToggleAnalyticsCard from './ToggleAnalyticsCard/VisitedToggleAnalyticsCard';

const StatDesempenho = props => (
    <>
      <div className='header'>
       <VisitedToggleAnalyticsCard list={props.list}/>
      </div>
    </>
  );
  
  export default StatDesempenho;