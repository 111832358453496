import React, { useEffect, useState } from 'react';
import { useParams, Link, Redirect, NavLink , useHistory  } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import GridContainer from '@jumbo/components/GridContainer';
import useApi from '../../../services/api'
import AyurvedaCard from '../Components/AyurvedaCard';
import BecomePro from '../Components/BecomePro';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Label } from '@material-ui/icons';

import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles(theme => ({
  root: {
    width: 'vcalc(100% - 50px)',
    height: '600px',
    margin: 'auto',
  },
  uroot: {
    margin: 'auto',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
  cardHeader: {
    padding: theme.spacing(2, 4),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
 
}));

function getSteps() {
  return ['Importação do Cronograma', 'Tarefas', 'Andares/Locais' ,'Ciclos de Medição'];
}

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}


export default function HorizontalLabelPositionBelowStepper() {

  const { id } = useParams();
  const [visible, setVisible] = useState(!visible)
  const [dadosObra, setDadosObra] = useState('');
  const [NomeObra, setNomeObra] = useState('');
  const [isCheckCro, setisCheckCro] = useState('');
  const [isCheckCiclo, setisCheckCiclo] = useState('');
  const [isOperacional, setisOperacional] = useState('');
  const [checkStatus, setCheckStatus] = useState('');

  const api = useApi();

  const [listBeginTask, setListBeginTask] = useState('');
  const [listBeginTaskx, setListBeginTaskx] = useState('');

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  
  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const callPavimentosTaks = async () => {
    const result = await api.getPavimentosTasks(id);
    if (result.error === '') {
     let newlist = result.list.reduce((acc, {Name}) => [...acc, Name], []);
     setLeft(newlist);
     console.log(newlist)
    }
  }

  const CallListResumeTasks = async () => {
    const result = await api.getResumeTasks(id);
    if (result.error === '') {

      
     const mappedArray = result.list.map((student => {
       return student.Name != '' ? 
       { ...student, 'resumoLista': student.UniqueID + "-" + student.Name + "-" + student.Text4 }
       : student;
     })
     );

      setListBeginTaskx(result.list);
 
       console.log(mappedArray);
       console.log(result.list);

      setListBeginTask(mappedArray);        
    } else {
      alert(result.error);
    }
  }

  const defaultList = [
    {'local':'SubSolo', 'type':false},
    {'local':'SemiEnterrado','type':false},
    {'local':'Terreo','type':false},
    {'local':'Garagem','type':true},
    {'local':'Tipo','type':true},
    {'local':'CaixaDagua','type':false},
    {'local':'CasaDeMaquinas','type':true},
  ];
  

  const classes = useStyles();
  const [IdCronograma,setIdCronograma] = useState('');
  const [cronogramaImportado,setCronogramaImportado] = useState(1);
  const [selectedDate,setSelectedDate] = useState('');
 

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const Handles  = () => {
    
  }
 const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map(value => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const [quantidadeBlocos,setQuantidadeBlocos] = useState(1);

  function getStepContent(stepIndex) { 
    switch (stepIndex) {
      case 0:
        return     <BecomePro
                    IdCronograma={IdCronograma}
          />        ;
      case 1:
        return (
          <> 
          <GridContainer>
            <Grid item md={6}>
              <Autocomplete
              id="inicioFundacao"
              options={listBeginTask}
              getOptionLabel={option => option.resumoLista}
              style={{ width: 300 }}
              renderInput={params => <TextField {...params} label="Inicio Fundação" variant="outlined" />}
            /> 
            </Grid>
          
            <Grid item md={6}>
                <Autocomplete
              id="terminoFundacao"
              options={listBeginTask}
              getOptionLabel={option => option.resumoLista}
              style={{ width: 300 }}
              renderInput={params => <TextField {...params} label="Término Fundação" variant="outlined" />}
            /> 
            </Grid>
        </GridContainer>
          
          <GridContainer>
            <Grid item md={6}>
                <Autocomplete
              id="combo-box-demo"
              options={listBeginTask}
              getOptionLabel={option => option.resumoLista}
              style={{ width: 300 }}
              renderInput={params => <TextField {...params} label="Inicio Estrutura" variant="outlined" />}
              /> 
            </Grid>
            
            <Grid item md={6}>
                <Autocomplete
              id="combo-box-demo"
              options={listBeginTask}
              getOptionLabel={option => option.resumoLista}
              style={{ width: 300 }}
              renderInput={params => <TextField {...params} label="Término Estrutura" variant="outlined" />}
            /> 
            </Grid>
          </GridContainer>
          <GridContainer>
            <Grid item md={6}>
                <Autocomplete
              id="combo-box-demo"
              options={listBeginTask}
              getOptionLabel={option => option.resumoLista}
              style={{ width: 300 }}
              renderInput={params => <TextField {...params} label="Inicio Fachada" variant="outlined" />}
            /> 
            </Grid>
            <Grid item md={6}>
                <Autocomplete
                id="combo-box-demo"
                options={listBeginTask}
                getOptionLabel={option => option.resumoLista}
                style={{ width: 300 }}
                renderInput={params => <TextField {...params} label="Término Fachada" variant="outlined" />}
              />
              </Grid>
          </GridContainer>
          
            
        </>)
      
      case 2:
        return (
          <>
           
          <GridContainer justifyContent="center" alignItems="center" className={classes.uroot}>
          <Grid item>
            <TextField
          id="standard-number"
          label="QuantidadeDeBlocos"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: { 
                max: 100, min: 1 
            }
        }}
          value={quantidadeBlocos}
          onChange={(e)=>setQuantidadeBlocos(e.target.value)}
        />
            </Grid>
      <Grid item>{customList('Choices', left)}</Grid>
      <Grid item>
        <GridContainer direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right">
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left">
            &lt;
          </Button>
        </GridContainer>
      </Grid>
      <Grid item>{customList('Chosen', right)}</Grid>
    </GridContainer>
          
         </> 
          )
      case 3:
          return (
            <>
            <GridContainer>
              <Grid item md={12}>
                    <TextField
                      id="date"
                      label="Primeiro ciclo"
                      type="date"
                      fullWidth={true}
                      defaultValue="2017-05-24"
                      value={selectedDate}
                      onChange={(e)=>setSelectedDate(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
              />
              </Grid>
        
            </GridContainer>
            <GridContainer>
              <Grid item md={12}>
                    <TextField
                      id="date"
                      label="Primeira Medição"
                      type="date"
                      defaultValue="2017-05-24"
                      value={selectedDate}
                      onChange={(e)=>setSelectedDate(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
              />
              </Grid>
        
            </GridContainer>
            </>
          );
      default:
        return 'Unknown stepIndex';
    }
  }
  useEffect(() => {

    CallListResumeTasks();
    callPavimentosTaks();
  }, []);
  return (
    <Box className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <GridContainer justifyContent="center">
      <Box>
        {activeStep === steps.length ? (
          <Box>
            <Typography className={classes.instructions}>Tarefas Concluidas , aguarde enquanto organizamos os dados ...</Typography>
          </Box>
        ) : (
          <>
          <Box>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
          </Box>
          </>
        )}
      </Box>
    </GridContainer>
    <div style={{paddingTop: '2em'}}>
      	 
      	</div>
    <GridContainer justifyContent="center">
       
         
    <Box>
        {activeStep === steps.length ? (
          <Box>
           
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        ) : (
          <>
        
            <Box >
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                Voltar
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}  disabled={cronogramaImportado === ''}>
                {activeStep === steps.length - 1 ? 'Finalizar' : 'Proximo'}
              </Button>
          </Box>
         
          </>
        )}
      </Box>
      
      </GridContainer>

      
     
    </Box>
  );
}
