import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
 
export default function Generics(props) {

    const [responsive, setResponsive] = useState('vertical');
    const [tableBodyHeight, setTableBodyHeight] = useState('400px');
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
    const [headerList,setHeaderList] = useState(props.colun);
    const [listData,setListdata] = useState(props.cronogramaData);


  const options = {
    filter: true,
    filterType: "dropdown",
    responsive,
    tableBodyWidth: "100%",
    tableBodyHeight,
    tableBodyMaxHeight,
    
  };
  
    function components() {
      return (
      
        <div> teste</div>
        );
    
  }; 

  return (
      <MUIDataTable
         
        data={props.cronogramaData}
        columns={props.colun}
        options={options}
        components={components()}
      />
  );
}

 